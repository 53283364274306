import SuperTable from "@decisionmechanics/super-table";
import "@decisionmechanics/super-table/style.css";
import PropTypes from "prop-types";
import { useEffect } from "react";
import useUndo from "use-undo";

const SuperTableUndo = ({ undoDisabled, ...rest }) => {
  const [packTableState, { set: setPackTable, undo, redo, canUndo, canRedo }] = useUndo(rest.packTable);

  const { present: packTable } = packTableState;

  useEffect(() => {
    if (!undoDisabled) {
      rest.onPackTableChanged?.(packTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packTable]);

  return (
    <SuperTable
      {...rest}
      packTable={rest.packTable}
      activeContextMenuItems={
        undoDisabled
          ? rest.activeContextMenuItems
          : [
              ...(rest.activeContextMenuItems ?? []).filter((menuItem) => !["undo", "redo"].includes(menuItem)),
              "undo",
              "redo",
            ]
      }
      clipboardTextSeparator="tab"
      externalThousandsSeparator=","
      onPackTableChanged={undoDisabled ? rest.onPackTableChanged : setPackTable}
      canUndo={canUndo}
      canRedo={canRedo}
      onUndo={undo}
      onRedo={redo}
    />
  );
};

SuperTableUndo.propTypes = {
  gridKey: PropTypes.string,
  undoDisabled: PropTypes.bool,
};

SuperTableUndo.defaultProps = {
  gridKey: "grid",
  undoDisabled: true,
};

export default SuperTableUndo;
