import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as gbtu from "../../GB/GBTableUtil";
import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";

import { cloneObj } from "../../GB/GBUtil";
import { numPotInitClientsPerMonth } from "../NonComponents/PIAppStateUtil";
import { daysPerWeekLabsProcessed } from "../NonComponents/PIAppStateUtil";
import { initLabsAvailProcessPerDay } from "../NonComponents/PIAppStateUtil";
import { daysPerWeekStaffAvail } from "../NonComponents/PIAppStateUtil";
import { minAvailPerDay } from "../NonComponents/PIAppStateUtil";
import { provAvailPerDay } from "../NonComponents/PIAppStateUtil";
import * as pitu from "../NonComponents/PITableUtil";

import SuperTableShim from "../../common/SuperTableShim";

export const PICapacityMonthlyTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

const initCol = 0;

const headerRow1 = 0;
const headerRow2 = 1;
const firstServDelivUnitRow = 2;

let numRows = 50;
const numCols = 20;

//let maxPage = 2;

class PICapacityMonthlyTable extends Component {
  static propTypes = {
    [PICapacityMonthlyTableProps.allowEditsBoolC]: PropTypes.bool,

    [pip.areaTypeInt]: PropTypes.number,

    [pip.countryISO3Str]: PropTypes.string,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.areaInfo2DArr]: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    ),
  };

  static defaultProps = {
    [PICapacityMonthlyTableProps.allowEditsBoolC]: true,

    [pip.areaTypeInt]: pic.districtArea,

    [pip.countryISO3Str]: "",

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},

    [pip.areaInfo2DArr]: [],
  };

  state = {
    page: 1,
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onChangePage = (dir) => {
    let pg = cloneObj(this.state.page);
    pg += dir;
    this.setState({ page: pg });
  };

  onTableChange = (newPackTable) => {
    try {
      const props = this.props;
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const newPackTableClone = gbu.cloneObj(newPackTable);

      let monthlyCapObjArr = piasu.getModVarValue(modVarObjListClone, pisc.monthlyCapacityMVTag);

      for (let i = 0; i < monthlyCapObjArr.length; i++) {
        provAvailPerDay(monthlyCapObjArr, i + 1, 1, gbtu.getValue(newPackTableClone, i + 2, 1));
        minAvailPerDay(monthlyCapObjArr, i + 1, 1, gbtu.getValue(newPackTableClone, i + 2, 2));
        daysPerWeekStaffAvail(monthlyCapObjArr, i + 1, 1, gbtu.getValue(newPackTableClone, i + 2, 3));
        provAvailPerDay(monthlyCapObjArr, i + 1, 2, gbtu.getValue(newPackTableClone, i + 2, 4));
        minAvailPerDay(monthlyCapObjArr, i + 1, 2, gbtu.getValue(newPackTableClone, i + 2, 5));
        daysPerWeekStaffAvail(monthlyCapObjArr, i + 1, 2, gbtu.getValue(newPackTableClone, i + 2, 6));
        initLabsAvailProcessPerDay(monthlyCapObjArr, i + 1, gbtu.getValue(newPackTableClone, i + 2, 7));
        daysPerWeekLabsProcessed(monthlyCapObjArr, i + 1, gbtu.getValue(newPackTableClone, i + 2, 8));
        numPotInitClientsPerMonth(monthlyCapObjArr, i + 1, gbtu.getValue(newPackTableClone, i + 2, 9));
      }

      piasu.setModVarValue(modVarObjListClone, pisc.monthlyCapacityMVTag, monthlyCapObjArr);

      onModVarsChange(modVarObjListClone, () => {});
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      let monthlyCapObjArr = piasu.getModVarValue(modVarObjList, pisc.monthlyCapacityMVTag);

      numRows = 4; //monthlyCapObjArr.length + 2;

      let lies = 317;

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, headerRow1, initCol, RS(SC.GB_stServiceDeliveryUnit));
      gbtu.setValue(packTable, headerRow1, initCol + 1, RS(SC.GB_stStaffCap) + ", " + RS(SC.GB_stTypeA));
      gbtu.setValue(packTable, headerRow1, initCol + 4, RS(SC.GB_stStaffCap) + ", " + RS(SC.GB_stTypeB));
      gbtu.setValue(packTable, headerRow1, initCol + 7, RS(SC.GB_stLabCap));
      gbtu.setValue(packTable, headerRow1, initCol + 9, RS(SC.GB_stDemand));
      gbtu.setValue(packTable, headerRow1, initCol + 10, RS(SC.GB_stDrugCap));

      gbtu.setValue(packTable, headerRow2, initCol + 1, RS(SC.GB_stNumTypeAAvailPerDay));
      gbtu.setValue(packTable, headerRow2, initCol + 2, RS(SC.GB_stNumMinAvailPerDay));
      gbtu.setValue(packTable, headerRow2, initCol + 3, RS(SC.GB_stNumDaysPerWeekAvail));
      gbtu.setValue(packTable, headerRow2, initCol + 4, RS(SC.GB_stNumTypeBAvailPerDay));
      gbtu.setValue(packTable, headerRow2, initCol + 5, RS(SC.GB_stNumMinAvailPerDay));
      gbtu.setValue(packTable, headerRow2, initCol + 6, RS(SC.GB_stNumDaysPerWeekAvail));
      gbtu.setValue(packTable, headerRow2, initCol + 7, RS(SC.GB_stInitLabsProcDaily));
      gbtu.setValue(packTable, headerRow2, initCol + 8, RS(SC.GB_stDaysPerWeekLabsProc));
      gbtu.setValue(packTable, headerRow2, initCol + 9, RS(SC.GB_stNumPotInitClientsPerMonth));
      gbtu.setValue(packTable, headerRow2, initCol + 10, RS(SC.GB_stPrEPDrugSupply));
      gbtu.setValue(packTable, headerRow2, initCol + 11, RS(SC.GB_stMonthTypeAStaffCap));
      gbtu.setValue(packTable, headerRow2, initCol + 12, RS(SC.GB_stMonthTypeBStaffCap));
      gbtu.setValue(packTable, headerRow2, initCol + 13, RS(SC.GB_stMonthLabCap));
      gbtu.setValue(packTable, headerRow2, initCol + 14, RS(SC.GB_stNumPotVisitsPerMonth));
      gbtu.setValue(packTable, headerRow2, initCol + 15, RS(SC.GB_stBottleneckCap) + ", " + RS(SC.GB_stExclDrugs));
      gbtu.setValue(packTable, headerRow2, initCol + 16, RS(SC.GB_stBottleneckCap));
      gbtu.setValue(packTable, headerRow2, initCol + 17, RS(SC.GB_stBottleneck));
      gbtu.setValue(
        packTable,
        headerRow2,
        initCol + 18,
        RS(SC.GB_stSurplusHoursBottleneck) + ", " + RS(SC.GB_stStaffTypeA)
      );
      gbtu.setValue(
        packTable,
        headerRow2,
        initCol + 19,
        RS(SC.GB_stSurplusHoursBottleneck) + ", " + RS(SC.GB_stStaffTypeB)
      );

      for (let c = 1; c < numCols; c++) {
        for (let r = firstServDelivUnitRow; r < numRows; r++) {
          gbtu.setValue(packTable, r, initCol + c, lies);
        }
      }

      for (let r = firstServDelivUnitRow; r < numRows; r++) {
        let val = "Clinic " + r; //monthlyCapObjArr[i - 1].name;//servDelivUnitMstID(monthlyCapObjArr, i);//"Clinic" + " " + i;
        gbtu.setValue(packTable, r, initCol, val);
        gbtu.setValue(packTable, r, initCol + 1, 317); //provAvailPerDay(monthlyCapObjArr, i, 1));
        gbtu.setValue(packTable, r, initCol + 2, 317); //minAvailPerDay(monthlyCapObjArr, i, 1));
        gbtu.setValue(packTable, r, initCol + 3, 317); //daysPerWeekStaffAvail(monthlyCapObjArr, i, 1));
        gbtu.setValue(packTable, r, initCol + 4, 317); //provAvailPerDay(monthlyCapObjArr, i, 2));
        gbtu.setValue(packTable, r, initCol + 5, 317); //minAvailPerDay(monthlyCapObjArr, i, 2));
        gbtu.setValue(packTable, r, initCol + 6, 317); //daysPerWeekStaffAvail(monthlyCapObjArr, i, 2));
        gbtu.setValue(packTable, r, initCol + 7, 317); //initLabsAvailProcessPerDay(monthlyCapObjArr, i));
        gbtu.setValue(packTable, r, initCol + 8, 317); //daysPerWeekLabsProcessed(monthlyCapObjArr, i));
        gbtu.setValue(packTable, r, initCol + 9, 317); //numPotInitClientsPerMonth(monthlyCapObjArr, i));
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setFixedRows(packTable, 1);
      gbtu.lockCells(packTable, headerRow2, true, false);
      for (let c = 9; c < numCols; c++) {
        for (let r = firstServDelivUnitRow; r < numRows; r++) {
          gbtu.lockCell(packTable, r, c, true, true);
        }
      }
      gbtu.setRowAlignment(packTable, headerRow1, gbtc.hAlign.center);
      gbtu.setRowAlignment(packTable, headerRow2, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      gbtu.setRowHeight(packTable, headerRow1, 100);
      gbtu.setRowHeight(packTable, headerRow2, 100);
      gbtu.setWordWrappedCols(packTable, true);
      gbtu.setColWidths(packTable, Theme.dataColWidthLarge);
      gbtu.setColWidth(packTable, initCol, Theme.itemNameColWidth);
      gbtu.setMinAllowedValByCol(packTable, initCol + 2, 0);
      gbtu.setMaxAllowedValByCol(packTable, initCol + 2, gbtc.maxInt);
      //causes weird color issue gbtu.mergeCells(packTable, headerRow1, initCol, 2, 1);
      for (let c = 0; c < numCols; c++) {
        const primaryBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.PI_PrimaryColor));
        gbtu.setCellBGColor(packTable, headerRow2, c, primaryBase10);

        const whiteBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whiteColor));
        gbtu.setFontColor(packTable, headerRow2, c, whiteBase10);
      }

      if (window.DebugMode) {
        console.log("Component: PICapacityMonthlyTable");
        console.log("ModVar(s):");
        console.log(pisc.monthlyCapacityMVTag);
        console.log(monthlyCapObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          onPackTableChanged={this.onTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: 20,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PICapacityMonthlyTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PICapacityMonthlyTable;
