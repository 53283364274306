import * as React from "react";
import TAppPage from "../APP/TAppPage";
// import TButton from '../common/TButton';

class ErrorBoundaryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    console.log("Logged: " + error + " ------ " + info);
  }

  render() {
    let styles = {
      error: {
        background: "#ffd0ce",
        padding: "20px",
        boxSizing: "border-box",
        borderRadius: "5px",
        border: "1px solid #BBBBBB",
        width: "320px",
        margin: "0 auto",
      },
      header: {
        fontSize: "24px",
        color: "red",
        textAlign: "center",
        padding: "10px",
        fontWeight: "bold",
      },
      sadFace: {
        fontSize: "50px",
        textAlign: "center",
        margin: "50px",
      },
      buttons: {
        width: "130px",
      },
    };

    if (this.state.hasError) {
      return (
        <TAppPage style={this.props.style}>
          <div style={styles.error}>
            <p style={styles.header}>Page crashed</p>

            <p style={styles.sadFace}>:(</p>

            <p>The page you were visiting has crashed. Please try reloading the page or navigate to another page.</p>

            {/* <hr />

                        <TButton caption="Reload page" style={styles.buttons}></TButton>
                        <TButton caption="Home" style={styles.buttons}></TButton> */}
          </div>
        </TAppPage>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryPage;
