import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as gbu from "../../GB/GBUtil";
import PIAnnualUnitCostsDetResTable from "../Tables/Results/PIAnnualUnitCostsDetResTable";
import PIAvgMonthlyCostsResTable from "../Tables/Results/PIAvgMonthlyCostsResTable";
import PIMethodComboBox from "../Other/PIMethodComboBox";

class PIDetailedCostsResultContent extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarChange]: PropTypes.func,
    [pias.onModVarsChange]: PropTypes.func,
    [pip.showMethodComboBox]: PropTypes.bool,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarChange]: () => console.log(pias.onModVarChange),
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pip.showMethodComboBox]: false,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderContent = () => {
    const fn = () => {
      const props = this.props;
      //const helpAreaStr = props[pias.helpAreaStr];
      //const onHelp = props[pias.onHelp];
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];
      const showMethodComboBox = props[pip.showMethodComboBox];

      let methodComboBox = null;

      if (showMethodComboBox) {
        const numMethods = piasu.getTotalNumMethods(modVarObjList);

        if (numMethods > 1) {
          methodComboBox = (
            <PIMethodComboBox
              {...{
                [pias.modVarObjList]: modVarObjList,
                [pias.onModVarsChange]: onModVarsChange,
              }}
            />
          );
        }
      }

      const detCostsLab = (
        <p
          style={{
            marginTop: 30,
            marginRight: 20,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stAnnualUnitCosts) + " (" + RS(SC.GB_stUSD) + ")"}
        </p>
      );

      const detCostsTable = (
        <PIAnnualUnitCostsDetResTable
          {...{
            [pias.modVarObjList]: modVarObjList,
          }}
        />
      );

      const avgMonthlyCostsLab = (
        <p
          style={{
            marginTop: 30,
            marginRight: 20,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stAverageMonthlyCost) + " (" + RS(SC.GB_stUSD) + ")"}
        </p>
      );

      const avgMonthlyCostsTable = (
        <PIAvgMonthlyCostsResTable
          {...{
            [pias.modVarObjList]: modVarObjList,
          }}
        />
      );

      return (
        <React.Fragment>
          {methodComboBox}
          {detCostsLab}
          {detCostsTable}
          {avgMonthlyCostsLab}
          {avgMonthlyCostsTable}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderContent");
  };

  render() {
    try {
      return <React.Fragment>{this.renderContent()}</React.Fragment>;
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIDetailedCostsResultContent;
