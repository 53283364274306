import PropTypes from "prop-types";
import TButton from "../../../common/TButton";
import { RS, ValidLanguages } from "../../../../data/strings/global";
import * as Theme from "../../../../app/Theme";

const LanguageChooser = (props) => {
  return Object.keys(ValidLanguages).map((lang) => (
    <TButton
      key={lang}
      caption={RS(ValidLanguages[lang])}
      captionStyles={{
        color: Theme.PI_PrimaryColor,
        ...Theme.menuItemFontStyle,
        fontSize: 16,
        textDecoration: "underline",
      }}
      transparent={true}
      onClick={() => {
        props.onChange?.(lang);
      }}
    />
  ));
};

LanguageChooser.propTypes = {
  onChange: PropTypes.func,
};

export default LanguageChooser;
