import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import * as pias from "../../NonComponents/PIAppState";

import { repeat } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const firstRow = 0;
const numRows = 6;

const firstCol = 0;
const defPriorPopCol = 0;
const elevRiskCol = 1;
const numCols = elevRiskCol + 1;

class PITargDefPriorPopRefTable2 extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Column headings */

      gbtu.setValue(packTable, firstRow, defPriorPopCol, RS(SC.GB_stDefaultPriorPop));
      gbtu.setValue(packTable, firstRow, elevRiskCol, RS(SC.GB_stElevatedRiskPerc));

      /* Row headings */

      gbtu.setValue(packTable, 1, firstCol, "SDC");
      gbtu.setValue(packTable, 2, firstCol, "MSM");
      gbtu.setValue(packTable, 3, firstCol, "FSW");
      gbtu.setValue(packTable, 4, firstCol, "TW");
      gbtu.setValue(packTable, 5, firstCol, "PWID");

      /* Values */

      gbtu.setValue(packTable, 1, elevRiskCol, "100% − % SDC virally supressed");
      gbtu.setValue(packTable, 2, elevRiskCol, 75);
      gbtu.setValue(packTable, 3, elevRiskCol, 100);
      gbtu.setValue(packTable, 4, elevRiskCol, 90);
      gbtu.setValue(packTable, 5, elevRiskCol, 75);

      gbtu.setColWidth(packTable, firstCol, Theme.itemNameColWidth);
      gbtu.setColWidth(packTable, elevRiskCol, Theme.itemNameColWidth);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setRowHeight(packTable, firstRow, 75);
      gbtu.setWordWrappedCol(packTable, firstCol, true);
      gbtu.lockPackTable(packTable, true, true);

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"targDefPriorPopRefTable2"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={[...repeat(["s", "s"], 2), ...repeat(["s", "n"], packTable.GBRowCount - 2)]}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "renderTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PITargDefPriorPopRefTable2;
