import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ClearButton from "@material-ui/icons/Clear";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";

class TDialog extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    visible: PropTypes.bool,
    caption: PropTypes.any,
    header: PropTypes.any,
    message: PropTypes.any,
    content: PropTypes.any,
    actions: PropTypes.array,
    spinner: PropTypes.bool,
    modal: PropTypes.bool,
    error: PropTypes.bool,
    onClose: PropTypes.func,
    closeBtnCaption: PropTypes.string,
    okBtnCaption: PropTypes.string,
    cancelBtnCaption: PropTypes.string,
    icon: PropTypes.any,
    transition: PropTypes.string,
    style: PropTypes.object,
    outerStyle: PropTypes.object,
    maxWidth: PropTypes.any,
  };

  static defaultProps = {
    visible: true,
    caption: "", //'Title',
    header: undefined,
    message: "", //'Message',
    content: null,
    actions: ["mrClose"],
    spinner: false,
    modal: false,
    error: false,
    onClose: (value) => console.log("TDialog onClose [" + value + "]"),
    closeBtnCaption: "Close",
    okBtnCaption: "OK",
    cancelBtnCaption: "Cancel",
    icon: null,
    transition: "",
    style: {},
    outerStyle: {},
    maxWidth: "sm",
  };

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  slideTransition = (props) => {
    if (this.props.transition === "slideLeft") {
      return <Slide direction="left" {...props} />;
    } else {
      return <Fade />;
    }
  };

  onBackdropClick = () => {
    if (!this.props.modal) {
      this.onClose("mrClose");
    }
  };

  onClose = (value) => {
    this.props.onClose(value);
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderCircularProgress = () => {
    return (
      <CircularProgress
        key={"CircularProgress"}
        size={60}
        thickness={5}
        style={{ marginLeft: "42%", marginTop: 10, marginBottom: 10 }}
      />
    );
  };

  renderXButton = () => {
    const styles = {
      xButton2: {
        cursor: "pointer",
        float: "right",
        margin: "0 0 0 20px",
      },
    };

    return (
      <div style={styles.xButton2}>
        <IconButton
          iconStyle={styles.clearButtonIcon}
          disableTouchRipple={true}
          onClick={() => this.onClose("mrClose")}
          style={{ height: "30px", width: "30px" }}
        >
          <ClearButton style={{ height: "20px", width: "20px" }} />
        </IconButton>
      </div>
    );
  };

  renderActions = () => {
    let actionButtons = [];

    for (let i = 0; i <= this.props.actions.length - 1; i++) {
      switch (this.props.actions[i]) {
        case "mrClose":
          actionButtons.push(
            <Button color="primary" key={"Action" + i} onClick={() => this.onClose("mrClose")}>
              {this.props.closeBtnCaption}
            </Button>
          );
          break;

        case "mrOK":
          actionButtons.push(
            <Button color="primary" key={"Action" + i} onClick={() => this.onClose("mrOK")}>
              {this.props.okBtnCaption}
            </Button>
          );
          break;

        case "mrCancel":
          actionButtons.push(
            <Button color="primary" key={"Action" + i} onClick={() => this.onClose("mrCancel")}>
              {this.props.cancelBtnCaption}
            </Button>
          );
          break;

        case "mrYes":
          actionButtons.push(
            <Button color="primary" key={"Action" + i} onClick={() => this.onClose("mrYes")}>
              Yes
            </Button>
          );
          break;

        case "mrNo":
          actionButtons.push(
            <Button color="primary" key={"Action" + i} onClick={() => this.onClose("mrNo")}>
              No
            </Button>
          );
          break;

        default:
          break;
      }
    }

    return actionButtons;
  };

  render() {
    const message = this.props.message;
    const content = this.props.content;
    const visible = this.props.visible;
    //const modal = this.props.modal;

    const spinner = this.props.spinner ? this.renderCircularProgress() : null;
    //const xButton = (this.props.showXButton) ? this.renderXButton() : null;
    const actionButons = this.renderActions();
    const caption = this.props.caption;
    const icon = this.props.icon;
    const header = this.props.header;

    return (
      <div>
        {/*{xButton}*/}

        <Dialog
          open={visible}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              this.onClose("mrClose");
            } else {
              this.onBackdropClick?.();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          // TransitionComponent={this.slideTransition(this.props)}
          style={this.props.outerComboBoxStyle}
          maxWidth={this.props.maxWidth}
        >
          {typeof header !== "undefined" ? (
            <DialogTitle
              id="alert-dialog-title"
              style={{
                minWidth: "200px",
                boxSizing: "border-box",
                ...this.props.style,
              }}
            >
              {icon}
              <div style={{ lineHeight: "40px" }}>{caption}</div>
              {header}
            </DialogTitle>
          ) : null}

          <DialogContent style={{ boxSizing: "border-box", ...this.props.style }}>
            <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            {content}
            {spinner}
          </DialogContent>

          <DialogActions style={{ boxSizing: "border-box", ...this.props.style }}>{actionButons}</DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default TDialog;
