import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const priorPopCol = 0;
const constCovCol = 1;
const ninetyX3Col = 2;
const numCols = ninetyX3Col + 1;

const firstRow = 0;

class PIDefImpactPriorPopTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const defImpPriorPopObjArr = piasu.getModVarValue(modVarObjList, pisc.impactDefPriorPopMVTag);

      const numDefImpPriorPops = piasu.getNumDefImpPriorPops(defImpPriorPopObjArr);

      let packTable = gbtu.getNewPackTable();

      const numRows = numDefImpPriorPops + 1; // includes one header

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));
      gbtu.setValue(packTable, firstRow, constCovCol, RS(SC.GB_stConstCov));
      gbtu.setValue(packTable, firstRow, ninetyX3Col, RS(SC.GB_stNinetyFiveX3));

      for (let ipp = 1; ipp <= numDefImpPriorPops; ipp++) {
        /* Set row headings. */
        const priorPopName = piasu.defImpPriorPopName(defImpPriorPopObjArr, ipp);
        gbtu.setValue(packTable, ipp, priorPopCol, priorPopName);

        const constCovFlt = piasu.getConstantDIPP(defImpPriorPopObjArr, ipp);
        gbtu.setValue(packTable, ipp, constCovCol, constCovFlt);

        const ninetyX3Flt = piasu.getNinetyX3DIPP(defImpPriorPopObjArr, ipp);
        gbtu.setValue(packTable, ipp, ninetyX3Col, ninetyX3Flt);
      }

      //gbtu.setMaxAllowedValByCol(packTable, priorSizeColPO, gbtc.maxInt);
      gbtu.lockPackTable(packTable, true, true);
      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 4);
      gbtu.setRowHeight(packTable, firstRow, 50);
      gbtu.setColWidths(packTable, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidth);
      gbtu.setWordWrappedCol(packTable, priorPopCol, true);

      if (window.DebugMode) {
        console.log("Component: PIDefImpactPriorPopTable");
        console.log("ModVar(s):");
        console.log(pisc.impactDefPriorPopMVTag);
        console.log(defImpPriorPopObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"table"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "renderTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIDefImpactPriorPopTable;
