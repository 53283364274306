import React from "react";
import PropTypes from "prop-types";

const frames = [
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAoCAYAAACrUDmFAAAABmJLR0QAAACuAP8HpySYAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH4gcfAgwGa1C1pwAABGFJREFUaN7tml9IW1ccx39t3LgDi9cO3MhgzJCHsstEh5hCHjo216dy14fgnvrQPayDDDZ86h6EiIUJhlLaDpuXvUwpDuR2YVCYrlzBgJGqaUsWW7uYtDKbcNu0ic0N3Fy/e1Bq0Zvk/smN7diB85KQc88nv/P7/r7nlxwAAPoPjya7H1AubVCuIGu+9+Zbh6ilmXk9AEsbz+jhg1WKL0Vp5s9pujG/SPF4klQdn3W6ODrycQ+d7P2Mejxd1N7+PrW1NNdnYzA9VEjrKYTHgvC6nCCiuk6ny4vgmICVdAaq+U3CMKAi57AoCjjR7ao7VKXJOrsREkRIsmIfoCJLmB4PwsUyDQPbPR0Mi++CAjIFuX6AqpJHRLi0r2B7QZ24IESQV1RrgJnkAnjO+cqA7clTjkckka6aowe06uBmuUDTPwfoizPnqWRJwhjiOo/QG0Tk7vqEPB++R0REf0Wv09L9J0Sk0HIsbvkZ/aHfKPBVLx1qOlhbRfNSEv29nIlv1AHOy+PimID4vVU8flrQnSeFp4+xei8OYewieC8Hh4lour1+JKV89SOaSc7DYyjXtqAEcRGSgcSvNeSChEVRMAzLsB7M3n2oDfj3wjU4GYfOxRj4+oNYtlij9NTaTHoZwX4fGAMCJMyvvNgXAUAqFgarcwGu14/EmoRGD2ktAb/u1GERjqV2ABU5gyG+o0b4OQhzCSjYz6EgMSeAq5FGHfwQMtumgHYKeWVIT98g0hoJvF8jL6Ux2OepCbenTJRLWRr+8nMaCN/efsVB/su/049njmtLcI2x8ewJSdlHlM1tUCn3D83fuU9ERO6PesjZylBzaxu983YrtR5uIaOrb5YL9EfoBzrx7U8vDH0HP0RTE2epjWmqXCZ2IslidCpmSEQUOYfbc9M4e9oHpwE1djAs+NPfI2xYjVXEpkbBakSuqpNR5Axit1Z0w0lrdxEKfANWtwrXU6FVrNyKacKZuk3szoVLBiTcqHHg/Rcs5z6ZVbM74i8G6qY1Yz16fcG0ehsGVBUJIT9vyk5Zmce/vlzxGNYNsJhLwe9179vtwe31I5Ur2gX4HJMBn6EccnHHMDByBTPRKG4uxbGezSKbXUd86Sai0RlcGRnAMc5l6DT4ApN4blcEVUXCSIUCu7u9YOTWLRcyEIUQup1s1bU9fSOQFNX+HNSCdDBuXBVjum7Z1boHMfEq3BriZQbOtIqqioRzL9m6elu53Vasgz9nCs5SHdyC7MSpYcFS1KpFUxg+hU4LcBVbFvq71iUihrGxPV6mrUeYf4IlwP9/m6AyPUqt0lzkBoWv/brdaCI67O6ivpM8feo9Su0fvGvvJuxqNaQTEfh0dL9d3b6arb998KJV/Q5mxwcNGnAGg+OzKNoAeLDeJ2KzXCRxYtJgr7NEkxMiFcubr8cR1dPjqdZmeMWPqDFIO+FsBdQDaTec7YDVIBsB1xBALchGwTUM8GXIRsJZ9qJm/nFRIoaamaaGWbV/AUJ3Ei2zZ04SAAAAAElFTkSuQmCC",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAoCAYAAACrUDmFAAAABmJLR0QAAACuAP8HpySYAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH4gcfAg0Amygh0wAABH5JREFUaN7tmV9IW2cYxh+N1rNNa9yGZRkIhkzKDhM7nLnIRYW6XpV0sOCuCusu1kEGG151F4LFwgTDKG2Hzc1u5iiDlbO5Qdl0I7IJRlpNWzKtWo3WzSWcNmqsSXeSPruwq0Pz55yTnDjHXvhuTs7353fe733e9/tSQpL4D1uZ0RMkE+uIxuJpf9v3VBWqK4W9AZhYX8XdxXkEJ/wY/nEIP42NIxicQ0pFX4tVxMFXW/BG2xG02A+hvr4OtdWVhVkYdVuK8nKIA/0eOqwWAihos1gd9PRLnFkIM6V/kdQMqMSjHPdJPNZsLThUpma2NNMr+SjHFeMAlbjMoS88tJqFooFtbybBzA88EsOxeOEAU8oaR6QLuwq2E9TCc9II15RUfoDhuet0ipZ/DdiOOBWdHJlcyBqjJeny4KNkDEOfdeH4qU+QyEvCBIhNB1EOwHaoFfaXXwQA/Oq/ionZ+wAUTAWCec/R4f0GXe+0oaqsNLeKrslz7GgTdXxRE0WHk+f7JQan53lvJaY6TmIr9zg/HaTUf55Oh0iTDm/aHG7OyWvZt2h4box2TbG2CSX5xilrCPxcFo/JHPdJmmEFs52/3L6bHvDO9a9pEUwqBxPo6vBwKs8cpSbXhhem6OlwUdAgQNLYzJN1gSRDgQGaVQ4gtrk5uSSz2CYvTdKtOnTMHAiEtgCVeJjdzsYc7hcpjU5S4W6awslRiWKOMGp0djP8uCjAViLPDGlvP8OFNAG8W7YmL/BMuz0n3I40kUxE0PPW6+gcuPn4iQnui9/h41NH00twDltfvQ858gci0XUkor9j7NYsAMD2SgssNQIqa2px4Lka1DxbDa2jP0rG8IP3Ixx7/9MnBX2jsxuDX55GrVCWOU1sedLMvsGAJhFR4lHeHB3i6ZMuWjSosUkw03nyQw5oVuMUA4N9NKfxXNZKRomHGbgxoxpOXrpNb9d7NKtW4UIqdIozNwJp4XSdJrbHwgUNEq61cHC6z+Ud+9CrZrd8n2vIm/kV1n1Xr+tWb82AKUWm1+3UVU7l046+ezHjNiwY4EY0RLfDtmunB5vDzVB0wyjAB7zS5dIUQ1bxMDt7L3HY7+e1iSCXIxFGIssMTlyj3z/MS72dPCxaNe0GV9cVPjDKgylFZm+GBLv9ekHLqTseC9MnedlsMWcd297eS1lJGR+D6SBNgo2XfQFVp+xstwcB32Xa0oiXHjjdKppSZJ79R1lX6FJueynW6DyrCy6vPLgJ2cQTPVJeXsvmTannBJvygMt4ZaH+1joBCIKB1+NJbE6hf4a8AP//bwLA0iIQXAYWVwH5z81nFfuA+mqg4QWgoQ4oN3B+wzw4OwN8Pw3c2cj+XvXTwJEGwPESULJXAH/2A98uAkkNfZrrgON24JkCr6W00HBMAr+taIMDgNAKsJ4svAcLDlhSBrzZCry2X32f5/cDb7cCB8r2ACAAlFcALpWQf8NZKowRmVIYZGogjYYzFDAXZDHgDAfMBFksuKJWMspD4CsfMI/iwRW9VFMeAgkAVRUomv0FGxbq4fxdbRIAAAAASUVORK5CYII=",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAoCAYAAACrUDmFAAAABmJLR0QAAACuAP8HpySYAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH4gcfAg0Z/0OJEwAABKtJREFUaN7tmV9sU1Ucxz9rN1oGbFWWoQVmNsYkFpGRSWOKZITxYEKKCQQSnggmGrMHkz35YgLZiw+LMUhi8MGnmUg0uTJNJjJ0yL9t2UoFy2BjfxkZnXeudIx23nY/HwaSsY7e3vZ2aPwm56X3nnPP557z+31/5zZHRIT/sHLNfkAsep+JyUjCa0uWrqBwuf3fARi9f4/bwwMErrRz7mwLP3f4CAT6ievo6yxzsWHLVt6u2clWdyWlpSUUFy7PzMTEsOKijg5KU2ODeMqcAmS0Ocs80tCoSO9QUOLGJykpA2qRCfG1KrK7qizjUAs1h7NKTiitokY08wC1iCotXzVImcOeNbAnm9XukA8aFAlORjIHGNfCclH5bFHB5oM65VPlooS1eHqAwf4u8bqczwzYvDh1eeVi99BTYzQnkQ/OxCZp+fIIe977hGhaKcyOa/MG8oDyymrcr6wG4Hp7M1du/Qlo3PAH0n5G3YlTHDlcw4pcS/IsGlb7pa7GZeCNWsXl8cqxRkUCPQMyHprUHSeToXEZ6AmI0nhMvB6XWA2sZrmnVvrV8NO3aLC/Q9wpxdoslNLqEzWFwE+myKQqvlYlZVi7wy0Xbt5ODNjX9Z047Vadg9llX12D3EjTo/R4bXDohjTU7RN7CglI6ej9Z16IiAz6m8ShcwBXTa10j6iSbakj3VKrO3Qc0uQffAyoRYJS792UZPldorR1iyaLKU262xRxJQmjTd56CT4sCnhs5AtDuvcflaEEAbxYCqtDcnS/OyncvCQzH9IqtcebdRlqwomERW72iVy6JnKuS+Tk+dn2Y9fsb74+EXU8ZCiO41pYmo/XzklCT8Il9MFYdIyPD+zio6ZhPj/Tyrs1r2HR6UjaNFzthetB6LkHU3F9/SwWeMkBL6+EzRVQnK/XA2f4reULqne9T4m3njMnP6TYPveAlNDoY9ExAj1hXt1UrgtubBQu9UL7HzA9k/4JZ10RbF8PG9dAjg7IW1evUVDhmge3IKBehSegxQ9tKsRMOKyWroS3KqH8OeNjGAb0+UHpg6kZTJXFAp5S2L0F8rIBKDE49StcGAeT2eZo7fNwYBs4bSYCToXh6wsQmFqcD0iOZXBwG5QXmPRN5rw/NbhC+2z8rHXAEiusKQYrcHcMonG4G4L+CRiN6tsNoSk47YcXtsNyM1ZQYvDNL9AWWvgeWx64V8NWFzh1pvvIA+gIwOU7MKYtfN8qBxzaAatyTY7BRJAWC+xYB29uhAKD3+okBp2/Q3MfhGbShzOcRSUGJ89CR/jxw/dWpZfOn7SfHzqh8+FLLCqAd3amDpeWTTyCDOXDwTeMr9rTxv/pMvgewGGDcGkbvTYN2Iz5k15FpmGpzXj/tAD//28CGBmGwCgM3wP1r4eZdgmUFkLFi1BRYu4OMG0Fb/XC6R7oe5DEK/NhZwV41usprJ8RwPPt8P1wagV4VQnsccOyTNeymYaTGNwJpX66GAzBfROOJBkHzMmFvdXwegr1YlEBHKo2bgVZBQTIs8E+nZCP4Jw2c5KMBZOkB9JsOFMBk0FmA850wIUgswWX1UpGm4ZvW2GA7MFlvVTTpiEKrLCRNf0Nnx4fI7W+7eIAAAAASUVORK5CYII=",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAoCAYAAACrUDmFAAAABmJLR0QAAACuAP8HpySYAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAB3RJTUUH4gcfAg0orp2JKQAABCpJREFUaN7tmf1PU2cUxz8thZZWSisvHaXiGIg4ujEM6JxidGZsS1y2xS0m/mS2/2u/LEu27MUsmhiW6RbFydukOF5MKyKsL1BgLYW2XFu4+6EQidyWe3t7Cy47PzVp7/M8nz7nnO855+pEURT5D5tB6w1SAiyuSn9nKge7+SUBTCbgiR/8EQitQjAB/6zBhoxnLUawm+DwAaizw6sucFYU5lw6NS4aDoNnGkbnISQU9p+3GOENB7TVw+su0BULMCXAyCT0B+BpojhxZCyFk/XwzptQa9QIMCVAnwfuBGB5fW8Shl4Pbgf0HAenuUCAYhrujcCtv/cOTAr07Qbo6QCrQQVgcAZ+fAjTa/tTAiwmeL8VTh/JHqOSgGIabg9Brx/SKg9xsBxKAGs51GzGz3ICFp9lPkeS6vdod8GnXdK3uQMwFoFrAzC6kgeMBY5VwWs14KqBGpmpfmUFQgswvQDeJZiJy5OX7WazwOVTcNSeAzA4A1//CfPryqC6nNDRArUFEu1kAv7ywkBQGayhBD5qh+4mCcBHE/DNJMRlrtZUDWePgFuFRsmxoB/u+WBoUZ4r6/XwXiv0tGXOpRNFUfRNwldjIEerqyrgYju01xU3oYRDcH0UxmWGzgdu6Dm2CZgS4IffYSiW+/ovtMC7bijdw8w5OgY3vLCUI4yqrXD1HDiN21w0F6TDBpc6odm+P+QhFoEbwzAczQ23I8lIQbbVw+cndhfUXNlxKQGpZzC3WdpVmsFaBiYzOO1Qa1cex2Iabg/CzcDzJPQinKRMbIfsboZPOuRvnhLgoQ8m5sG7DHGZ2Vivh8M2OFoFbynMxkMj8NNjqJCAyyr0KSHTJXS2yoMLh+APHwwsgLCh3gWVZuiJR2Br3Amnul2KReBXD/Qvqq9GpKyxCj7sUBf7eQM+8MC1Kfm6qaawPt0IF4/nl70VA4pp+PkO9C0pL6fU2KGDcPmMtBsWDDAeg2/7YDy+N/Jgs8CVM9Bs1Wgmc9ejDK7SlImfQzYoKwFXbaazmAvD2jrMReFJBEIyZzfROPR64JWzcECLGxTT8P1v0B/dfbxwok1+151MwOA43A9AOJX9dw4bXD0PDoPGMSgFqdfD+SbodudXFGytPTwGN6cguqEeLu8sKqbhu1swGNOmlHuxFKu2wpcXlMOpkoktyKgZrpzK/9Zyrf/LfXiQgC/yhFMt9CkBMGrbXSQFKDfm/7zu/3cTKs0/C+MhmF1+PmgylkFjJbTUQUuDth6g2Q0+9kGvF6Z2mX5XmjONdK7R374DvDsA12eVFeCdDfDxSbAUupYtNJyYhkBUeXfxNAqrGrQkBQfUGeDSOehSUC9udeIOw0sACFBqhM9kQlZn6cT3NaBcSK3hNAXcDbIYcJoDZoMsFlxRK5mtad00xYMreqmWEmANqDBSNPsXsDrVWxA47/wAAAAASUVORK5CYII=",
];

class TWifiAnimation extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    style: PropTypes.object,
  };

  static defaultProps = {
    style: {},
  };

  constructor(props) {
    super(props);

    this.timer = null;

    this.state = {
      frame: 0,
    };
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      const frameIdx = (this.state.frame + 1) % 4;
      this.setState({
        frame: frameIdx,
      });
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    return <img src={frames[this.state.frame]} style={this.props.style} alt={""} />;
  }
}

export default TWifiAnimation;
