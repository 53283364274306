import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

class PIStayOnMethodAnnAggCostsLiteResTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const costStayOnPrEPPT1DIntArr = piasu.getModVarValue(modVarObjList, pisc.costStayOnPrEPPTMVTag, true);
      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const aggFileNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.aggFileNamesMVTag, true);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID);
      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numFiles = costStayOnPrEPPT1DIntArr.length;

      let packTable = gbtu.getNewPackTable();

      /* Number of service delivery strategies + edu code column + personnel type column. */
      const numCols = numPriorPops + 1;

      packTable = gbtu.resizePackTable(packTable, numFiles + 1, numCols);

      /* Column headings */
      gbtu.setValue(packTable, 0, 0, RS(SC.GB_stFile));

      for (let pp = 1; pp <= numPriorPops; pp++) {
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, 0, pp, priorPopName);

        for (let f = 1; f <= numFiles; f++) {
          gbtu.setValue(packTable, f, 0, aggFileNames1DStrArr[f - 1]);

          let value = piasu.getCostStayOnPrEPPP(costStayOnPrEPPT1DIntArr[f - 1], selectedMethodCurrID, pp);
          gbtu.setValue(packTable, f, pp, value);
        }

        gbtu.setColWidth(packTable, pp, Theme.dataColWidthMed);
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 2);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setRowHeight(packTable, 0, 50);
      gbtu.setWordWrappedCol(packTable, 0, true);
      gbtu.lockPackTable(packTable, true, false);

      if (window.DebugMode) {
        console.log("Component: PIStayOnMethodAnnAggCostsLiteResTable");
        console.log("ModVar(s):");
        console.log(pisc.costStayOnPrEPPTMVTag);
        console.log(costStayOnPrEPPT1DIntArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"table"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "renderTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIStayOnMethodAnnAggCostsLiteResTable;
