import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Theme from "../../app/Theme";

class TAppBar extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    logo: PropTypes.any,
    caption: PropTypes.string,
    hamburgerStyle: PropTypes.object,
    showHamburger: PropTypes.bool,
    onHamburgerClick: PropTypes.func,
    onTitleClick: PropTypes.func,
    position: PropTypes.string,
    style: PropTypes.object,
    titleStyle: PropTypes.object,
  };

  static defaultProps = {
    logo: null,
    caption: "",
    showHamburger: true,
    hamburgerStyle: {},
    onTitleClick: () => console.log("onTitleClick fired"),
    onHamburgerClick: () => console.log("onHamburgerClick fired"),
    position: "absolute",
    style: { "min-height": "164px" },
    titleStyle: {},
  };

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  onHamburgerClick = () => {
    this.props.onHamburgerClick();
  };

  onTitleClick = () => {
    this.props.onTitleClick();
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    const styles = {
      appBar: {
        zIndex: Theme.zIndex.drawer + 1,
        ...Theme.appBar,
      },
      toolbar: {
        ...this.props.style,
      },
      hamburger: {
        marginLeft: -12,
        marginRight: 20,
        color: "white",
        ...this.props.hamburgerStyle,
      },
      logo: {
        marginRight: "15px",
        cursor: "pointer",
      },
      title: {
        flex: 0,
        fontSize: "24px",
        cursor: "pointer",
      },
    };

    return (
      <div className="AppBar">
        <AppBar position={this.props.position} style={styles.appBar}>
          <Toolbar style={styles.toolbar}>
            {this.props.showHamburger ? (
              <IconButton style={styles.hamburger} aria-label="Menu" onClick={this.onHamburgerClick}>
                <MenuIcon />
              </IconButton>
            ) : null}

            {/* <Typography 
                            style={styles.logo} 
                            variant="h2" 
                            color="inherit"
                            onClick={this.onTitleClick}
                        > */}
            {this.props.logo}
            {/* </Typography> */}

            <Typography style={{ ...styles.title, ...this.props.titleStyle }} onClick={this.onTitleClick}>
              {this.props.caption}
            </Typography>

            {this.props.children}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default TAppBar;
