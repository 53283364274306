import * as React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles/index";
// import Icon from '@material-ui/core/Icon';
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
// import MaximizeIcon from '@material-ui/icons/KeyboardArrowLeft';
// import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 430,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    minWidth: 0, // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar,
});

class TSideDrawer extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    header: PropTypes.bool,
    widthOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    widthClosed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    expanded: PropTypes.bool,
    classes: PropTypes.object,
    onResize: PropTypes.func,
    direction: PropTypes.string,
    headerStyle: PropTypes.object,
    contentStyle: PropTypes.object,
    style: PropTypes.object,
  };

  static defaultProps = {
    header: true,
    widthOpen: "450px",
    widthClosed: "50px",
    expanded: true,
    classes: {},
    onResize: () => {},
    direction: "right",
    headerStyle: {},
    contentStyle: {},
    style: { backgroundColor: "#eceff1" },
  };

  constructor(props) {
    super(props);

    this.state = {
      modalVisible: true,
      drawerWidth: this.props.widthOpen,
      expanded: this.props.expanded,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState, nextContext) {
    if (this.props.expanded !== nextProps.expanded) {
      nextProps.expanded ? this.maximizeDrawer() : this.minimizeDrawer();
    }

    return true;
  }

  componentDidMount() {
    this.state.expanded ? this.maximizeDrawer() : this.minimizeDrawer();
  }

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  minimizeDrawer = () => {
    this.setState(
      {
        drawerWidth: this.props.widthClosed,
        expanded: false,
      },
      () => this.props.onResize(this.state.drawerWidth, this.state.expanded)
    );
  };

  maximizeDrawer = () => {
    this.setState(
      {
        drawerWidth: this.props.widthOpen,
        expanded: true,
      },
      () => this.props.onResize(this.state.drawerWidth, this.state.expanded)
    );
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderHeader = () => {
    const styles2 = {
      formHeader: {
        borderBottom: "1px solid #CCCCCC",
        ...this.props.headerStyle,
      },
    };

    return (
      <div className="SideDrawerHeader" style={styles2.formHeader}>
        {this.state.expanded ? (
          <IconButton onClick={this.minimizeDrawer}>
            {/* <CloseIcon /> */}

            <i
              className={this.props.direction === "left" ? "fa fa-angle-left" : "fa fa-angle-right"}
              aria-hidden="true"
              style={{ width: "25px" }}
            />

            {/* <Icon
                                className={
                                    (this.props.direction === "left") ? "fa fa-angle-left" : "fa fa-angle-right"
                                }
                                // className="icon-hover"
                                // onClick={() => this.setState({dialogVisible : true}) }
                            >
                                close
                            </Icon> */}
          </IconButton>
        ) : (
          <IconButton onClick={this.maximizeDrawer}>
            {/* <MaximizeIcon /> */}
            <i
              className={this.props.direction === "right" ? "fa fa-angle-left" : "fa fa-angle-right"}
              aria-hidden="true"
              style={{ width: "25px" }}
            />

            {/* <Icon
                                className="icon-hover"
                                onClick={() => this.setState({dialogVisible : true}) }
                            >
                                maximize
                            </Icon> */}
          </IconButton>
        )}
      </div>
    );
  };

  renderContent = () => {
    const styles = {
      box: {
        height: "100%",
        overflowY: "auto",
      },
      content: {
        height: "calc(100% - " + 0 + "px)",
        position: "relative",
        top: 0,
        bottom: 0,
        boxSizing: "border-box",
        ...this.props.contentStyle,
      },
    };

    if (!this.state.expanded) {
      styles.box.width = "400px";
      styles.box.paddingLeft = "50px";
      styles.box.display = "none";
    }

    // mark -- first div below caused children not to show!!!
    //{/*<div className="SideDrawerContent" style={styles.box}>*/}
    //{/*<div className="SideDrawerContent2" style={styles.content}>*/}
    //{/*{this.props.children}*/}
    //{/*</div>*/}
    //{/*</div>*/}

    return (
      <div className="SideDrawerContent2" style={styles.content}>
        {this.props.children}
      </div>
    );
  };

  renderDrawer = () => {
    const styles = {
      drawer: {
        overflow: "hidden",
        ...this.props.style,
      },
    };

    // markThomas - position was absolute; top 10 PaperProps

    return (
      <div className="SideDrawer" style={{ display: "inline-block" }}>
        <Drawer
          variant="persistent"
          anchor={this.props.direction}
          open={this.state.modalVisible}
          //PaperProps={{style: {boxSizing: 'border-box', width: this.state.drawerWidth, top: 0, bottom: 0, position: 'absolute', ...this.props.style}}}
          // mr2 - was relative
          PaperProps={{
            style: {
              boxSizing: "border-box",
              width: this.state.drawerWidth,
              bottom: 0,
              position: "absolute",
              ...this.props.style,
            },
          }}
          style={{ ...styles.drawer }}
        >
          {/* <div className={this.props.classes.toolbar} /> */}

          {this.props.header ? this.renderHeader() : null}

          {this.renderContent()}
        </Drawer>
      </div>
    );
  };

  render() {
    return this.renderDrawer();
  }
}

export default withStyles(styles)(TSideDrawer);
