import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../../app/Theme";

import * as gbu from "../../../GB/GBUtil";
import * as pias from "../../NonComponents/PIAppState";
import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";
import PIDefCostsPerVisitRefTable from "../../Tables/Reference/PIDefCostsPerVisitRefTable";

class PIDefCostsPerVisitRefLiteForm extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];

    const defCostsPerVisitLab = (
      <p
        style={{
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stDefCostsPerVisit)}
      </p>
    );

    const defCostsPerVisitRefTable = (
      <PIDefCostsPerVisitRefTable
        {...{
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,
        }}
      />
    );

    return (
      <React.Fragment>
        {defCostsPerVisitLab}
        {defCostsPerVisitRefTable}
      </React.Fragment>
    );
  }
}

export default PIDefCostsPerVisitRefLiteForm;
