import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TComboBox from "../../common/TComboBox";

import * as Theme from "../../../app/Theme";

import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbu from "../../GB/GBUtil";
import PIStayOnMethodAnnAggCostsLiteResTable from "../Tables/Results/PIStayOnMethodAnnAggCostsLiteResTable";
import PIPerPersonInitAnnAggCostsLiteResTable from "../Tables/Results/PIPerPersonInitAnnAggCostsLiteResTable";

import PIMethodComboBox, { PIMethodComboBoxProps } from "../Other/PIMethodComboBox";

const selectedIndicatorByteC = "selectedIndicatorByte";

/*   Aggregate - Costs Lite/Detailed costing metrics (indicators) */

const costToStayOnMethodAnnuallyAggInd = 1;
const costPerPersonInitAnnuallyAggInd = 2;
const costPerPersonInitMonthlyAggInd = 3;

class PICostsLiteAggResultContent extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
  };

  state = {
    [selectedIndicatorByteC]: costToStayOnMethodAnnuallyAggInd,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onMetricComboBoxChange = (value, text, info) => {
    this.setState({
      [selectedIndicatorByteC]: info,
    });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderContent = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];

      const state = this.state;
      const selectedIndicatorByte = state[selectedIndicatorByteC];

      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID);
      const numMethods = piasu.getTotalNumMethods(modVarObjList);

      const comboBoxLengths = 400;

      let methodComboBox = null;

      if (numMethods > 1) {
        methodComboBox = (
          <PIMethodComboBox
            {...{
              [pias.modVarObjList]: modVarObjList,
              [pias.onModVarsChange]: onModVarsChange,

              [PIMethodComboBoxProps.outerStyle]: {
                marginBottom: 20,
              },

              [PIMethodComboBoxProps.row]: false,
            }}
          />
        );
      }

      const metricLab = (
        <p
          style={{
            ...Theme.labelStyle,
            margin: 0,
            marginRight: 27, // line up with method combobox
          }}
        >
          {RS(SC.GB_stMetric) + ":"}
        </p>
      );

      const metricComboBox = (
        <TComboBox
          onChange={this.onMetricComboBoxChange}
          info={[costToStayOnMethodAnnuallyAggInd, costPerPersonInitAnnuallyAggInd, costPerPersonInitMonthlyAggInd]}
          itemIndex={selectedIndicatorByte - 1}
          items={[
            RS(SC.GB_stPersYearCostMETHOD).replace(pic.methodStr, piasu.methodName(methodObjArr, selectedMethodCurrID)),
            RS(SC.GB_stCostPerPersInit),
          ]}
          outerStyle={{
            fontFamily: Theme.fontFamily,
          }}
          style={{
            fontFamily: Theme.fontFamily,
            width: comboBoxLengths,
          }}
        />
      );

      const metricDiv = (
        <div
          style={{
            alignItems: "center",
            display: "inline-flex",
            marginBottom: 20,
          }}
        >
          {metricLab}
          {metricComboBox}
        </div>
      );

      let metricTable = null;

      if (selectedIndicatorByte === costToStayOnMethodAnnuallyAggInd) {
        metricTable = (
          <PIStayOnMethodAnnAggCostsLiteResTable
            {...{
              [pias.modVarObjList]: modVarObjList,
            }}
          />
        );
      } else if (selectedIndicatorByte === costPerPersonInitAnnuallyAggInd) {
        metricTable = (
          <PIPerPersonInitAnnAggCostsLiteResTable
            {...{
              [pias.modVarObjList]: modVarObjList,
            }}
          />
        );
      }

      return (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {methodComboBox}
            {metricDiv}
          </div>
          {metricTable}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderContent");
  };

  render() {
    try {
      //const props = this.props;
      //const modVarObjList = props[pias.modVarObjList];
      //const origModVarObjArr = props[pias.origModVarObjArr];
      //const onModVarsChange = props[pias.onModVarsChange];

      return <React.Fragment>{this.renderContent()}</React.Fragment>;
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PICostsLiteAggResultContent;
