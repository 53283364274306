import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

class TAppSplash extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    caption: PropTypes.string,
    logo: PropTypes.any,
  };

  static defaultProps = {
    caption: "Loading...",
    logo: null,
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    return (
      <div>
        <div style={{ margin: "0, auto", textAlign: "center" }}>
          <img style={{ marginTop: "15px", height: "50px" }} src={this.props.logo} alt="" />
          <p style={{ textAlign: "center", color: "#0c3a79", fontSize: "22px" }}>{this.props.caption}</p>
          <CircularProgress
            size={118}
            style={{ marginTop: "10", marginBottom: "10", margin: "0 auto", display: "block" }}
          />
        </div>
      </div>
    );
  }
}

export default TAppSplash;
