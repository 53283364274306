import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as pic from "../../NonComponents/PIConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import { getCostStayOnPrEPSDS } from "../../NonComponents/PIAppStateUtil";
import { getCostStayOnPrEPPP } from "../../NonComponents/PIAppStateUtil";
import { getCostPerPersonInitPP } from "../../NonComponents/PIAppStateUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import SuperTableShim from "../../../common/SuperTableShim";

class PIAnnualUnitCostsDetResTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const servDelivStratObjList = piasu.getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

      const costStayOnPrEPSDSObjList = piasu.getModVarValue(modVarObjList, pisc.costStayOnPrEPSDSMVTag);
      const costStayOnPrEPPTObjList = piasu.getModVarValue(modVarObjList, pisc.costStayOnPrEPPTMVTag);
      const costPerPersonInitPTObjList = piasu.getModVarValue(modVarObjList, pisc.costPerPersonInitPTMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      let packTable = gbtu.getNewPackTable();

      const numServDelivStrats = piasu.getTotalNumServDelivStrats(selectedMethodMstID, servDelivStratObjList);
      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numCols = Math.max(numServDelivStrats, numPriorPops) + 1;
      const numRows = 5;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, 0, 0, RS(SC.GB_stAnnUnitCosts));
      gbtu.setFontStyle(packTable, 0, 0, [gbtc.fontStyle.bold]);

      gbtu.setValue(packTable, 1, 0, RS(SC.GB_stPrEPPersYearCost));
      gbtu.setIndent(packTable, 1, true, Theme.leftIndent);

      gbtu.setValue(packTable, 2, 0, RS(SC.GB_stAnnUnitCosts));
      //gbtu.setFontStyle(packTable, 2, 0, [gbtc.fontStyle.bold]);

      const methodName = piasu.methodName(methodObjList, selectedMethodCurrID);
      gbtu.setValue(packTable, 3, 0, RS(SC.GB_stPersYearCostMETHOD).replace(pic.methodStr, methodName));

      gbtu.setIndent(packTable, 3, true, Theme.leftIndent);

      gbtu.setValue(packTable, 4, 0, RS(SC.GB_stCostPerPersInit));
      gbtu.setIndent(packTable, 4, true, Theme.leftIndent);

      for (let sds = 1; sds <= numServDelivStrats; sds++) {
        /* Set col headings. */
        const servDelivStratName = piasu.getServDelivStratName(selectedMethodMstID, servDelivStratObjList, sds);
        gbtu.setValue(packTable, 0, sds, servDelivStratName);
        gbtu.setFontStyle(packTable, 0, sds, [gbtc.fontStyle.bold]);

        let cost = getCostStayOnPrEPSDS(costStayOnPrEPSDSObjList, selectedMethodCurrID, sds);
        gbtu.setValue(packTable, 1, sds, cost);

        gbtu.setColWidth(packTable, sds, Theme.dataColWidthMed);
        gbtu.setWordWrappedCol(packTable, sds, true);
      }

      gbtu.setFontColor(packTable, 2, 0, 16777215);
      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set col headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, 2, pp, priorPopName);
        //gbtu.setFontStyle(packTable, 2, pp, [gbtc.fontStyle.bold]);
        gbtu.setFontColor(packTable, 2, pp, 16777215);

        let cost1 = getCostStayOnPrEPPP(costStayOnPrEPPTObjList, selectedMethodCurrID, pp);
        let cost2 = getCostPerPersonInitPP(costPerPersonInitPTObjList, selectedMethodCurrID, pp);

        gbtu.setValue(packTable, 3, pp, cost1);
        gbtu.setValue(packTable, 4, pp, cost2);

        gbtu.setColWidth(packTable, pp, Theme.dataColWidthMed);
        gbtu.setWordWrappedCol(packTable, pp, true);
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 2);
      gbtu.lockPackTable(packTable, true, false);
      gbtu.setRowHeight(packTable, 0, 50);
      gbtu.setWordWrappedCol(packTable, 0, true);

      gbtu.setID(packTable, "detailedCostsPackTable");
      gbtu.setKey(packTable, "detailedCostsPackTable");

      if (window.DebugMode) {
        console.log("Component: PIAnnualUnitCostsDetResTable");
        console.log("ModVar(s):");
        console.log(pisc.costStayOnPrEPSDSMVTag);
        console.log(costStayOnPrEPSDSObjList);
        console.log(pisc.costStayOnPrEPPTMVTag);
        console.log(costStayOnPrEPPTObjList);
        console.log(pisc.costPerPersonInitPTMVTag);
        console.log(costPerPersonInitPTObjList);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"detailedCostTable"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,

            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIAnnualUnitCostsDetResTable");
  };

  render() {
    return (
      <React.Fragment>
        <span id={"double-header"}>{this.renderTable()}</span>
      </React.Fragment>
    );
  }
}

export default PIAnnualUnitCostsDetResTable;
