import axios from "axios";

//==================================================================================================================
//
//                     ##     ## ######## #### ##       #### ######## #### ########  ######
//                     ##     ##    ##     ##  ##        ##     ##     ##  ##       ##    ##
//                     ##     ##    ##     ##  ##        ##     ##     ##  ##       ##
//                     ##     ##    ##     ##  ##        ##     ##     ##  ######    ######
//                     ##     ##    ##     ##  ##        ##     ##     ##  ##             ##
//                     ##     ##    ##     ##  ##        ##     ##     ##  ##       ##    ##
//                      #######     ##    #### ######## ####    ##    #### ########  ######
//
//==================================================================================================================

const uuidv4 = () => {
  let crypto = window.crypto || window.msCrypto;

  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-mixed-operators
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

// const GenerateRandomGUID = () => {
//     return '{' + uuidv4() + '}';
// };

//==================================================================================================================
//
//                ##     ##    ###    ########  ####    ###    ########  ##       ########  ######
//                ##     ##   ## ##   ##     ##  ##    ## ##   ##     ## ##       ##       ##    ##
//                ##     ##  ##   ##  ##     ##  ##   ##   ##  ##     ## ##       ##       ##
//                ##     ## ##     ## ########   ##  ##     ## ########  ##       ######    ######
//                 ##   ##  ######### ##   ##    ##  ######### ##     ## ##       ##             ##
//                  ## ##   ##     ## ##    ##   ##  ##     ## ##     ## ##       ##       ##    ##
//                   ###    ##     ## ##     ## #### ##     ## ########  ######## ########  ######
//
//==================================================================================================================

const CONTAINER_BASE_URL = window.API_URL_BASE; // Controller url
const CONTAINER_LOGIN_URL = CONTAINER_BASE_URL + "/api/v1/session/"; // Talk to controller to ask for a container; get a token back
const CONTAINER_STATUS_URL = CONTAINER_BASE_URL + "/api/v1/server/"; // Talk to controller to check 'token' until container is ready
const CONTAINER_SESSION_URL = CONTAINER_BASE_URL + "/r/"; // Talk to RemObjects server on the container
const CONTAINER_TAG = window.containerVersion; // Ability to declare a custom container to get.  Blank is latest.
const CONTAINER_NEW = "instance="; // Adding a new instance value guarentees a new container every time

const CONTAINER_USER = "PrEPit_Client"; //'guest';                     // User for the controller
const CONTAINER_PASS = "guest123"; //'';                             // Password for the user

const CONSOLE_LOGGING = true; // Show console.log messages for each step (debugging purposes)

//==================================================================================================================
//
//                  ######   #######  ##    ## ########    ###    #### ##    ## ######## ########
//                 ##    ## ##     ## ###   ##    ##      ## ##    ##  ###   ## ##       ##     ##
//                 ##       ##     ## ####  ##    ##     ##   ##   ##  ####  ## ##       ##     ##
//                 ##       ##     ## ## ## ##    ##    ##     ##  ##  ## ## ## ######   ########
//                 ##       ##     ## ##  ####    ##    #########  ##  ##  #### ##       ##   ##
//                 ##    ## ##     ## ##   ###    ##    ##     ##  ##  ##   ### ##       ##    ##
//                  ######   #######  ##    ##    ##    ##     ## #### ##    ## ######## ##     ##
//
//==================================================================================================================

const RequestToken = (params, successFn, errorFn) => {
  const url = CONTAINER_LOGIN_URL + params.userName + "?password=" + params.password;

  if (CONSOLE_LOGGING) {
    console.log("Requesting token... " + url);
  }

  axios
    .create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    })
    .post(url)
    .then((response) => {
      if (CONSOLE_LOGGING) {
        console.log("Got Token: " + response.data.token);
      }
      successFn(response.data.token);
    })
    .catch((err) => {
      errorFn(err.message);
    });
};

const RequestLinkToken = (params, successFn, errorFn) => {
  const token = params.token;
  const url = CONTAINER_STATUS_URL + token + "?" + CONTAINER_TAG + "&" + CONTAINER_NEW + params.instance;

  if (CONSOLE_LOGGING) {
    console.log("Requesting linkToken... " + url);
  }

  axios
    .create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "SpecW-Auth": "token " + token,
      },
    })
    .post(url)
    .then((response) => {
      if (response.data.atCapacity) {
        errorFn("Error: Containers are at full capacity");
      } else if (response.data.ready) {
        if (CONSOLE_LOGGING) {
          console.log("Got linkToken: " + response.data.linkToken);
        }
        successFn(response.data.linkToken);
      } else {
        setTimeout(() => {
          RequestLinkToken(params, successFn, errorFn);
        }, 1000);
      }
    })
    .catch((err) => {
      errorFn(err.message);
    });
};

const GetContainerAccess = (successFn, errorFn) => {
  //const CONTAINER_PASS = 'HLiST' + uuidv4();

  if (typeof errorFn === "undefined") {
    errorFn = () => {
      alert("errorFn was not passed in.");
    };
  }

  RequestToken(
    { userName: CONTAINER_USER, password: CONTAINER_PASS },
    (token) => {
      RequestLinkToken(
        { token: token, instance: uuidv4() },
        (linkToken) => {
          successFn({
            url: CONTAINER_SESSION_URL + linkToken + "/JSON",
          });
        },
        errorFn
      );
    },
    errorFn
  );
};

//==================================================================================================================
//
//                 ######  ########  ########  ######  ######## ########  ##     ## ##     ##
//                ##    ## ##     ## ##       ##    ##    ##    ##     ## ##     ## ###   ###
//                ##       ##     ## ##       ##          ##    ##     ## ##     ## #### ####
//                 ######  ########  ######   ##          ##    ########  ##     ## ## ### ##
//                      ## ##        ##       ##          ##    ##   ##   ##     ## ##     ##
//                ##    ## ##        ##       ##    ##    ##    ##    ##  ##     ## ##     ##
//                 ######  ##        ########  ######     ##    ##     ##  #######  ##     ##
//
//==================================================================================================================

/*const getProjectionData = (params, successFn, errorFn) => {
    GetContainerAccess((response) => {

        let method = 'RESTService.GetProjectionData';
        let GUID = GenerateRandomGUID();
        let startTime = Date.now();
        let url = response.url;

        if (CONSOLE_LOGGING) {
            console.log({
                _ : "SEND",
                methodName : method,
                params: {
                    jsonMesg: params
                },
                timeSent : Date(startTime).toString()
            });
        }

        axios
            .create({
                baseURL: url,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .post(url, {
                id: GUID,
                method: method,
                params: {
                    jsonMesg: JSON.stringify(params)
                }
            })
            .then(response => {
                let clientID = response.data.id;
                let method = JSON.parse(response.config.data).method;
                let params = JSON.parse(response.config.data).params;
                let result = response.data.result.Result;
            
                if (result === 'undefined') {
                    result = undefined;
                }
                else if ((typeof result === 'string') && ((result.charAt(0) === '{') || (result.charAt(0) === '['))) {
                    result = JSON.parse(response.data.result.Result);
                }
                else {
                    result = response.data.result.Result;
                }

                if (CONSOLE_LOGGING) {
                    let output = {};
                    output._ = "GET";
                    output.methodName = method;
                    output.params = params;
                    output.result = result;
                    output.Time = Date.now() - startTime;
                    output.timeReceived = Date(Date.now().toString());
                    console.log(output);
                }

                successFn(result);
            })
            .catch(e => {
                errorFn(e);
            });
    }, (msg) => {
        errorFn(msg);
    })
};*/

export default GetContainerAccess;
