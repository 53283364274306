import React from "react";
import PropTypes from "prop-types";
import TButton from "./TButton";

class THelpButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    Theme: PropTypes.any,
    divStyle: PropTypes.object,
  };

  static defaultProps = {
    onClick: () => console.log("onClick"),
    Theme: null,
    divStyle: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      //
    };
  }

  //==================================================================================================================
  //
  //                                                Function
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    const styles = {
      div: {
        display: "inline-block",
        marginLeft: "10px",
        marginRight: "10px",
        float: "right",
        marginTop: 10,
        ...this.props.divStyle,
      },
      caption: {
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: 0,
      },
      btn: {
        color: "white", // this.props.Theme ? this.props.Theme.PI_PrimaryColor : "black"
        background: this.props.Theme ? this.props.Theme.PI_PrimaryColor : "black", // "white", //
        border: "1px solid " + (this.props.Theme ? this.props.Theme.PI_PrimaryColor : "black"),
        minHeight: "20px",
        minWidth: "20px",
        height: "20px",
        width: "20px",
        boxShadow: "none",
        padding: 10,
        borderRadius: "50%",
      },
    };

    return (
      <div style={styles.div}>
        <TButton
          caption={"?"}
          captionStyles={styles.caption}
          aria-haspopup="true"
          variant={"outlined"}
          color={"secondary"}
          onClick={this.props.onClick}
          style={styles.btn}
        ></TButton>
      </div>
    );
  }
}

export default THelpButton;
