import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";

import { staffMinutes } from "../NonComponents/PIAppStateUtil";
import * as pitu from "../NonComponents/PITableUtil";

import SuperTableShim from "../../common/SuperTableShim";

export const PICapacityStaffMinutesTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

const catCol = 0;
const valCol = 1;

const firstRow = 0;

const numRows = 5;

class PICapacityStaffMinutesTable extends Component {
  static propTypes = {
    [PICapacityStaffMinutesTableProps.allowEditsBoolC]: PropTypes.bool,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [PICapacityStaffMinutesTableProps.allowEditsBoolC]: true,

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTableChange = (newPackTable) => {
    try {
      const props = this.props;
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const newPackTableClone = gbu.cloneObj(newPackTable);

      let capacity = piasu.getModVarValue(modVarObjListClone, pisc.staffMinutesMVTag);

      // capacity[0].init = newPackTableClone.tableData.value[1][1];
      // capacity[0].cont = newPackTableClone.tableData.value[2][1];
      // capacity[1].init = newPackTableClone.tableData.value[3][1];
      // capacity[1].cont = newPackTableClone.tableData.value[4][1];

      staffMinutes(capacity, 1, 1, newPackTableClone.tableData.value[1][1]);
      staffMinutes(capacity, 1, 2, newPackTableClone.tableData.value[2][1]);
      staffMinutes(capacity, 2, 1, newPackTableClone.tableData.value[3][1]);
      staffMinutes(capacity, 2, 2, newPackTableClone.tableData.value[4][1]);

      piasu.setModVarValue(modVarObjListClone, pisc.staffMinutesMVTag, capacity);

      onModVarsChange(modVarObjListClone, () => {});
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const staffMinutes = piasu.getModVarValue(modVarObjList, pisc.staffMinutesMVTag);

      const numMethods = piasu.getTotalNumMethods(methodObjArr);
      const numCols = numMethods + 1;

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      // gbtu.setValue(packTable, firstRow, catCol, "Estimate the number of minutes of staff time required for each visit type");

      gbtu.setValue(packTable, firstRow + 1, catCol, RS(SC.GB_stStaffAMinPerInit));
      gbtu.setValue(packTable, firstRow + 2, catCol, RS(SC.GB_stStaffAMinPerCont));
      gbtu.setValue(packTable, firstRow + 3, catCol, RS(SC.GB_stStaffBMinPerInit));
      gbtu.setValue(packTable, firstRow + 4, catCol, RS(SC.GB_stStaffBMinPerCont));

      for (let m = 1; m <= numMethods; m++) {
        gbtu.setValue(packTable, firstRow, m, piasu.methodName(methodObjArr, m));
      }

      for (let r = 1; r < numRows; r++) {
        for (let c = 1; c < numCols; c++) {
          gbtu.setValue(packTable, r, c, 317); //staffMinutes(capacity, 1, 1));
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      //gbtu.setRDecByRow(packTable, 2, 1);
      gbtu.setColWidth(packTable, catCol, 350);
      gbtu.setColWidth(packTable, valCol, Theme.dataColWidthSmall);
      gbtu.setRowHeight(packTable, firstRow, 50);
      gbtu.setWordWrappedCol(packTable, valCol, true);

      gbtu.setMinAllowedValByCol(packTable, valCol, 0);
      gbtu.setMaxAllowedValByCol(packTable, valCol, gbtc.maxInt);
      gbtu.setID(packTable, "CapacityStaffMinutesPackTable");
      gbtu.setKey(packTable, "CapacityStaffMinutesPackTable");

      if (window.DebugMode) {
        console.log("Component: PICapacityStaffMinutesTable");
        console.log("ModVar(s):");
        console.log(pisc.staffMinutesMVTag);
        console.log(staffMinutes);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"CapacityStaffMinutesTable"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          onPackTableChanged={this.onTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: 20,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PICapacityStaffMinutesTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PICapacityStaffMinutesTable;
