import React, { Component } from "react";
//import * as PropTypes from 'prop-types';

//import Link from "@material-ui/core/Link";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const firstRow = 0;
const numRows = 5;

const firstCol = 0;
const oneMonthCol = 1;
const threeMonthCol = 2;
const sixMonthCol = 3;
const numCols = sixMonthCol + 1;

class PIContRefTable3 extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Column headings */

      gbtu.setValue(packTable, firstRow, oneMonthCol, RS(SC.GB_stAfterOneMonth) + " (%)");
      gbtu.setValue(packTable, firstRow, threeMonthCol, RS(SC.GB_stAfterThreeMonths) + " (%)");
      gbtu.setValue(packTable, firstRow, sixMonthCol, RS(SC.GB_stAfterSixMonths) + " (%)");

      /* Row headings */

      gbtu.setValue(packTable, 1, firstCol, "All");
      gbtu.setValue(packTable, 2, firstCol, "HIV+ partner");
      gbtu.setValue(packTable, 3, firstCol, "Unknown status partner");
      gbtu.setValue(packTable, 4, firstCol, "HIV- partner");

      /* Values */

      gbtu.setValue(packTable, 1, oneMonthCol, 38.7);
      gbtu.setValue(packTable, 2, oneMonthCol, 68.0);
      gbtu.setValue(packTable, 3, oneMonthCol, 37.9);
      gbtu.setValue(packTable, 4, oneMonthCol, 33.8);

      gbtu.setValue(packTable, 1, threeMonthCol, 21.7);
      gbtu.setValue(packTable, 2, threeMonthCol, 49.7);
      gbtu.setValue(packTable, 3, threeMonthCol, 20.5);
      gbtu.setValue(packTable, 4, threeMonthCol, 17.7);

      gbtu.setValue(packTable, 1, sixMonthCol, 11.7);
      gbtu.setValue(packTable, 2, sixMonthCol, 35.7);
      gbtu.setValue(packTable, 3, sixMonthCol, 10.5);
      gbtu.setValue(packTable, 4, sixMonthCol, 9.1);

      gbtu.setColWidth(packTable, firstCol, Theme.itemNameColWidth);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 1);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setRowHeight(packTable, firstRow, 75);
      gbtu.setWordWrappedCol(packTable, firstCol, true);
      gbtu.lockPackTable(packTable, true, true);

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"contRefTable3"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: 30,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "renderTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIContRefTable3;
