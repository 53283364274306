import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes, repeat } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const firstRow = 0;

class PITargDisagDistrictPriorPopsResTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    selectedPriorityPopulations: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const priorPopObjArray = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);

      const PrEP_NEW_TargDisag3DFltArr = piasu.getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_NEW_MVTag);
      const PrEP_CT_TargDisag3DFltArr = piasu.getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_CT_MVTag);
      const districtPop1DObjArr = piasu.getModVarValue(modVarObjList, pisc.districtPopulationsMVTag);
      const PrEPStatus = piasu.getModVarValue(modVarObjList, pisc.selectedPrEPStatusMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      // markAGYW
      //const level1Name = piasu.getModVarValue(modVarObjList, pisc.disagTargArea1NameMVTag);
      //const level2Name = piasu.getModVarValue(modVarObjList, pisc.disagTargArea2NameMVTag);
      const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);
      const templateUploaded = piasu.getModVarValue(modVarObjList, pisc.disagTargTemplateUploadedMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      // markAGYW
      const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);
      const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      const numDistrictsDP = piasu.getNumDistrictsDP(districtPop1DObjArr);
      const numRows = numDistrictsDP + 1;

      let packTable = gbtu.getNewPackTable();

      /* The user can specify up to two levels but needs to specify at least one. If
               they don't, don't show the table. */
      if (level1Name === "" || !templateUploaded) {
        return null;
      } else {
        let level1NameCol = 0;
        let level2NameCol = 1;
        let totalCol = 2;
        let numNonPriorPopCols = totalCol + 1;
        let priorPopStartCol = 3;

        // markAGYW
        if (level2Name === "") {
          totalCol--;
          numNonPriorPopCols--;
          priorPopStartCol--;
        }

        const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArray);
        const numCols = numPriorPops + numNonPriorPopCols;

        packTable = gbtu.resizePackTable(packTable, numRows, numCols);

        /* Set column headings */
        // markAGYW start
        gbtu.setValue(packTable, firstRow, level1NameCol, level1Name);
        if (level2Name !== "") {
          gbtu.setValue(packTable, firstRow, level2NameCol, level2Name);
        }
        // markAGYW end
        gbtu.setValue(packTable, firstRow, totalCol, RS(SC.GB_stTotal));
        let c = priorPopStartCol;
        for (let pp = 1; pp <= numPriorPops; pp++) {
          const priorPopName = piasu.getPriorPopName(priorPopObjArray, pp);
          gbtu.setValue(packTable, firstRow, c, priorPopName);

          c++;
        }

        const levelCount = levelNames1DStrArr.filter((name) => Boolean(name)).length;

        const populationPotentialTotals = Array(numPriorPops).fill(0);

        for (let dp = 1; dp <= numDistrictsDP; dp++) {
          /* Set row headings. */
          const provinceName = piasu.provinceNameDP(districtPop1DObjArr, dp);
          gbtu.setValue(packTable, dp, level1NameCol, provinceName);

          // markAGYW
          if (level2Name !== "") {
            const districtName = piasu.districtNameDP(districtPop1DObjArr, dp);
            gbtu.setValue(packTable, dp, level2NameCol, districtName);
          }

          let c = priorPopStartCol;
          let total = 0;
          let grayOutTotalCol = true;

          for (let pp = 1; pp <= numPriorPops; pp++) {
            const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
            if (methodEligMstIDStr === pisc.yesCVOMstID) {
              let target = 0;

              if (PrEPStatus === pisc.PrEP_NEW_StatusMstID) {
                const PrEP_NEW_Val = piasu.getPrEP_NEW_TargDisag(
                  PrEP_NEW_TargDisag3DFltArr,
                  selectedMethodCurrID,
                  dp,
                  pp
                );
                target = PrEP_NEW_Val;
              } else {
                const PrEP_CT_Val = piasu.getPrEP_CTTargDisag(PrEP_CT_TargDisag3DFltArr, selectedMethodCurrID, dp, pp);
                target = PrEP_CT_Val;
              }

              populationPotentialTotals[pp - 1] += target;

              if (!props.selectedPriorityPopulations?.[dp - 1]?.[c - levelCount - 1] ?? true) {
                target = 0;
              }

              total += target;

              gbtu.setValue(packTable, dp, c, target);

              grayOutTotalCol = false;
            } else {
              gbtu.lockCol(packTable, c, true, true);
              const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
              gbtu.setColBGColor(packTable, c, gainsboroBase10);
            }

            c++;
          }

          if (grayOutTotalCol) {
            gbtu.lockCell(packTable, dp, totalCol, true, true);
            const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
            gbtu.setCellBGColor(packTable, dp, totalCol, gainsboroBase10);
          } else {
            gbtu.setValue(packTable, dp, totalCol, total);
          }
        }

        // Normalize population targets

        for (let populationIndex = 0; populationIndex < numPriorPops; populationIndex++) {
          let populationTotal = 0;

          for (let districtIndex = 0; districtIndex < numDistrictsDP; districtIndex++) {
            const value = gbtu.getValue(packTable, districtIndex + 1, priorPopStartCol + populationIndex);
            if (typeof value !== "number") continue;

            populationTotal += value;
          }

          if (populationTotal > 0) {
            for (let districtIndex = 0; districtIndex < numDistrictsDP; districtIndex++) {
              const target = gbtu.getValue(packTable, districtIndex + 1, priorPopStartCol + populationIndex);
              if (typeof target !== "number") continue;

              const normalizedTarget = target * (populationPotentialTotals[populationIndex] / populationTotal);

              gbtu.setValue(packTable, districtIndex + 1, priorPopStartCol + populationIndex, normalizedTarget);
            }
          }
        }

        for (let districtIndex = 0; districtIndex < numDistrictsDP; districtIndex++) {
          let districtTotal = 0;

          for (let populationIndex = 0; populationIndex < numPriorPops; populationIndex++) {
            const value = gbtu.getValue(packTable, districtIndex + 1, priorPopStartCol + populationIndex);
            if (typeof value !== "number") continue;

            districtTotal += value;
          }

          gbtu.setValue(packTable, districtIndex + 1, priorPopStartCol - 1, districtTotal);
        }

        gbtu.lockPackTable(packTable, true, false);
        gbtu.alignNumericCellsRight(packTable);
        gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
        gbtu.setRDecs(packTable, 0);
        gbtu.setColWidths(packTable, Theme.dataColWidthMed);
        gbtu.setColWidth(packTable, level1NameCol, Theme.itemNameColWidth);
        // markAGYW
        if (level2Name !== "") {
          gbtu.setColWidth(packTable, level2NameCol, Theme.itemNameColWidth);
        }
        gbtu.setRowHeight(packTable, firstRow, 50);

        if (window.DebugMode) {
          console.log("Component: PITargDisagDistrictPriorPopsResTable");
          console.log("ModVar(s):");
          console.log(pisc.targDisagDistPopPrEP_NEW_MVTag + ": ");
          console.log(PrEP_NEW_TargDisag3DFltArr);
          console.log(pisc.targDisagDistPopPrEP_CT_MVTag + ": ");
          console.log(PrEP_NEW_TargDisag3DFltArr);
          console.log(pisc.priorPopMethodEligMVTag + ": ");
          console.log(priorPopMethodEligObjArr);
          console.log("");
        }

        const stdTable = (
          <SuperTableShim
            font={Theme.fontFamily}
            headerBackgroundColor={Theme.PI_PrimaryColor}
            oddRowBackgroundColor={Theme.PI_BandColor}
            packTable={packTable}
            types={generateTypes(packTable, [
              ...repeat("s", levelCount),
              ...repeat("n", packTable.GBColCount - levelCount),
            ])}
            onPackTableChanged={() => {}}
            removedMenuNames={pitu.tableHideMenuItems}
            style={{
              fontFamily: Theme.fontFamily,
              marginTop: Theme.ctrlSpacing,
              padding: 0,
            }}
          />
        );

        return stdTable;
      }
    };

    return gbu.tryRenderFn(fn, "render PITargDisagDistrictPriorPopsTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PITargDisagDistrictPriorPopsResTable;
