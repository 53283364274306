import PropTypes from "prop-types";
import DraggableDocumentWindow from "./DraggableDocumentWindow";

const HelpWindow = ({ language, page, ...rest }) => (
  <DraggableDocumentWindow {...rest} url={`PrEPitHelp/${language}/HTML/${page}.htm`} />
);

HelpWindow.propTypes = {
  language: PropTypes.string,
  page: PropTypes.string.isRequired,
};

HelpWindow.defaultProps = {
  language: "en",
};

export default HelpWindow;
