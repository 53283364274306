import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";

import { onCalculate } from "../../PI/NonComponents/PICalc";
import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";
import * as pitu from "../NonComponents/PITableUtil";

import { generateHeterogeneousTypes } from "../../../utilities";

const priorPopCurrIDC = "priorPopCurrID";
const methodCurrIDC = "methodCurrID";

class PIAssignContCurvesToPriorPopsTable extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,

    [pias.onDialogChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: "",

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),
  };

  state = {
    /* Note: Fixed rows are NOT included. All numbers are zero-based. */
    [pip.focusedCell]: {
      [pip.rowFocus]: 0,
      [pip.colFocus]: 1,
    },
    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 0,
        [pip.rowEnd]: 0,
        [pip.columnStart]: 1,
        [pip.columnEnd]: 1,
      },
    ],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onContCurveComboBoxChange = (value, text, info) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onModVarsChange = props[pias.onModVarsChange];
    //const priorPopObjListClone = gbu.cloneObj(props[pip.priorPopObjList]);
    let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
    let priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
    let contCurveObjList = piasu.getModVarValue(modVarObjListClone, pisc.continuationCurvesMVTag);

    const contCurveCurrID = value + 1;
    const priorPopCurrID = info[priorPopCurrIDC];
    const methodCurrID = info[methodCurrIDC];

    const mstID = piasu.getContCurveMstID(contCurveObjList, contCurveCurrID);
    piasu.setPriorPopContCurveMstID(priorPopObjList, methodCurrID, priorPopCurrID, mstID);

    onCalculatingChange(true, () => {
      //props[pip.onPriorityPopChange](priorPopObjListClone);
      onModVarsChange(modVarObjListClone, false, () => {
        /* Put this here because after the editor values change, the user needs to see
                   the graph under it update. */
        onCalculate(
          modVarObjListClone,
          "",
          onDialogChange,
          (response) => {
            onModVarsChange(response, false, () => {
              onCalculatingChange(false);
            });
          },
          () => onCalculatingChange(false)
        );
      });
    });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      let stdTable;

      const props = this.props;

      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      const contCurveObjList = piasu.getModVarValue(modVarObjList, pisc.continuationCurvesMVTag);
      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      const state = this.state;

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numMethods = piasu.getTotalNumMethods(methodObjList);
      const numRows = numPriorPops + 1; // includes one header

      const firstRow = 0;

      const priorPopCol = 0;
      const numCols = numMethods + 1;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));

      for (let m = 1; m <= numMethods; m++) {
        gbtu.setValue(packTable, firstRow, m, piasu.methodName(methodObjList, m));
      }

      let comboBoxObj = pitu.getComboBoxObj(numRows, numCols);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, pp, priorPopCol, priorPopName);

        for (let m = 1; m <= numMethods; m++) {
          const methodMstID = piasu.methodMstID(methodObjList, m);
          const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);

          if (methodEligMstIDStr !== pisc.yesCVOMstID) {
            gbtu.lockCell(packTable, pp, m, true, true);
            const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
            gbtu.setCellBGColor(packTable, pp, m, gainsboroBase10);

            continue;
          }

          gbtu.setHasComboBox(packTable, pp, m, true);

          comboBoxObj[pitu.style2DObjArray][pp][m] = {
            color: Theme.blackColor,
            fontFamily: Theme.fontFamily,
          };

          let priorPopCurrIDArray = [];
          priorPopCurrIDArray.length = piasu.getTotalNumContCurves(contCurveObjList);
          priorPopCurrIDArray.fill({
            [priorPopCurrIDC]: pp,
            [methodCurrIDC]: m,
          });
          comboBoxObj[pitu.info3DIntArray][pp][m] = priorPopCurrIDArray;

          comboBoxObj[pitu.item3DStrArray][pp][m] = piasu.getContCurveNames(contCurveObjList);

          const contCurveMstID = piasu.getPriorPopContCurveMstID(priorPopObjList, m, pp);
          const contCurveCurrID = piasu.getContCurveCurrID(contCurveObjList, contCurveMstID); // markProblem
          comboBoxObj[pitu.idx2DIntArray][pp][m] = contCurveCurrID - 1;

          comboBoxObj[pitu.onChange2DFuncArray][pp][m] = this.onContCurveComboBoxChange;
        }
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      gbtu.setColWidths(packTable, Theme.itemNameColWidth);
      gbtu.setColWidth(packTable, priorPopCol, Theme.itemNameColWidthWide);
      gbtu.setWordWrappedCols(packTable, true);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, 0);
      gbtu.setID(packTable, "assignCurvesPackTable");
      gbtu.setKey(packTable, "assignCurvesPackTable");

      if (window.DebugMode) {
        console.log("Component: PIAssignContCurvesToPriorPopsTable");
        console.log("ModVar(s):");
        console.log(pisc.continuationCurvesMVTag);
        console.log(contCurveObjList);
        console.log(pisc.priorPopsMVTag);
        console.log(priorPopObjList);
        console.log("");
      }

      stdTable = (
        <GbStdTableWithComboBoxes
          focusedCell={state[pip.focusedCell]}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          gridKey={tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateHeterogeneousTypes(packTable, "dd")}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
          comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
          comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
          comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
          onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
          width={0}
          limitToTableWidth={true}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIAssignContCurvesToPriorPopsTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAssignContCurvesToPriorPopsTable;
