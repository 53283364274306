import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TComboBox from "../../common/TComboBox";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pip from "../NonComponents/PIProps";

import * as gbu from "../../GB/GBUtil";
import { onCalculate } from "../NonComponents/PICalc";
import { getTotalNumMethods } from "../NonComponents/PIAppStateUtil";

/* Attributes specific to PIMethodComboBox. */

export const PIMethodComboBoxProps = {
  outerComboBoxStyle: "outerComboBoxStyle", // affects combobox only
  outerStyle: "outerStyle", // affects label + combobox
  row: "row", // if true, label and combo are side-by-side; stacked otherwise
};

class PIMethodComboBox extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [PIMethodComboBoxProps.outerComboBoxStyle]: PropTypes.object,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [PIMethodComboBoxProps.outerStyle]: PropTypes.object,

    [pip.recalcBool]: PropTypes.bool,

    [PIMethodComboBoxProps.row]: PropTypes.bool,

    [pip.selectedMethodMstIDStr]: PropTypes.string,
    [pip.onSelectedMethodChange]: PropTypes.func, // alternative to onModVarsChange for local state

    [pip.selectedMethodMVTagStr]: PropTypes.string,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [PIMethodComboBoxProps.outerComboBoxStyle]: {},

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log("onModVarsChange"),

    [PIMethodComboBoxProps.outerStyle]: {},

    [pip.recalcBool]: false,

    [PIMethodComboBoxProps.row]: true,

    [pip.selectedMethodMstIDStr]: "",
    [pip.onSelectedMethodChange]: undefined,

    [pip.selectedMethodMVTagStr]: pisc.selectedMethodMVTag,
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onMethodComboBoxChange = (value, text, info) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onSelectedMethodChange = props[pip.onSelectedMethodChange];

    const methodObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.methodsMVTag);

    let methodMstID;
    if (info === getTotalNumMethods(methodObjArr) + 1) {
      methodMstID = pisc.allMethodsCombined;
    } else {
      methodMstID = piasu.methodMstID(methodObjArr, info);
    }

    if (typeof onSelectedMethodChange !== "undefined") {
      onSelectedMethodChange(methodMstID);
    } else {
      const onModVarsChange = props[pias.onModVarsChange];
      const recalcBool = props[pip.recalcBool];
      //const selectedMethodMVTagStr = props[pip.selectedMethodMVTagStr]; #notused

      if (methodMstID !== pisc.allMethodsCombined) {
        piasu.setModVarValue(modVarObjArrClone, pisc.selectedMethodMVTag, methodMstID);
      }

      piasu.setModVarValue(modVarObjArrClone, pisc.disagTargSelectedMethodMVTag, methodMstID);

      onModVarsChange(modVarObjArrClone, false, () => {
        if (recalcBool) {
          onCalculatingChange(true, () => {
            onCalculate(
              modVarObjArrClone,
              "",
              onDialogChange,
              (response) => {
                onModVarsChange(response, false, () => {
                  onCalculatingChange(false);
                });
              },
              () => onCalculatingChange(false)
            );
          });
        }
      });
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const comboBoxOuterStyle = props[PIMethodComboBoxProps.outerComboBoxStyle];
    const modVarObjArr = props[pias.modVarObjList];
    const outerStyle = props[PIMethodComboBoxProps.outerStyle];
    const rowBool = props[PIMethodComboBoxProps.row];
    const selectedMethodMVTagStr = props[pip.selectedMethodMVTagStr];
    const onSelectedMethodChange = props[pip.onSelectedMethodChange];

    const methodObjArr = piasu.getModVarValue(modVarObjArr, pisc.methodsMVTag);
    let method1DStrArray = piasu.getMethodNames(methodObjArr);
    let methodCurrIDs1DArray = piasu.getMethodCurrIDArray(methodObjArr);

    if (selectedMethodMVTagStr === pisc.disagTargSelectedMethodMVTag) {
      method1DStrArray.push(RS(SC.GB_stAllMethodsCombined));
      methodCurrIDs1DArray.push(getTotalNumMethods(methodObjArr) + 1);
    }

    let selectedMethodMstID;
    if (typeof onSelectedMethodChange !== "undefined") {
      selectedMethodMstID = props[pip.selectedMethodMstIDStr];
    } else {
      selectedMethodMstID = piasu.getModVarValue(modVarObjArr, selectedMethodMVTagStr);
    }

    let methodCurrID;
    if (selectedMethodMstID === pisc.allMethodsCombined) {
      methodCurrID = getTotalNumMethods(methodObjArr) + 1;
    } else {
      methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID);
    }

    let methodStr = RS(SC.GB_stMethod) + (rowBool ? ": " : "");

    const methodLab = (
      <p
        style={{
          ...Theme.labelStyle,
          margin: 0,
          marginRight: rowBool ? Theme.leftIndent : 0,
        }}
      >
        {methodStr}
      </p>
    );

    const methodComboBox = (
      <TComboBox
        onChange={this.onMethodComboBoxChange}
        info={methodCurrIDs1DArray}
        itemIndex={methodCurrID - 1}
        items={method1DStrArray}
        outerStyle={{
          fontFamily: Theme.fontFamily,
          width: 150,
          ...comboBoxOuterStyle,
        }}
        style={{
          fontFamily: Theme.fontFamily,
          marginTop: rowBool ? 0 : 5,
        }}
      />
    );

    return (
      <div
        style={{
          alignItems: rowBool ? "center" : "flex-start",
          display: "inline-flex",
          flexDirection: rowBool ? "row" : "column",
          ...outerStyle,
        }}
      >
        {methodLab}
        {methodComboBox}
      </div>
    );
  }
}

export default PIMethodComboBox;
