import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import TButton from "../../common/TButton";

/* Attributes specific to PINavBtnDiv. */

export const PINavBtnDivProps = {
  backBtnStyle: "backBtnStyle",
  divStyle: "divStyle",
  nextBtnStyle: "nextBtnStyle",

  enableBackBtn: "enableBackBtn",
  enableNextBtn: "enableNextBtn",

  showBackBtn: "showBackBtn",
  showNextBtn: "showNextBtn",

  backBtnKey: "backBtnKey",
  nextBtnKey: "nextBtnKey",

  onBackBtnClick: "onBackBtnClick",
  onNextBtnClick: "onNextBtnClick",
};

class PINavBtnDiv extends Component {
  static propTypes = {
    [PINavBtnDivProps.backBtnStyle]: PropTypes.object,
    [PINavBtnDivProps.divStyle]: PropTypes.object,
    [PINavBtnDivProps.nextBtnStyle]: PropTypes.object,

    [PINavBtnDivProps.backBtnKey]: PropTypes.string,
    [PINavBtnDivProps.nextBtnKey]: PropTypes.string,

    [PINavBtnDivProps.enableBackBtn]: PropTypes.bool,
    [PINavBtnDivProps.enableNextBtn]: PropTypes.bool,

    [PINavBtnDivProps.showBackBtn]: PropTypes.bool,
    [PINavBtnDivProps.showNextBtn]: PropTypes.bool,

    [PINavBtnDivProps.onBackBtnClick]: PropTypes.func,
    [PINavBtnDivProps.onNextBtnClick]: PropTypes.func,
  };

  static defaultProps = {
    [PINavBtnDivProps.backBtnStyle]: {},
    [PINavBtnDivProps.divStyle]: {},
    [PINavBtnDivProps.nextBtnStyle]: {},

    [PINavBtnDivProps.backBtnKey]: "backBtn",
    [PINavBtnDivProps.nextBtnKey]: "nextBtn",

    [PINavBtnDivProps.enableBackBtn]: true,
    [PINavBtnDivProps.enableNextBtn]: true,

    [PINavBtnDivProps.showBackBtn]: true,
    [PINavBtnDivProps.showNextBtn]: true,

    [PINavBtnDivProps.onBackBtnClick]: () => console.log([PINavBtnDivProps.onBackBtnClick]),
    [PINavBtnDivProps.onNextBtnClick]: () => console.log([PINavBtnDivProps.onNextBtnClick]),
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onBackBtnClick = () => {
    this.props[PINavBtnDivProps.onBackBtnClick]();
  };

  onNextBtnClick = () => {
    this.props[PINavBtnDivProps.onNextBtnClick]();
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const divStyle = props[PINavBtnDivProps.divStyle];
    const backBtnStyle = props[PINavBtnDivProps.backBtnStyle];
    const nextBtnStyle = props[PINavBtnDivProps.nextBtnStyle];
    const backBtnKey = props[PINavBtnDivProps.backBtnKey];
    const nextBtnKey = props[PINavBtnDivProps.nextBtnKey];
    const showBackBtn = props[PINavBtnDivProps.showBackBtn];
    const showNextBtn = props[PINavBtnDivProps.showNextBtn];
    const enableBackBtn = props[PINavBtnDivProps.enableBackBtn];
    const enableNextBtn = props[PINavBtnDivProps.enableNextBtn];

    let backBtn = null;

    if (showBackBtn) {
      backBtn = (
        <TButton
          caption={RS(SC.GB_stBack)}
          captionStyles={{
            fontSize: 16,
            ...Theme.menuItemFontStyle,
            //marginTop    : 5,
            // marginBottom : 5,
          }}
          containerStyle={{
            display: "inline-block",
            fontFamily: Theme.fontFamily,
            marginBottom: 10,
            //marginLeft   : Theme.leftIndent,
            marginTop: Theme.topIndent,
            ...backBtnStyle,
          }}
          disabled={!enableBackBtn}
          key={backBtnKey}
          onClick={this.onBackBtnClick}
        />
      );
    }

    let nextBtn = null;

    if (showNextBtn) {
      nextBtn = (
        <TButton
          caption={RS(SC.GB_stNext)}
          captionStyles={{
            fontSize: 16,
            ...Theme.menuItemFontStyle,
            //marginTop    : 5,
            //marginBottom : 5,
          }}
          containerStyle={{
            display: "inline-block",
            fontFamily: Theme.fontFamily,
            marginBottom: 20,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.topIndent,
            ...nextBtnStyle,
          }}
          disabled={!enableNextBtn}
          key={nextBtnKey}
          onClick={this.onNextBtnClick}
        />
      );
    }

    return (
      <div key={"PINavBtnDiv"} style={divStyle}>
        {backBtn}
        {nextBtn}
      </div>
    );
  }
}

export default PINavBtnDiv;
