import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";
import * as pic from "../../NonComponents/PIConst";

import { generateTypes, repeat } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const priorPopRow = 0;
const initTotalRow = 1;
const persCostCatInit = 2;
const labCostCatInit = 3;
const otherRecCostCatInit = 4;
const capCostsCostCatInit = 5;
const contTotalRow = 6;
const persCostCatCont = 7;
const labCostCatCont = 8;
const otherRecCostCatCont = 9;
const capCostsCostCatCont = 10;
const numRows = capCostsCostCatCont + 1;

const costCatCol = 0;
const SDCsCol = 1;
const keyPopsCol = 2;
const AGYWCol = 3;
const ratioCol = 4;
const numCols = ratioCol + 1;

class PIDefCostsPerVisitRefTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const costPerVisitObjArr = piasu.getModVarValue(modVarObjList, pisc.defCostsPerVisitLiteMVTag);
      const costPerVisitRatiosObjArr = piasu.getModVarValue(modVarObjList, pisc.costPerVisitRatiosMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set row headings and formatting. */

      gbtu.setValue(packTable, priorPopRow, costCatCol, RS(SC.GB_stCostCategory));

      gbtu.setValue(packTable, initTotalRow, costCatCol, RS(SC.GB_stInitiationVisit));
      gbtu.setFontStyle(packTable, initTotalRow, costCatCol, [gbtc.fontStyle.bold]);

      gbtu.setValue(packTable, persCostCatInit, costCatCol, RS(SC.GB_stPersonnel));
      gbtu.setIndent(packTable, persCostCatInit, true, Theme.leftIndent);

      gbtu.setValue(packTable, labCostCatInit, costCatCol, RS(SC.GB_stInitVisitLabs));
      gbtu.setIndent(packTable, labCostCatInit, true, Theme.leftIndent);

      gbtu.setValue(packTable, otherRecCostCatInit, costCatCol, RS(SC.GB_stOtherRecurOtherCommEtc));
      gbtu.setIndent(packTable, otherRecCostCatInit, true, Theme.leftIndent);

      gbtu.setValue(packTable, capCostsCostCatInit, costCatCol, RS(SC.GB_stCapCosts));
      gbtu.setIndent(packTable, capCostsCostCatInit, true, Theme.leftIndent);

      gbtu.setValue(packTable, contTotalRow, costCatCol, RS(SC.GB_stContinuationVisit));
      gbtu.setFontStyle(packTable, contTotalRow, costCatCol, [gbtc.fontStyle.bold]);

      gbtu.setValue(packTable, persCostCatCont, costCatCol, RS(SC.GB_stPersonnel));
      gbtu.setIndent(packTable, persCostCatCont, true, Theme.leftIndent);

      gbtu.setValue(packTable, labCostCatCont, costCatCol, RS(SC.GB_stContVisitLabs));
      gbtu.setIndent(packTable, labCostCatCont, true, Theme.leftIndent);

      gbtu.setValue(packTable, otherRecCostCatCont, costCatCol, RS(SC.GB_stOtherRecurOtherCommEtc));
      gbtu.setIndent(packTable, otherRecCostCatCont, true, Theme.leftIndent);

      gbtu.setValue(packTable, capCostsCostCatCont, costCatCol, RS(SC.GB_stCapCosts));
      gbtu.setIndent(packTable, capCostsCostCatCont, true, Theme.leftIndent);

      /* Set column headings */

      gbtu.setValue(packTable, priorPopRow, SDCsCol, RS(SC.GB_stSDCs));
      gbtu.setValue(packTable, priorPopRow, keyPopsCol, RS(SC.GB_stKeyPopCC));
      gbtu.setValue(packTable, priorPopRow, AGYWCol, RS(SC.GB_stAGYWAbbr));

      let col = 1;

      for (let mp = 1; mp <= pic.numMinPatCPV; mp++) {
        let row = 1;

        for (let vt = 1; vt <= pic.numVisitTypes; vt++) {
          /* Total row comes first but is last in the data structure. */
          const value = piasu.getCostPerVisit(costPerVisitObjArr, selectedMethodCurrID, mp, vt, pic.numCostCatCPV);
          gbtu.setValue(packTable, row, col, value);

          row++;

          for (let cc = 1; cc <= pic.numCostCatCPV - 1; cc++) {
            const value = piasu.getCostPerVisit(costPerVisitObjArr, selectedMethodCurrID, mp, vt, cc);
            gbtu.setValue(packTable, row, col, value);

            row++;
          }
        }

        col++;
      }

      gbtu.setValue(
        packTable,
        otherRecCostCatInit,
        ratioCol,
        piasu.costPerVisitRatios(costPerVisitRatiosObjArr, selectedMethodCurrID, 0)
      );
      gbtu.setValue(
        packTable,
        capCostsCostCatInit,
        ratioCol,
        piasu.costPerVisitRatios(costPerVisitRatiosObjArr, selectedMethodCurrID, 1)
      );
      gbtu.setValue(
        packTable,
        otherRecCostCatCont,
        ratioCol,
        piasu.costPerVisitRatios(costPerVisitRatiosObjArr, selectedMethodCurrID, 0)
      );
      gbtu.setValue(
        packTable,
        capCostsCostCatCont,
        ratioCol,
        piasu.costPerVisitRatios(costPerVisitRatiosObjArr, selectedMethodCurrID, 1)
      );

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, priorPopRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 2);
      gbtu.setRowHeight(packTable, priorPopRow, 50);

      gbtu.setColWidth(packTable, costCatCol, Theme.itemNameColWidth);
      for (let c = 1; c < numCols; c++) {
        gbtu.setColWidth(packTable, c, Theme.dataColWidthSmall);
      }
      gbtu.setWordWrappedCol(packTable, costCatCol, Theme.itemNameColWidth);

      gbtu.lockPackTable(packTable, true, true);

      if (window.DebugMode) {
        console.log("Component: PIDefCostsPerVisitRefTable");
        console.log("ModVar(s):");
        console.log(pisc.defCostsPerVisitLiteMVTag);
        console.log(costPerVisitObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={{ ...packTable, GBRowHeights: repeat(50, packTable.GBRowCount) }}
          types={generateTypes(packTable)}
          onPackTableChanged={() => {}} //this.onTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          width={0}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIDefCostsPerVisitRefTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIDefCostsPerVisitRefTable;
