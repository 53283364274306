import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

class TAlertDialog extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.any,
  };

  static defaultProps = {
    open: true,
    onClose: () => {},
    title: "Alert Caption",
    content: null,
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              this.props.onClose?.(event, reason);
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>

          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description"> */}
            {this.props.content}
            {/* </DialogContentText> */}
          </DialogContent>

          <DialogActions>
            <Button onClick={this.props.onClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default TAlertDialog;
