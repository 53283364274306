import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../../app/Theme";

import TTabs2 from "../../../common/TTabs2";

import * as gbu from "../../../GB/GBUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piu from "../../NonComponents/PIUtil";
import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";
import * as pisc from "../../NonComponents/PIServerConst";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import PIAvgUnitCostPerMonthPriorPopRefTable from "../../Tables/Reference/PIAvgUnitCostPerMonthPriorPopRefTable";
import PIAvgUnitCostPerMonthServDelivStratDetRefTable from "../../Tables/Reference/PIAvgUnitCostPerMonthServDelivStratDetRefTable";
import PIAvgUnitCostPopGraph from "../../Graphs/PIAvgUnitCostPopGraph";
import PIAvgUnitCostStratGraph from "../../Graphs/PIAvgUnitCostStratGraph";

const byPriorPopTab = 0;
const byServDelivStratTab = 1;

const selectedTabIdxC = "selectedTabIdx";

class PIAvgUnitCostPrEPUseRefForm extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  state = {
    [selectedTabIdxC]: byPriorPopTab,
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTabIdxChange = (tabIdx) => {
    this.setState({
      [selectedTabIdxC]: tabIdx,
    });
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const state = this.state;
    const selectedTabIdx = state[selectedTabIdxC];

    let tabs = null;
    let byServDelivStratLab = null;
    let avgUnitCostPerMonthServDelivStratDetRefTable = null;
    let avgUnitCostStratGraph = null;
    let byPriorPopLab = null;
    let avgCostPerMonthTable = null;
    let avgUnitCostPopGraph = null;

    const costingModeMstID = piasu.getModVarValue(modVarObjList, pisc.costingModuleMVTag);

    if (!piu.usingCostsLite(costingModeMstID)) {
      tabs = (
        <TTabs2
          onChange={this.onTabIdxChange}
          selectedTabIdx={selectedTabIdx}
          tabBackgroundColor={"inherit"}
          tabBarOutline={"none"}
          tabContents={["", ""]}
          tabTitles={[RS(SC.GB_stByPriorPopCap), RS(SC.GB_stByServDelivStratCap)]}
        />
      );

      if (selectedTabIdx === byServDelivStratTab) {
        byServDelivStratLab = (
          <p
            key={"byServDelivStratLab"}
            style={{
              ...Theme.labelStyle,
            }}
          >
            {RS(SC.GB_stAvgUnitCostByMonthPrEPUse) + ", " + RS(SC.GB_stByServDelivStrat) + " (" + RS(SC.GB_stUSD) + ")"}
          </p>
        );

        avgUnitCostPerMonthServDelivStratDetRefTable = (
          <PIAvgUnitCostPerMonthServDelivStratDetRefTable
            {...{
              [pias.modVarObjList]: modVarObjList,
            }}
          />
        );

        avgUnitCostStratGraph = (
          <PIAvgUnitCostStratGraph
            {...{
              [pias.modVarObjList]: modVarObjList,
            }}
          />
        );
      } else if (selectedTabIdx === byPriorPopTab) {
        byPriorPopLab = (
          <p
            key={"byPriorPopLab"}
            style={{
              ...Theme.labelStyle,
            }}
          >
            {RS(SC.GB_stAvgUnitCostByMonthPrEPUse) + ", " + RS(SC.GB_stByPriorPop) + " (" + RS(SC.GB_stUSD) + ")"}
          </p>
        );

        avgCostPerMonthTable = (
          <PIAvgUnitCostPerMonthPriorPopRefTable
            {...{
              [pias.modVarObjList]: modVarObjList,
            }}
          />
        );

        avgUnitCostPopGraph = (
          <PIAvgUnitCostPopGraph
            {...{
              [pias.modVarObjList]: modVarObjList,
            }}
          />
        );
      }
    } else {
      byPriorPopLab = (
        <p
          key={"byPriorPopLab"}
          style={{
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stAvgUnitCostByMonthPrEPUse) + ", " + RS(SC.GB_stByPriorPop) + " (" + RS(SC.GB_stUSD) + ")"}
        </p>
      );

      avgCostPerMonthTable = (
        <PIAvgUnitCostPerMonthPriorPopRefTable
          {...{
            [pias.modVarObjList]: modVarObjList,
          }}
        />
      );

      avgUnitCostPopGraph = (
        <PIAvgUnitCostPopGraph
          {...{
            [pias.modVarObjList]: modVarObjList,
          }}
        />
      );
    }

    return (
      <React.Fragment>
        {tabs}
        {byServDelivStratLab}
        {avgUnitCostPerMonthServDelivStratDetRefTable}
        {avgUnitCostStratGraph}
        {byPriorPopLab}
        {avgCostPerMonthTable}
        {avgUnitCostPopGraph}
      </React.Fragment>
    );
  }
}

export default PIAvgUnitCostPrEPUseRefForm;
