import React, { Component } from "react";
//import * as PropTypes from 'prop-types';

import Link from "@material-ui/core/Link";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const firstRow = 0;
const numRows = 6;

const firstCol = 0;
//const curveCol       = 0;
const oneMonthCol = 1;
const threeMonthCol = 2;
const sixMonthCol = 3;
const twelveMonthCol = 4;
const sourceCol = 5;
const numCols = sourceCol + 1;

class PIContRefTable1 extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      let frameworkComponents = {};

      const Source = ({ linkKey, row }) => {
        let link;

        switch (row) {
          case 1: {
            link = (
              <Link
                key={linkKey}
                onClick={() =>
                  gbu.URL_Click("https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1002099")
                }
                style={{
                  color: Theme.PI_LinkBlue,
                  cursor: "pointer",
                }}
                underline={"hover"}
              >
                Baeten et al., 2016
              </Link>
            );

            break;
          }

          case 2: {
            link = (
              <Link
                key={linkKey}
                onClick={() =>
                  gbu.URL_Click("https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1002444")
                }
                style={{
                  color: Theme.PI_LinkBlue,
                  cursor: "pointer",
                }}
                underline={"hover"}
              >
                Eakle et al., 2017
              </Link>
            );

            break;
          }

          case 3:
          case 4:
          case 5: {
            link = (
              <Link
                key={linkKey}
                onClick={() =>
                  gbu.URL_Click(
                    "https://lvcthealth.org/wp-content/uploads/2018/10/How-long-will-they-take-it-Oral-pre-exposure-prophylaxis-PrEP-retention-in-a-demonstration-project-in-Kenya.pdf"
                  )
                }
                style={{
                  color: Theme.PI_LinkBlue,
                  cursor: "pointer",
                }}
                underline={"hover"}
              >
                Kyongo IAC presentation, 2018
              </Link>
            );

            break;
          }

          default: {
            throw Error("Unknown row: " + row);
          }
        }

        return link;
      };

      /* Column headings */

      gbtu.setValue(packTable, firstRow, oneMonthCol, RS(SC.GB_stAfterOneMonth) + " (%)");
      gbtu.setValue(packTable, firstRow, threeMonthCol, RS(SC.GB_stAfterThreeMonths) + " (%)");
      gbtu.setValue(packTable, firstRow, sixMonthCol, RS(SC.GB_stAfterSixMonths) + " (%)");
      gbtu.setValue(packTable, firstRow, twelveMonthCol, RS(SC.GB_stAfter12Months) + " (%)");
      gbtu.setValue(packTable, firstRow, sourceCol, RS(SC.GB_stSource));

      /* Row headings */

      gbtu.setValue(packTable, 1, firstCol, "SDCs (Partners Demonstration Project, Kenya and Uganda)");
      gbtu.setValue(packTable, 2, firstCol, "FSW (TAPS Study, South Africa)");
      gbtu.setValue(packTable, 3, firstCol, "FSW (PCP Kenya)");
      gbtu.setValue(packTable, 4, firstCol, "MSM (PCP Kenya)");
      gbtu.setValue(packTable, 5, firstCol, "Young Women (PCP Kenya)");

      /* Values */

      gbtu.setValue(packTable, 1, oneMonthCol, 85.3);
      gbtu.setValue(packTable, 2, oneMonthCol, 53.0);
      gbtu.setValue(packTable, 3, oneMonthCol, 60.6);
      gbtu.setValue(packTable, 4, oneMonthCol, 45.2);
      gbtu.setValue(packTable, 5, oneMonthCol, 30.2);

      gbtu.setValue(packTable, 1, threeMonthCol, 74.2);
      gbtu.setValue(packTable, 2, threeMonthCol, 44.0);
      gbtu.setValue(packTable, 3, threeMonthCol, 39.4);
      gbtu.setValue(packTable, 4, threeMonthCol, 30.1);
      gbtu.setValue(packTable, 5, threeMonthCol, 19.8);

      gbtu.setValue(packTable, 1, sixMonthCol, 48.3);
      gbtu.setValue(packTable, 2, sixMonthCol, 30.0);
      gbtu.setValue(packTable, 3, sixMonthCol, 21.2);
      gbtu.setValue(packTable, 4, sixMonthCol, 20.5);
      gbtu.setValue(packTable, 5, sixMonthCol, 11.6);

      gbtu.setValue(packTable, 1, twelveMonthCol, 18.9);
      gbtu.setValue(packTable, 2, twelveMonthCol, 22.0);
      gbtu.setValue(packTable, 3, twelveMonthCol, "");
      gbtu.setValue(packTable, 4, twelveMonthCol, "");
      gbtu.setValue(packTable, 5, twelveMonthCol, "");

      for (let row = 0; row < numRows; row++) {
        /* Need to save these into variables before passing or the link component will
                   be sent the wrong row, col. */
        let rowPassed = row;
        let colPassed = sourceCol;

        let linkKey = "link row" + rowPassed + "_col" + colPassed;

        packTable.components[rowPassed][colPassed] = () => <Source linkKey={linkKey} row={rowPassed} />;
      }

      gbtu.setColWidth(packTable, firstCol, Theme.itemNameColWidth);
      gbtu.setColWidth(packTable, sourceCol, Theme.itemNameColWidth);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 1);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setRowHeight(packTable, firstRow, 75);
      gbtu.setWordWrappedCol(packTable, firstCol, true);
      gbtu.lockPackTable(packTable, true, true);

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          frameworkComponents={frameworkComponents}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"contRefTable1"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={{
            ...packTable,
            GBRowHeights: [75, 40, 40, 40, 40, 40],
          }}
          types={generateTypes(packTable, ["s", "n", "n", "n", "n", "cm"])}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "renderTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIContRefTable1;
