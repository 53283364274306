import * as PropTypes from "prop-types";
import React, { Component } from "react";

import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as SC from "../../../data/strings/PIStringConst";
import { RS } from "../../../data/strings/global";
import TTabs2 from "../../common/TTabs2";

import * as Theme from "../../../app/Theme";

import * as gbu from "../../GB/GBUtil";

import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as piu from "../NonComponents/PIUtil";
import PIAGYWGeoPriorityTable from "../Tables/PIAGYWGeoPriorityTable";
import PIPrEPEfficacyAGYWTable from "../Tables/PIPrEPEfficacyAGYWTable";
//import PIAGYWCostTable from "../Tables/PIAGYWCostTable";
//import PIAGYWTable from "../Tables/PIAGYWTable";
import { onCalculate, onCalculateAGYWThresholdsTable } from "../NonComponents/PICalc";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import PIAGYWResultContent from "../ResultContent/PIAGYWResultContent";
import PIAGYWCostPerPYARTTable from "../Tables/PIAGYWCostPerPYARTTable";

import TButton from "../../common/TButton";
import TCheckBox from "../../common/TCheckBox";
import TEdit from "../../common/TEdit";
import TRadioGroup from "../../common/TRadioGroup";
//import TComboBox from "../../common/TComboBox";

import { cloneObj } from "../../GB/GBUtil";
import * as php from "../NonComponents/PIHelp";
import * as piv from "../NonComponents/PIValidate";
//import PICostsLiteResultContent from "../ResultContent/PICostsLiteResultContent";

import * as XLSX from "xlsx";
import { downloadAGYWTemplateXLSX } from "../../../api/server_calls";

const selectedTabIdxC = "selectedTabIdx";
const loadingAreasBoolC = "loadingAreasBool";

const parametersTab = 0;
const firstTab = parametersTab;
const inputsByAreaTab = 1;
const resultsTab = 2;
const finalTab = resultsTab;

class PIAGYWForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pip.countryISO3Str]: PropTypes.string,

    [pias.onDialogChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pip.countryISO3Str]: "",

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.helpAreaStr]: php.AGYW_FM_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),

    [pias.tableKeyObj]: {},
  };

  state = {
    [selectedTabIdxC]: parametersTab,
    [loadingAreasBoolC]: false,
  };

  componentDidMount() {
    this.props[pias.onHelpAreaChange](php.AGYW_ParamsTB_HP);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onThresholdCriterionRadioGroupChange = (value) => {
    const props = this.props;
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    const threshCritMstID = piu.getThreshCritMstID(value + 1);

    piasu.setModVarValue(modVarObjArrClone, pisc.thresholdCriterionMVTag, threshCritMstID);

    onModVarsChange(modVarObjArrClone, false);
  };

  onCostPerIAThresholdEditChange = (value) => {
    const props = this.props;
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    let valueNum = NaN;

    /* Entering "-" will cause value to be "", and Number("") is 0. */
    if (value !== "") {
      valueNum = Number(value);
    }

    if (!(isNaN(valueNum) || typeof valueNum !== "number" || valueNum < 0)) {
      piasu.setModVarValue(modVarObjArrClone, pisc.costPerIAMVTag, valueNum);

      onModVarsChange(modVarObjArrClone, false);
    }
  };

  onVaryPrEPCostByAreaCheckBoxClick = (checkBoxStateBool) => {
    const props = this.props;
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    piasu.setModVarValue(modVarObjArrClone, pisc.varyCostByAreaMVTag, checkBoxStateBool ? pisc.yesMstID : pisc.noMstID);

    onModVarsChange(modVarObjArrClone, false);
  };

  onVaryContCurvesByAreaCheckBoxClick = (checkBoxStateBool) => {
    const props = this.props;
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    piasu.setModVarValue(modVarObjArrClone, pisc.varyCurvesAreaMVTag, checkBoxStateBool ? pisc.yesMstID : pisc.noMstID);

    onModVarsChange(modVarObjArrClone, false);
  };

  onCostPerPersonYrPrEPEditChange = (value) => {
    const props = this.props;
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    let valueNum = NaN;

    /* Entering "-" will cause value to be "", and Number("") is 0. */
    if (value !== "") {
      valueNum = Number(value);
    }

    if (!(isNaN(valueNum) || typeof valueNum !== "number" || valueNum < 0)) {
      piasu.setModVarValue(modVarObjArrClone, pisc.costPerPersonMVTag, valueNum);

      onModVarsChange(modVarObjArrClone, false);
    }
  };

  onCostPerPYPrEPSrcRadioGroupChange = (value) => {
    const props = this.props;
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    const AGYWCostPerPYPrEPSrcMstIDStr = piu.getAGYWCostPerPYPrEPSrcMstID(value + 1);

    piasu.setModVarValue(modVarObjArrClone, pisc.AGYWCostPerPYPrEPSrcMVTag, AGYWCostPerPYPrEPSrcMstIDStr);

    onModVarsChange(modVarObjArrClone, false);
  };

  onCostPerPYARTEntryTypeRadioGroupChange = (value) => {
    const props = this.props;
    const modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    const AGYWCostPerPYARTEntryTypeMstIDStr = piu.getAGYWCostPerPYARTEntryTypeMstID(value + 1);

    piasu.setModVarValue(modVarObjArrClone, pisc.AGYWCostPerPYARTEntryTypeMVTag, AGYWCostPerPYARTEntryTypeMstIDStr);

    onModVarsChange(modVarObjArrClone, false);
  };

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];
    //const origModVarObjListClone = cloneObj(props[pias.origModVarObjArr]);

    piv.validateAGYWParameters(modVarObjListClone, "", true, (response) => {
      if (response) {
        if (tabIdx === resultsTab) {
          onHelpAreaChange(php.AGYW_ResTB_HP, () => {
            onModVarsChange(modVarObjListClone, false, () => {
              onCalculatingChange(true, () => {
                onCalculate(
                  modVarObjListClone,
                  "",
                  onDialogChange,
                  (response) => {
                    onCalculateAGYWThresholdsTable(
                      response,
                      "",
                      onDialogChange,
                      (response2) => {
                        onModVarsChange(response2, false, () => {
                          onCalculatingChange(false, () => {
                            this.setState({
                              [selectedTabIdxC]: tabIdx,
                            });
                          });
                        });
                      },
                      () => onCalculatingChange(false)
                    );
                  },
                  () => onCalculatingChange(false)
                );
              });
            });
          });
        } else {
          let helpAreaStr = "";

          switch (tabIdx) {
            case parametersTab:
              helpAreaStr = php.AGYW_ParamsTB_HP;
              break;

            case inputsByAreaTab:
              helpAreaStr = php.AGYW_InputsByAreaTB_HP;
              break;

            default:
              break;
          }

          onHelpAreaChange(helpAreaStr, () => {
            this.setState({
              [selectedTabIdxC]: tabIdx,
            });
          });
        }
      }
    });
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    const modVarObjArray = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];

    const state = this.state;
    let selectedTabIdx = state[selectedTabIdxC];

    let onPageChangeEvent = undefined;

    if (direction === pic.back) {
      if (selectedTabIdx === firstTab) {
        // let pageID;
        //
        // const costingModeMstID = piasu.getModVarValue(modVarObjArray, pisc.costingModuleMVTag);
        //
        // if (costingModeMstID !== pisc.noCostingModeMstID) {
        //
        //     pageID = piasu.getCostForm(modVarObjArray);
        //
        // }
        // else {
        //
        //     pageID = pic.configForm;
        //
        // }

        const backPageID = piasu.getPageID(modVarObjArray, pic.AGYW_FormOrder, pic.back);
        onPageChangeEvent = () => onPageChange(backPageID);
      } else {
        selectedTabIdx--;
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx === finalTab) {
        // let pageID;
        //
        // if (piasu.showImpact(modVarObjArray)) {
        //
        //     pageID = pic.impactForm;
        //
        // }
        // else if (piasu.showTargets(modVarObjArray)) {
        //
        //     pageID = pic.targetsForm;
        //
        // }
        // else if (piasu.showDisagTargets(modVarObjArray)) {
        //
        //     pageID = pic.disagTargetsForm;
        //
        // }
        // else if (piasu.showCommoditiesForecasting(modVarObjArray)) {
        //
        //     pageID = pic.commoditiesForecastForm;
        //
        // }

        const nextPageID = piasu.getPageID(modVarObjArray, pic.AGYW_FormOrder, pic.next);
        onPageChangeEvent = () => onPageChange(nextPageID);
      } else {
        selectedTabIdx++;
      }
    }

    piv.validateAGYWParameters(modVarObjArray, "", true, (response) => {
      if (response) {
        if (typeof onPageChangeEvent !== "undefined") {
          onPageChangeEvent();
        } else {
          this.onTabIdxChange(selectedTabIdx);
        }
      }
    });
  };

  onUploadAreaFileBtnClick = () => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const modVarObjArr = props[pias.modVarObjList];

      const levelNames1DStrArr = piasu.getModVarValue(modVarObjArr, pisc.adminSubnatLevelsDisagMVTag);
      const disagTargTemplateUploadedBool = piasu.getModVarValue(modVarObjArr, pisc.disagTargTemplateUploadedMVTag);

      const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);
      const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);

      if ((level2Name !== "" || level1Name !== "") && disagTargTemplateUploadedBool) {
        this.refs.importUploader.click();
      } else if (!disagTargTemplateUploadedBool) {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = RS(SC.GB_stUploadDataTemplateToUse);
        dialogObj[pias.headerStr] = RS(SC.GB_stError);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);

        //alert(RS(SC.GB_stUploadDataTemplateToUse));//SC.GB_stUploadAreaTemplate));
      } else {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = RS(SC.GB_stSpecifyASubnatLevel);
        dialogObj[pias.headerStr] = RS(SC.GB_stError);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);

        //alert(RS(SC.GB_stSpecifyASubnatLevel));
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  // markAGYW
  onLoadFileEnd = (fileReaderEvent) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjArrClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    if (fileReaderEvent.target.error === null) {
      //const countryISO3Str = props[pip.countryISO3Str];
      const onCalculatingChange = props[pias.onCalculatingChange];

      let areaObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.AGYWByAreaMVTag);
      const AGYWCostPerPYPrEPSrc = piasu.getModVarValue(modVarObjArrClone, pisc.AGYWCostPerPYPrEPSrcMVTag);
      const AGYWCostPerPYARTEntryType = piasu.getModVarValue(modVarObjArrClone, pisc.AGYWCostPerPYARTEntryTypeMVTag);
      const districtPop1DObjArr = piasu.getModVarValue(modVarObjArrClone, pisc.districtPopulationsMVTag);
      const levelNames1DStrArr = piasu.getModVarValue(modVarObjArrClone, pisc.adminSubnatLevelsDisagMVTag);

      const fileAsBinaryString = fileReaderEvent.target.result;
      const workbook = XLSX.read(fileAsBinaryString, { type: "binary" });
      const workSheetName = workbook.SheetNames[0];
      const workSheet = workbook.Sheets[workSheetName];
      const data2DStrArray = XLSX.utils.sheet_to_json(workSheet, { header: 1 });

      const firstDataRow = 7;

      let col = 0;

      const areaNameCol = col++;
      const catchmentNameCol = col++;
      const popAGYW15t24OptionalCol = col++; // population OR number to initiate
      const prev15t24PercCol = col++;
      const prev10t14PercOptionalCol = col++;

      let costPerPersonPrEPCol = -1;
      if (AGYWCostPerPYPrEPSrc !== pisc.AGYW_PerPYPrEPFromCostingSrcMstID) {
        costPerPersonPrEPCol = col++;
      }

      let costPerPYARTCol = -1;
      if (AGYWCostPerPYARTEntryType !== pisc.AGYW_SingleCostPerPerPYARTMstID) {
        costPerPYARTCol = col++;
      }

      col++;

      if (data2DStrArray.length >= firstDataRow && piasu.getNumAGYWAreas(areaObjArr) > 0) {
        const sampleAreaObj = gbu.cloneObj(areaObjArr[0]);
        areaObjArr = [];

        let row = firstDataRow;
        let stop = false;

        while (row < data2DStrArray.length && !stop) {
          if (typeof data2DStrArray[row][areaNameCol] === "undefined" || row === data2DStrArray.length - 1) {
            stop = true;
          } else {
            row++;
          }
        }

        while (true) {
          if (row > data2DStrArray.length - 1 || typeof data2DStrArray[row][areaNameCol] === "undefined") {
            break;
          }

          row++;
        }

        const lastDataRow = row - 1;

        areaObjArr.length = lastDataRow - firstDataRow + 1;

        /* Before changing anything, make sure that every level 1 (if level 2 was not specified) or level 2
                   (if level 2 was specified) name in the first column can be found somewhere in the
                   district populations list. */
        const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);
        const numDistrictsDP = piasu.getNumDistrictsDP(districtPop1DObjArr);

        let missingAreas1DStrArr = [];

        for (let row = firstDataRow; row <= lastDataRow; row++) {
          if (typeof data2DStrArray[row] !== "undefined" && typeof data2DStrArray[row][areaNameCol] !== "undefined") {
            let nameFromFile = data2DStrArray[row][areaNameCol].trim();

            let foundArea = false;
            let dp = 1;
            while (dp <= numDistrictsDP && !foundArea) {
              let districtPopName;

              if (level2Name !== "") {
                districtPopName = piasu.districtNameDP(districtPop1DObjArr, dp);
              } else {
                districtPopName = piasu.provinceNameDP(districtPop1DObjArr, dp);
              }

              foundArea = nameFromFile === districtPopName;

              dp++;
            }

            if (!foundArea) {
              missingAreas1DStrArr.push(nameFromFile);
            }
          }
        }

        if (missingAreas1DStrArr.length > 0) {
          missingAreas1DStrArr = gbu.RemoveDuplicates(missingAreas1DStrArr);
          const missingAreasStr = missingAreas1DStrArr.join(", ");

          let dialogObj = pias.getDefaultDialogObj();
          dialogObj[pias.contentStr] = (
            <p>
              {RS(SC.GB_stSubnatNamesDontMatchMsg)}
              <br></br>
              <br></br>
              {missingAreasStr}
            </p>
          );
          dialogObj[pias.headerStr] = RS(SC.GB_stError);
          dialogObj[pias.maxWidthStr] = "sm";
          dialogObj[pias.showBool] = true;
          dialogObj[pias.styleObj] = { width: 500 };

          onDialogChange(dialogObj);
        } else {
          /* The only purpose for setting this flag to true is so the table redraws after data is read in. */
          this.setState(
            {
              [loadingAreasBoolC]: true,
            },
            () => {
              let area = 0;

              for (let row = firstDataRow; row <= lastDataRow; row++) {
                areaObjArr[area] = cloneObj(sampleAreaObj);

                if (typeof data2DStrArray[row] !== "undefined") {
                  let areaName = "";
                  let catchmentName = "";
                  let popAGYW15t24Optional = 0;
                  let prev15t24Perc = 0;
                  let prev10t14PercOptional = 0;
                  let numInitOptional = 0;
                  let costPerPersonPrEP = 0;
                  let costPerPYART = 0;

                  if (typeof data2DStrArray[row][areaNameCol] !== "undefined") {
                    areaName = data2DStrArray[row][areaNameCol];
                    piasu.areaNameAGYW(areaObjArr, area + 1, areaName.trim());
                  }

                  if (typeof data2DStrArray[row][catchmentNameCol] !== "undefined") {
                    catchmentName = data2DStrArray[row][catchmentNameCol];
                    piasu.catchmentAreaNameAGYW(areaObjArr, area + 1, catchmentName.trim());
                  }

                  if (typeof data2DStrArray[row][popAGYW15t24OptionalCol] !== "undefined") {
                    if (piasu.showTargets(modVarObjArrClone)) {
                      popAGYW15t24Optional = parseFloat(data2DStrArray[row][popAGYW15t24OptionalCol]);
                      piasu.pop15t24AGYW(areaObjArr, area + 1, popAGYW15t24Optional);
                    } else {
                      numInitOptional = parseFloat(data2DStrArray[row][popAGYW15t24OptionalCol]);
                      piasu.numInitAGYW(areaObjArr, area + 1, numInitOptional);
                    }
                  } else {
                    if (!piasu.showTargets(modVarObjArrClone)) {
                      /* Avoid divide by zero error for PrEP cost per infection averted. */
                      piasu.numInitAGYW(areaObjArr, area + 1, 1);
                    }
                  }

                  if (typeof data2DStrArray[row][prev15t24PercCol] !== "undefined") {
                    prev15t24Perc = parseFloat(data2DStrArray[row][prev15t24PercCol]);
                    piasu.prev15t24CostAGYW(areaObjArr, area + 1, prev15t24Perc);
                  }

                  if (typeof data2DStrArray[row][prev10t14PercOptionalCol] !== "undefined") {
                    prev10t14PercOptional = parseFloat(data2DStrArray[row][prev10t14PercOptionalCol]);
                    piasu.prev10t14AGYW(areaObjArr, area + 1, prev10t14PercOptional * 100);
                  }

                  if (costPerPersonPrEPCol !== -1 && typeof data2DStrArray[row][costPerPersonPrEPCol] !== "undefined") {
                    costPerPersonPrEP = parseFloat(data2DStrArray[row][costPerPersonPrEPCol]);
                    piasu.costPerPersonYrPrEPAGYW(areaObjArr, area + 1, costPerPersonPrEP);
                  }

                  if (costPerPYARTCol !== -1 && typeof data2DStrArray[row][costPerPYARTCol] !== "undefined") {
                    costPerPYART = parseFloat(data2DStrArray[row][costPerPYARTCol]);
                    piasu.costPerPersonYrARTAGYW(areaObjArr, area + 1, costPerPYART);
                  }
                }

                area++;
              }

              piasu.setModVarValue(modVarObjArrClone, pisc.AGYWByAreaMVTag, areaObjArr);
              onModVarsChange(modVarObjArrClone, false, () => {
                this.setState(
                  {
                    [loadingAreasBoolC]: false,
                  },
                  () => {
                    onCalculatingChange(true, () => {
                      onCalculate(
                        modVarObjArrClone,
                        "",
                        onDialogChange,
                        (response) => {
                          onModVarsChange(response, false, () => {
                            onCalculatingChange(false, () => {
                              let dialogObj = pias.getDefaultDialogObj();
                              dialogObj[pias.contentStr] = RS(SC.GB_stTemplateSuccessUploaded);
                              dialogObj[pias.headerStr] = RS(SC.GB_stNote);
                              dialogObj[pias.maxWidthStr] = "sm";
                              dialogObj[pias.showBool] = true;
                              dialogObj[pias.styleObj] = { width: 500 };

                              onDialogChange(dialogObj);

                              //alert(RS(SC.GB_stTemplateSuccessUploaded));
                            });
                          });
                        },
                        () => onCalculatingChange(false)
                      );
                    });
                  }
                );
              });

              piasu.setModVarValue(modVarObjArrClone, pisc.AGYWTemplateUploadedMVTag, true);
            }
          );
        }
      } else {
        let dialogObj = pias.getDefaultDialogObj();
        dialogObj[pias.contentStr] = RS(SC.GB_stPleaseFillOutTemplate);
        dialogObj[pias.headerStr] = RS(SC.GB_stNote);
        dialogObj[pias.maxWidthStr] = "sm";
        dialogObj[pias.showBool] = true;
        dialogObj[pias.styleObj] = { width: 500 };

        onDialogChange(dialogObj);
      }
    } else {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_steFileReadError);
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);
    }
  };

  onReadFile = (event) => {
    try {
      let file = event.target.files[0];

      if (file) {
        let fileReader = new FileReader();
        fileReader.onloadend = (fileReaderEvent) => this.onLoadFileEnd(fileReaderEvent);
        // markAGYW csv fileReader.readAsText(file);
        fileReader.readAsBinaryString(file);
      }
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onResetEventTargetValue = (event) => {
    event.target.value = null;
  };

  onAreaTypeComboBoxChange = (value, text, info) => {
    const props = this.props;
    const modVarObjArrClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    const areaTypeMstIDStr = piu.getAGYWAreaTypeMstID(value + 1);

    piasu.setModVarValue(modVarObjArrClone, pisc.AGYWSelectedAreaTypeMVTag, areaTypeMstIDStr);
    onModVarsChange(modVarObjArrClone);
  };

  onGenerateAGYWTemplate = () => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const modVarObjArr = props[pias.modVarObjList];

    const levelNames1DStrArr = piasu.getModVarValue(modVarObjArr, pisc.adminSubnatLevelsDisagMVTag);
    const disagTargTemplateUploadedBool = piasu.getModVarValue(modVarObjArr, pisc.disagTargTemplateUploadedMVTag);

    const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);
    const level2Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 2);

    if ((level2Name !== "" || level1Name !== "") && disagTargTemplateUploadedBool) {
      downloadAGYWTemplateXLSX(modVarObjArr);
    } else if (!disagTargTemplateUploadedBool) {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_stUploadDataTemplateToUse);
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert(RS(SC.GB_stUploadAreaTemplate));
    } else {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = RS(SC.GB_stSpecifyASubnatLevel);
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert(RS(SC.GB_stSpecifyASubnatLevel));
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderThresholdCriterionRadioGroup = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    const threshCritMstID = piasu.getModVarValue(modVarObjList, pisc.thresholdCriterionMVTag);
    const threshCritCurrID = piu.getThreshCritCurrID(threshCritMstID);

    const radioGroup = (
      <TRadioGroup
        caption={RS(SC.GB_stChooseThresholdCrit)}
        captionStyle={{
          ...Theme.labelStyle,
        }}
        handleChange={this.onThresholdCriterionRadioGroupChange}
        itemIndex={threshCritCurrID - 1}
        items={[RS(SC.GB_stTotalCostSavings), RS(SC.GB_stPrEPCostInfectAvtd)]}
        row={true}
        style={{
          margin: 0,
          padding: 0,
        }}
      />
    );

    return radioGroup;
  };

  renderParametersTabContent = () => {
    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];
    //const onPageChange = props[pias.onPageChange];
    const tableKeyObj = props[pias.tableKeyObj];

    const tableKey = tableKeyObj[pias.PrEPEfficacyAGYWTableKey];

    const threshCritMstID = piasu.getModVarValue(modVarObjList, pisc.thresholdCriterionMVTag);

    const costingMode = piasu.getModVarValue(modVarObjList, pisc.costingModuleMVTag);
    const usingCostsLiteBool = piu.usingCostsLite(costingMode);

    const varyCostsByAreaDetMstID = piasu.getModVarValue(modVarObjList, pisc.varyCostByAreaMVTag);
    const varyCostsByAreaBool = varyCostsByAreaDetMstID === pisc.yesMstID ? true : false;

    const PrEPEfficacyAGYWTable = (
      <PIPrEPEfficacyAGYWTable
        {...{
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,

          [pip.tableKey]: tableKey,
        }}
      />
    );

    const thresholdCriterionRadioGroup = this.renderThresholdCriterionRadioGroup();

    let costPerIAThresholdEdit = null;
    if (threshCritMstID === pisc.PrEPCostInfAvtdAGYWMstID) {
      const costPerIAThresh = piasu.getModVarValue(modVarObjList, pisc.costPerIAMVTag); // this.state.costPerIAThreshold;

      costPerIAThresholdEdit = (
        <TEdit
          caption={RS(SC.GB_stCostPerIAThreshold)}
          onChange={this.onCostPerIAThresholdEditChange}
          //error      = {costPerIAThresh < 0 ? true : false}
          //errorText  = {"oops"}
          inputProps={{
            endAdornment: <InputAdornment position={"end"}>{RS(SC.GB_stUSD)}</InputAdornment>,
          }}
          style={{
            // marginBottom : 10,
            // marginLeft   : Theme.leftIndent,
            // marginTop    : Theme.topIndent,
            margin: 0,
            marginLeft: 5,
            padding: 0,
            width: 150,
          }}
          type={"number"}
          value={costPerIAThresh}
        />
      );
    }

    const costPerIAThresholdDiv = (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          marginTop: Theme.topIndent,
        }}
      >
        {thresholdCriterionRadioGroup}
        {costPerIAThresholdEdit}
      </div>
    );

    const varyPrEPCostByAreaCheckBox = (
      <TCheckBox
        caption={RS(SC.GB_stVaryPrEPCostDetCosts)}
        onClick={this.onVaryPrEPCostByAreaCheckBoxClick}
        style={{
          //    display : "inline-block",
          display: "block",
        }}
        value={varyCostsByAreaBool}
      />
    );

    let costPerPersonYrPrEPEdit = null;
    if (!varyCostsByAreaBool) {
      const costsByAreaDetCostsInt = piasu.getModVarValue(modVarObjList, pisc.costPerPersonMVTag);

      costPerPersonYrPrEPEdit = (
        <TEdit
          caption={RS(SC.GB_stCostPerPersonYrPrEP)}
          onChange={this.onCostPerPersonYrPrEPEditChange}
          inputProps={{
            endAdornment: <InputAdornment position={"end"}>{RS(SC.GB_stUSD)}</InputAdornment>,
          }}
          style={{
            // marginBottom : 10,
            // marginLeft   : Theme.leftIndent,
            // marginTop    : Theme.topIndent,
            margin: 0,
            marginLeft: 90, // line up with other TEdit
            padding: 0,
            width: 150,
          }}
          type={"number"}
          value={costsByAreaDetCostsInt}
        />
      );
    }

    let costPerPersonYrPrEPDiv = null;

    if (!usingCostsLiteBool) {
      costPerPersonYrPrEPDiv = (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            height: 50, // when checkbox is unchecked, keeps div height the same
          }}
        >
          {varyPrEPCostByAreaCheckBox}
          {costPerPersonYrPrEPEdit}
        </div>
      );
    }

    //const varyContCurvesByAreaBool =
    //piasu.getModVarValue(modVarObjList, pisc.varyCurvesAreaMVTag);

    // const varyContCurvesByAreaCheckBox =
    //     <TCheckBox
    //         caption = {RS(SC.GB_stVaryContCurvesByArea)}
    //         onClick = {this.onVaryContCurvesByAreaCheckBoxClick}
    //         style   = {{
    //             martinBottom : 20,
    //             marginTop    : 10,
    //         }}
    //         value   = {(varyContCurvesByAreaBool === pisc.yesMstID) ? true : false}
    //     />;

    const AGYWTab1NoteLab = (
      <p
        key={"AGYWTab1NoteLab"}
        style={{
          marginBottom: 20,
          marginLeft: 0,
          ...Theme.textFontStyle,
        }}
      >
        {RS(SC.GB_stAGYWTab1Note)}
      </p>
    );

    return (
      <React.Fragment>
        {PrEPEfficacyAGYWTable}
        {costPerIAThresholdDiv}
        {costPerPersonYrPrEPDiv}
        {/*{varyContCurvesByAreaCheckBox}*/}
        {AGYWTab1NoteLab}
      </React.Fragment>
    );
  };

  renderInputsByAreaTabContent = () => {
    const props = this.props;
    const countryISO3Str = props[pip.countryISO3Str];
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];
    const tableKeyObj = props[pias.tableKeyObj];

    //const areaTypeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.AGYWSelectedAreaTypeMVTag);
    const costPerPYPrEPSrcMstIDStr = piasu.getModVarValue(modVarObjList, pisc.AGYWCostPerPYPrEPSrcMVTag);
    const costPerPYARTEntryTypeMstIDStr = piasu.getModVarValue(modVarObjList, pisc.AGYWCostPerPYARTEntryTypeMVTag);
    const costingModeMstID = piasu.getModVarValue(modVarObjList, pisc.costingModuleMVTag);
    const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
    const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);

    const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);
    //const areaTypeCurrIDInt = piu.getAGYWAreaTypeCurrID(areaTypeMstIDStr);
    const costPerPYPrEPSrcCurrIDInt = piu.getAGYWCostPerPYPrEPSrcCurrID(costPerPYPrEPSrcMstIDStr);
    const costPerPYARTEntryTypeCurrIDInt = piu.getAGYWCostPerPYARTEntryTypeCurrID(costPerPYARTEntryTypeMstIDStr);

    let costPerPYPrEPSrcRadioGroup = null;

    if (costingModeMstID !== pisc.noCostingModeMstID) {
      /* If pill is active, use that. Otherwise, use the first method. */
      let methodCurrID = 1;
      if (piasu.getDefMethodActive(methodObjArr, pisc.pillMethodMstID)) {
        methodCurrID = piasu.getMethodCurrID(methodObjArr, pisc.pillMethodMstID);
      }

      const methodName = piasu.methodName(methodObjArr, methodCurrID);

      let option1Str = RS(SC.GB_stUseCostPerPYFromMode).replace(pic.modeStr, piu.getCostingModeStr(costingModeMstID));
      option1Str = option1Str.replace(pic.methodStr, methodName);

      costPerPYPrEPSrcRadioGroup = (
        <TRadioGroup
          caption={RS(SC.GB_stChooseCostPerPYPrEP)}
          captionStyle={{
            ...Theme.labelStyle,
          }}
          handleChange={this.onCostPerPYPrEPSrcRadioGroupChange}
          itemIndex={costPerPYPrEPSrcCurrIDInt - 1}
          items={[option1Str, RS(SC.GB_stEnteredByAreaBelow)]}
          row={true}
          style={{
            display: "block",
            margin: 0,
            marginBottom: 20,
            padding: 0,
          }}
        />
      );
    }

    const costPerPYARTEntryTypeRadioGroup = (
      <TRadioGroup
        caption={RS(SC.GB_stChooseWayCostPerPYART)}
        captionStyle={{
          ...Theme.labelStyle,
        }}
        handleChange={this.onCostPerPYARTEntryTypeRadioGroupChange}
        itemIndex={costPerPYARTEntryTypeCurrIDInt - 1}
        items={[RS(SC.GB_stEnterCostPerPYARTByAreaBelow), RS(SC.GB_stEnterByAreaBelow)]}
        row={true}
        style={{
          display: "block",
          margin: 0,
          marginBottom: 20,
          padding: 0,
        }}
      />
    );

    let AGYWCostPerPYARTTable = null;

    if (costPerPYARTEntryTypeMstIDStr === pisc.AGYW_SingleCostPerPerPYARTMstID) {
      AGYWCostPerPYARTTable = (
        <PIAGYWCostPerPYARTTable
          {...{
            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            // [pip.tableKey]         : AGYWGeoPriorityTableKey,
          }}
        />
      );
    }

    const inputDialog = (
      <input
        type={"file"}
        //markAGYW
        accept={".xlsx"}
        //accept   = {".csv"}
        ref={"importUploader"}
        style={{
          display: "none",
        }}
        onChange={this.onReadFile}
        onClick={(event) => this.onResetEventTargetValue(event)}
      />
    );

    const genAreaTemplateBtn = (
      <TButton
        caption={RS(SC.GB_stGenAreaTemplate)}
        key={"genAreaTemplateBtn"}
        // markAGYW
        //onClick = {() => gbu.resourceClick(pic.AGYWDataTemplateXLSX)}
        //onClick = {() => gbu.resourceClick(pic.areaTemplateCSV)}
        onClick={this.onGenerateAGYWTemplate}
        style={{
          backgroundColor: Theme.PI_TertiaryColor,
          width: 200,
        }}
      />
    );

    const uploadAreaFileBtn = (
      <TButton
        caption={RS(SC.GB_stUploadAreaTemplateBtnText)}
        onClick={this.onUploadAreaFileBtnClick}
        style={{
          backgroundColor: Theme.PI_TertiaryColor,
          marginLeft: Theme.leftIndent,
          width: 200,
        }}
      />
    );

    // markAGYW
    // const areaTypeLab =
    //     <p
    //         style = {{
    //             ...Theme.labelStyle,
    //             marginBottom : 10,
    //             marginTop    : 0,
    //         }}
    //     >
    //         {RS(SC.GB_stChooseAnAreaType)}
    //     </p>;
    //
    // const areaTypeComboBox =
    //     <TComboBox
    //         onChange   = {this.onAreaTypeComboBoxChange}
    //         info       = {[
    //             pic.nationalMstID,
    //             pic.regionalMstID,
    //             pic.districtMstID,
    //         ]}
    //         itemIndex  = {areaTypeCurrIDInt - 1}
    //         items      = {[
    //             RS(SC.GB_stNational),
    //             RS(SC.GB_stRegional),
    //             RS(SC.GB_stDistrict),
    //         ]}
    //         outerStyle = {{
    //             display      : "block",
    //             fontFamily   : Theme.fontFamily,
    //             marginBottom : 20,
    //             marginTop    : 0,
    //         }}
    //         style     = {{
    //             fontFamily   : Theme.fontFamily,
    //         }}
    //     />;

    const AGYWGeoPriorityTableKey = tableKeyObj[pias.AGYWGeoPriorityTableKey];

    let specLevel1Lab = null;

    // markAGYW
    if (level1Name === "") {
      specLevel1Lab = (
        <p
          style={{
            display: "block",
            marginTop: 20,
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stSpecifyASubnatLevelToViewTable)}
        </p>
      );
    }

    const PIAGYWGeoPriorityTableComp = (
      <PIAGYWGeoPriorityTable
        {...{
          [pip.countryISO3Str]: countryISO3Str,

          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,

          [pip.tableKey]: AGYWGeoPriorityTableKey,
        }}
      />
    );

    // markAGYW
    return (
      <React.Fragment>
        {costPerPYPrEPSrcRadioGroup}
        {costPerPYARTEntryTypeRadioGroup}
        {AGYWCostPerPYARTTable}
        {/*{areaTypeLab}*/}
        {/*{areaTypeComboBox}*/}
        {inputDialog}
        {genAreaTemplateBtn}
        {uploadAreaFileBtn}
        {specLevel1Lab}
        {PIAGYWGeoPriorityTableComp}
      </React.Fragment>
    );
  };

  renderResultsTabContent = () => {
    const props = this.props;
    const countryISO3Str = props[pip.countryISO3Str];
    const modVarObjList = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];

    const resultContent = (
      <PIAGYWResultContent
        {...{
          [pip.countryISO3Str]: countryISO3Str,
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: onModVarsChange,
        }}
      />
    );

    return <React.Fragment>{resultContent}</React.Fragment>;
  };

  render() {
    try {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      //const onModVarsChange = props[pias.onModVarsChange];
      //const onPageChange = props[pias.onPageChange];

      const state = this.state;
      const selectedTabIdx = state[selectedTabIdxC];

      let formHeightStyle = {};

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stAdolGirlsYoungWomen)}
        </p>
      );

      const tabs = (
        <TTabs2
          onChange={this.onTabIdxChange}
          selectedTabIdx={selectedTabIdx}
          style={{
            marginTop: 10,
          }}
          tabBackgroundColor={"inherit"}
          tabBarOutline={"none"}
          tabContents={["", "", ""]}
          tabTitles={[RS(SC.GB_stParameters), RS(SC.GB_stInputsByArea), RS(SC.GB_stResults)]}
        />
      );

      let parametersTabContent = null;
      let inputsByAreaTabContent = null;
      let resultsAreaTabContent = null;

      if (selectedTabIdx === parametersTab) {
        parametersTabContent = this.renderParametersTabContent();
      } else if (selectedTabIdx === inputsByAreaTab) {
        formHeightStyle = {
          height: 1050,
        };

        inputsByAreaTabContent = this.renderInputsByAreaTabContent();
      } else if (selectedTabIdx === resultsTab) {
        resultsAreaTabContent = this.renderResultsTabContent();
      }

      // const showNextBtn =
      //       piasu.showImpact(modVarObjList) ||
      //       piasu.showTargets(modVarObjList) ||
      //       piasu.showDisagTargets(modVarObjList) ||
      //       piasu.showCommoditiesForecasting(modVarObjList);

      const backPageID = piasu.getPageID(modVarObjList, pic.AGYW_FormOrder, pic.back);
      const nextPageID = piasu.getPageID(modVarObjList, pic.AGYW_FormOrder, pic.next);

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.showBackBtn]: backPageID !== pic.noPageID,
            [PINavBtnDivProps.showNextBtn]: nextPageID !== pic.noPageID,
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );

      /* formHeightStyle: Some tables unmount and remount (such as those implementing comboboxes and
               those that change font color). This changes the height of the table, causing the
               AppPage scrollbar to change position. To prevent this, if the table is shown on the screen,
               set the height of the form large enough to cover the entire vertical span of all
               controls on the form. */
      return (
        <div
          style={{
            ...formHeightStyle,
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          {areaLab}

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {tabs}

          {parametersTabContent}
          {inputsByAreaTabContent}
          {resultsAreaTabContent}

          {navBtnDiv}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIAGYWForm;
