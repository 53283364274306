import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const firstRow = 0;

const fileNameCol = 0;
const totalCol = 1;
const numNonPriorPopCols = totalCol + 1;
const priorPopStartCol = 2;

class PITargDisagDistrictPriorPopsAggResTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    selectedPriorityPopulations: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const priorPopObjArray = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);

      const PrEP_NEW_TargDisag2DFltArr = piasu.getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_NEW_MVTag, true);
      const PrEP_CT_TargDisag2DFltArr = piasu.getModVarValue(modVarObjList, pisc.targDisagDistPopPrEP_CT_MVTag, true);
      const PrEPStatus = piasu.getModVarValue(modVarObjList, pisc.selectedPrEPStatusMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const aggFileNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.aggFileNamesMVTag, true);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      const numFiles = PrEP_NEW_TargDisag2DFltArr.length;
      const numRows = numFiles + 1;

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArray);
      const numCols = numPriorPops + numNonPriorPopCols;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, 0, 0, RS(SC.GB_stFile));
      gbtu.setValue(packTable, firstRow, totalCol, RS(SC.GB_stTotal));

      let c = priorPopStartCol;
      for (let pp = 1; pp <= numPriorPops; pp++) {
        const priorPopName = piasu.getPriorPopName(priorPopObjArray, pp);
        gbtu.setValue(packTable, firstRow, c, priorPopName);

        c++;
      }

      for (let f = 1; f <= numFiles; f++) {
        /* Set row headings. */
        gbtu.setValue(packTable, f, 0, aggFileNames1DStrArr[f - 1]);

        /* Note: This is the only ModVar whose structure actually changes in
                   Aggregate mode. We drop the district completely. */
        let c = priorPopStartCol;

        let total = 0;

        for (let pp = 1; pp <= numPriorPops; pp++) {
          if (PrEPStatus === pisc.PrEP_NEW_StatusMstID) {
            const PrEP_NEW_Val = piasu.getPrEP_NEW_TargDisagAggMode(
              PrEP_NEW_TargDisag2DFltArr[f - 1],
              selectedMethodCurrID,
              pp
            );
            gbtu.setValue(packTable, f, c, PrEP_NEW_Val);
            total += PrEP_NEW_Val;
          } else {
            const PrEP_CT_Val = piasu.getPrEP_CT_TargDisagAggMode(
              PrEP_CT_TargDisag2DFltArr[f - 1],
              selectedMethodCurrID,
              pp
            );
            gbtu.setValue(packTable, f, c, PrEP_CT_Val);
            total += PrEP_CT_Val;
          }

          c++;
        }

        gbtu.setValue(packTable, f, totalCol, total);
      }

      gbtu.lockPackTable(packTable, true, false);
      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      gbtu.setColWidths(packTable, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, fileNameCol, Theme.itemNameColWidth);
      gbtu.setRowHeight(packTable, firstRow, 50);

      if (window.DebugMode) {
        console.log("Component: PITargDisagDistrictPriorPopsAggResTable");
        console.log("ModVar(s):");
        console.log(pisc.targDisagDistPopPrEP_NEW_MVTag);
        console.log(PrEP_NEW_TargDisag2DFltArr);
        console.log(pisc.targDisagDistPopPrEP_CT_MVTag);
        console.log(PrEP_CT_TargDisag2DFltArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PITargDisagDistrictPriorPopsAggResTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PITargDisagDistrictPriorPopsAggResTable;
