import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import SuperTableShim from "../../common/SuperTableShim";

export const PICapacityAnnualPrEPTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

const catCol = 0;
const valCol = 1;

const firstRow = 0;

const numRows = 7;
const numCols = 2;

class PICapacityAnnualPrEPTable extends Component {
  static propTypes = {
    [PICapacityAnnualPrEPTableProps.allowEditsBoolC]: PropTypes.bool,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [PICapacityAnnualPrEPTableProps.allowEditsBoolC]: true,

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTableChange = (newPackTable) => {
    // try {
    //     const props = this.props;
    //     let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
    //     const onModVarsChange = props[pias.onModVarsChange];
    //
    //     const newPackTableClone = gbu.cloneObj(newPackTable);
    //
    //     let salary1DFltArray = piasu.getModVarValue(modVarObjListClone, pisc.salariesMVTag);
    //
    //     for (let ec = 1; ec <= pic.numEduCodes; ec++) {
    //
    //         const salary = gbtu.getValue(newPackTableClone, ec, 1);
    //         piasu.setSalary(salary1DFltArray, ec, salary);
    //
    //     }
    //
    //     piasu.setModVarValue(modVarObjListClone, pisc.salariesMVTag, salary1DFltArray);
    //
    //     onModVarsChange(modVarObjListClone, () => {
    //
    //         /* Put this here because after the editor values change, the user needs to see
    //            the graph under it update. */
    //         // onCalculate(modVarObjListClone, "", (response) => {
    //         //
    //         //     onModVarsChange(response);
    //         //
    //         // });
    //
    //     });
    //
    // }
    // catch (exception) {
    //
    //     alert(exception.name + ': ' + exception.message);
    //
    // }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      let lies = 317;

      let capacity = piasu.getModVarValue(modVarObjList, pisc.annualPrEPCapacityMVTag);

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, firstRow + 1, catCol, RS(SC.GB_stNumAGYWBelowThreshold));
      gbtu.setValue(packTable, firstRow + 2, catCol, RS(SC.GB_stPrevAmongAGYW));
      gbtu.setValue(packTable, firstRow + 3, catCol, RS(SC.GB_stTotalPrEPBelowThreshold));
      gbtu.setValue(packTable, firstRow + 4, catCol, RS(SC.GB_stTotalSavingsBelowThreshold));
      gbtu.setValue(packTable, firstRow + 5, catCol, RS(SC.GB_stTotalSavingsAllAreas));
      gbtu.setValue(packTable, firstRow + 6, catCol, RS(SC.GB_stAddSavingsAchTargeting));

      gbtu.setValue(packTable, firstRow + 1, valCol, lies);
      gbtu.setValue(packTable, firstRow + 2, valCol, lies);
      gbtu.setValue(packTable, firstRow + 3, valCol, lies);
      gbtu.setValue(packTable, firstRow + 4, valCol, lies);
      gbtu.setValue(packTable, firstRow + 5, valCol, lies);
      gbtu.setValue(packTable, firstRow + 6, valCol, lies);

      // gbtu.setValue(packTable, firstRow + 6, extraCol, lies);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      gbtu.setRDecByRow(packTable, 2, 1);
      gbtu.setColWidth(packTable, catCol, 350);
      gbtu.setColWidth(packTable, valCol, Theme.dataColWidthSmall);
      gbtu.setWordWrappedCol(packTable, valCol, true);

      gbtu.setMinAllowedValByCol(packTable, valCol, 0);
      gbtu.setMaxAllowedValByCol(packTable, valCol, gbtc.maxInt);
      gbtu.setID(packTable, "CapacityAnnualPrEPPackTable");
      gbtu.setKey(packTable, "CapacityAnnualPrEPPackTable");

      if (window.DebugMode) {
        console.log("Component: PICapacityAnnualPrEPTable");
        console.log("ModVar(s):");
        console.log(pisc.annualPrEPCapacityMVTag);
        console.log(capacity);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"CapacityAnnualPrEPTable"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          onPackTableChanged={this.onTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: 20,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PICapacityAnnualPrEPTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PICapacityAnnualPrEPTable;
