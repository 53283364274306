import * as React from "react";
import * as PropTypes from "prop-types";

import TAppContent from "../../APP/TAppContent";

import * as pic from "../NonComponents/PIConst";
import * as pip from "../NonComponents/PIProps";

class PIAppSideBarAndContent extends React.Component {
  static propTypes = {
    [pip.appBarHeight]: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    [pip.appSideBarWidth]: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    [pip.children]: PropTypes.any,
  };

  static defaultProps = {
    [pip.appBarHeight]: pic.defaultAppBarHeight,
    [pip.appSideBarWidth]: 0,
    [pip.children]: null,
  };

  render() {
    const props = this.props;
    const appBarHeight = props[pip.appBarHeight];
    //const appSideBarWidth = props[pip.appSideBarWidth];
    const children = props[pip.children];

    return (
      <TAppContent
        //mr3 appBarHeight = {appBarHeight}
        //mr3 sideBarWidth = {appSideBarWidth}
        // mr3
        style={{
          top: appBarHeight,
        }}
      >
        {children}
      </TAppContent>
    );
  }
}

export default PIAppSideBarAndContent;
