import * as React from "react";
import PropTypes from "prop-types";
import TDialog from "./TDialog";
import TWifiAnimation from "./TWifiAnimation";

class TAPIDialog extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    apiTasks: PropTypes.array,
  };

  static defaultProps = {
    apiTasks: [],
  };

  state = {
    title: "Communicating with server...",
    show: true,
    runningTasks: [],
  };

  shouldComponentUpdate(nextProps, nextState) {
    // if (JSON.stringify(this.props.apiTasks) !== JSON.stringify(nextProps.apiTasks)) {

    //     let i;
    //     let task = null;
    //     let tasks = nextProps.apiTasks;
    //     let len = tasks.length;

    //     for (i = 0; i < len; i++) {
    //         task = tasks[i];

    //         if (task.running) {
    //             if (task.flag !== 'getStatus') {
    //                 return true;
    //             }
    //         }
    //     }

    //     return false;
    // }
    // else {
    //     return false;
    // }
    return true;
  }

  static getDerivedStateFromProps(props, state) {
    let i;
    let task = null;
    let tasks = props.apiTasks;
    let len = tasks.length;

    let show = false;
    let runningTasks = [];

    for (i = 0; i < len; i++) {
      task = tasks[i];

      if (task.running) {
        if (task.flag !== "getStatus") {
          show = true;
          runningTasks.push(task.flag);
        }
      }
    }

    return {
      show: show,
      runningTasks: runningTasks,
    };
  }

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    let styles = {
      dialog: {
        width: "370px",
      },
      icon: {
        marginRight: "20px",
        float: "left",
      },
    };

    let show = this.state.show;
    let message = this.state.runningTasks.join("<br />");

    if (show) {
      return (
        <TDialog
          caption={this.state.title}
          icon={<TWifiAnimation style={styles.icon} />}
          message={"Processing..."}
          content={
            <div>
              {/* <div style={{marginTop: 10}}>Working on</div> */}
              {message}
            </div>
          }
          modal={true}
          visible={this.props.show}
          actions={[]}
          style={styles.dialog}
        />
      );
    } else {
      return null;
    }
  }
}

export default TAPIDialog;
