import React, { Component } from "react";

import * as Theme from "../../../../app/Theme";
import TButton from "../../../common/TButton";

import * as gbu from "../../../GB/GBUtil";
import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

class PIContinuationCalcForm extends Component {
  static propTypes = {};

  static defaultProps = {};

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  render() {
    const contCalcInfo = (
      <p
        key={"contCalcInfo"}
        style={{
          marginLeft: Theme.leftIndent,
        }}
      >
        {RS(SC.GB_stContCalcInfo)}
      </p>
    );

    const contCalcDownloadBtn = (
      <TButton
        caption={RS(SC.GB_stContCalcDownload)}
        containerStyle={{
          float: "right",
          marginRight: 10,
          marginTop: 4,
        }}
        key={"contCalcDownloadBtn"}
        onClick={() => gbu.resourceClick("PrEP-it_continuation_calculator.xlsm")}
        style={{
          backgroundColor: Theme.PI_PrimaryColor,
          padding: 0,
        }}
      />
    );

    return (
      <React.Fragment>
        {contCalcInfo}

        {contCalcDownloadBtn}
      </React.Fragment>
    );
  }
}

export default PIContinuationCalcForm;
