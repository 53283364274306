import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../app/Theme";

import * as gbu from "../../GB/GBUtil";
import * as piu from "../NonComponents/PIUtil";
import * as pip from "../NonComponents/PIProps";
import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";
import * as pic from "../NonComponents/PIConst";
import * as piasu from "../NonComponents/PIAppStateUtil";

/* Attributes specific to PITimePeriodLab. */

export const PITimePeriodLabProps = {
  style: "style",
};

class PITimePeriodLab extends Component {
  static propTypes = {
    [pip.spaceAfterColonStr]: PropTypes.string,

    [pip.timePeriodObj]: PropTypes.object,

    [pip.timePeriodType]: PropTypes.number,

    [PITimePeriodLabProps.style]: PropTypes.object,
  };

  static defaultProps = {
    [pip.spaceAfterColonStr]: "",

    [pip.timePeriodObj]: {},

    [pip.timePeriodType]: pic.targSetPeriod,

    [PITimePeriodLabProps.style]: {},
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  renderTimePeriodLab = () => {
    const fn = () => {
      const props = this.props;
      const spaceAfterColonStr = props[pip.spaceAfterColonStr];
      const timePeriodObj = props[pip.timePeriodObj];
      const timePeriodType = props[pip.timePeriodType];
      const labStyle = props[PITimePeriodLabProps.style];

      let periodStr;
      let startMonth;
      let startYear;
      let endMonth;
      let endYear;

      if (timePeriodType === pic.dateRangeDisplayPeriod) {
        periodStr = RS(SC.GB_stDateRangeForDisplay);
        startMonth = piasu.getDateRangeDisplayStartMonth(timePeriodObj);
        startYear = piasu.getDateRangeDisplayStartYear(timePeriodObj);
        endMonth = piasu.getDateRangeDisplayEndMonth(timePeriodObj);
        endYear = piasu.getDateRangeDisplayEndYear(timePeriodObj);
      } else if (timePeriodType === pic.progDataPeriod) {
        periodStr = RS(SC.GB_stProgDataPeriod);
        startMonth = piasu.getProgDataStartMonth(timePeriodObj);
        startYear = piasu.getProgDataStartYear(timePeriodObj);
        endMonth = piasu.getProgDataEndMonth(timePeriodObj);
        endYear = piasu.getProgDataEndYear(timePeriodObj);
      } else {
        periodStr = RS(SC.GB_stTargetSettingPeriod);
        startMonth = piasu.getTargStartMonth(timePeriodObj);
        startYear = piasu.getTargStartYear(timePeriodObj);
        endMonth = piasu.getTargEndMonth(timePeriodObj);
        endYear = piasu.getTargEndYear(timePeriodObj);
      }

      const startMonthStr = piu.getMonthName(startMonth);
      const endMonthStr = piu.getMonthName(endMonth);

      const timePeriodLab = (
        <p
          style={{
            display: "inline-block",
            marginBottom: 0,
            marginTop: 0,
            ...Theme.labelStyle,
            ...labStyle,
          }}
        >
          {periodStr + ": "}

          {spaceAfterColonStr}

          <span style={{ color: Theme.PI_TertiaryColor }}>
            {startMonthStr + " " + startYear + " - " + endMonthStr + " " + endYear}
          </span>
        </p>
      );

      return timePeriodLab;
    };

    return gbu.tryRenderFn(fn, "render PITimePeriodLab");
  };

  render() {
    return <React.Fragment>{this.renderTimePeriodLab()}</React.Fragment>;
  }
}

export default PITimePeriodLab;
