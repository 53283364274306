import * as gbu from "../../GB/GBUtil";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbtc from "../../GB/GBTableConst";

import * as Theme from "../../../app/Theme";
import * as pip from "./PIProps";

export const style2DObjArray = "style2DObjArray";
export const info3DIntArray = "info3DIntArray";
export const item3DStrArray = "item3DStrArray";
export const idx2DIntArray = "idx2DIntArray";
export const disabled2DBoolArray = "disabled2DBoolArray";
export const onChange2DFuncArray = "onChange2DFuncArray";

export function colorizeTable(packTable) {
  const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
  const inputIndicatorColorBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.PI_InputIndicatorColor));
  const whiteBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whiteColor));

  const numRows = gbtu.getNumRows(packTable);
  const numCols = gbtu.getNumCols(packTable);

  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      if (row >= 1 && col >= 1) {
        if (gbtu.getCellLocked(packTable, row, col)) {
          gbtu.setCellBGColor(packTable, row, col, gainsboroBase10);
        } else {
          gbtu.setCellBGColor(packTable, row, col, inputIndicatorColorBase10);
        }
      } else {
        gbtu.setCellBGColor(packTable, row, col, whiteBase10);
      }
    }
  }
}

export function getComboBoxObj(numRows, numCols) {
  let comboBoxStyle2DObjArray = [];
  let comboBoxInfo3DIntArray = [];
  let comboBoxItem3DStrArray = [];
  let comboBoxIdx2DIntArray = [];
  let comboBoxDisabled2DBoolArray = [];
  let comboBoxOnChange2DFuncArray = [];

  for (let r = 0; r < numRows; r++) {
    comboBoxStyle2DObjArray.push([]);
    comboBoxInfo3DIntArray.push([]);
    comboBoxItem3DStrArray.push([]);
    comboBoxDisabled2DBoolArray.push([]);
    comboBoxIdx2DIntArray.push([]);
    comboBoxOnChange2DFuncArray.push([]);

    for (let c = 0; c < numCols; c++) {
      comboBoxStyle2DObjArray[r].push({});
      comboBoxInfo3DIntArray[r].push([]);
      comboBoxItem3DStrArray[r].push([]);
      comboBoxDisabled2DBoolArray[r].push(false);
      comboBoxIdx2DIntArray[r].push(0);
      comboBoxOnChange2DFuncArray[r].push(() => {});
    }
  }

  return {
    [style2DObjArray]: comboBoxStyle2DObjArray,
    [info3DIntArray]: comboBoxInfo3DIntArray,
    [item3DStrArray]: comboBoxItem3DStrArray,
    [idx2DIntArray]: comboBoxIdx2DIntArray,
    [disabled2DBoolArray]: comboBoxDisabled2DBoolArray,
    [onChange2DFuncArray]: comboBoxOnChange2DFuncArray,
  };
}

export function onCellFocused(componentRef, focusedCell) {
  try {
    componentRef.setState({
      [pip.focusedCell]: focusedCell,
    });
  } catch (exception) {
    alert(exception.name + ": " + exception.message);
  }
}

export function onSelectionChanged(componentRef, selectedRegions) {
  try {
    componentRef.setState({
      [pip.selectedRegions]: selectedRegions,
    });
  } catch (exception) {
    alert(exception.name + ": " + exception.message);
  }
}

// Cut doesn't seem to work, Paste is always grayed out, Data source is unneeded, and multiply breaks because
// it's not connected to a dialog like LiST (although LiST's doesn't work)
export const tableHideMenuItems = [gbtc.cut_MI, gbtc.paste_MI, gbtc.dataSource_MI, gbtc.multiply_MI];
