import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
});

class TTabs2 extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    tabBackgroundColor: PropTypes.string,
    tabTitles: PropTypes.arrayOf(PropTypes.string),
    tabContents: PropTypes.arrayOf(PropTypes.any),
    classes: PropTypes.object.isRequired,
    selectedTabIdx: PropTypes.number,
    onChange: PropTypes.func,
    style: PropTypes.object,
  };

  static defaultProps = {
    tabBackgroundColor: "default",
    tabTitles: ["Tab 1", "Tab 2", "Tab 3"],
    tabContents: ["Content 1", "Content 2", "Content 3"],
    classes: {},
    selectedTabIdx: 0,
    onChange: () => console.log("onChange"),
    tabBarOutline: undefined,
    style: {},
  };

  // state = {
  //     value: 0,
  // };

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  /* Material UI:

       Callback fired when the value changes.

       Signature:

           function(event: object, value: any) => void

               event: The event source of the callback
               value: We default to the index of the child (number)*/

  onChange = (event, value) => {
    this.props.onChange(value);
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderTabTitles = () => {
    let arr = [];

    for (var i = 0; i < this.props.tabTitles.length; i++) {
      arr.push(<Tab style={{ textTransform: "none" }} label={this.props.tabTitles[i]} key={i} />);
    }

    return arr;
  };

  renderTabContent = () => {
    return this.props.tabContents[this.props.selectedTabIdx]; //this.state.value];
  };

  render() {
    const { classes, tabBarOutline } = this.props;
    //const { value } = this.state;

    let appBarStyle = {
      marginBottom: 20,
    };

    if (typeof tabBarOutline !== "undefined") {
      appBarStyle.boxShadow = tabBarOutline;
    }

    return (
      <div className={classes.root}>
        <AppBar position="static" color={this.props.tabBackgroundColor} style={{ ...appBarStyle, ...this.props.style }}>
          <Tabs
            value={this.props.selectedTabIdx} // value}
            onChange={this.onChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {this.renderTabTitles()}
          </Tabs>
        </AppBar>
        {this.renderTabContent()}
      </div>
    );
  }
}

export default withStyles(styles)(TTabs2);
