import * as React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

class TEdit extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    caption: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    rootStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    multiline: PropTypes.bool,
    numRows: PropTypes.number,
    rowsMax: PropTypes.number,
    inputProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    data: PropTypes.any,
  };

  static defaultProps = {
    caption: "Caption",
    name: "TEdit1",
    value: null,
    type: "",
    placeholder: "",
    onChange: (value) => console.log("onChange [" + value + "]"),
    style: {},
    rootStyle: {}, //{padding: 0, margin: 0, height: 40},
    inputStyle: {},
    error: false,
    errorText: "",
    multiline: false,
    numRows: 1,
    rowsMax: 1,
    inputProps: {},
    fullWidth: false,
    variant: "standard",
    data: null,
  };

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  onChange = (event) => {
    this.props.onChange(event.target.value, this.props.data);
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    const selectFieldStyle = {
      marginRight: 25,
      paddingLeft: 0,
      marginBottom: 30,
    };

    return (
      <TextField
        id={this.props.name}
        label={this.props.caption}
        value={this.props.value}
        onChange={this.onChange}
        type={this.props.type}
        style={{ ...selectFieldStyle, ...this.props.style }}
        error={this.props.error}
        helperText={this.props.errorText}
        multiline={this.props.multiline}
        minRows={this.props.numRows}
        maxRows={this.props.rowsMax}
        InputProps={this.props.inputProps}
        fullWidth={this.props.fullWidth}
        variant={this.props.variant}
      />
    );

    // const unstyledComponent = props => (
    //     <TextField
    //         id={this.props.name}
    //         label={this.props.caption}
    //         value={this.props.value}
    //         onChange={this.onChange}
    //         type={this.props.type}
    //         style={{...selectFieldStyle, ...this.props.style}}
    //         classes={{
    //             root : props.classes.root,
    //             // formControl : props.classes.formControl
    //         }}
    //     />
    // );

    // const StyledComponent = withStyles({
    //     root : this.props.rootStyle || {},
    //     // input : this.props.inputStyle || {},
    //     // formControl : {margin : 0, top: -15}
    // })(unstyledComponent);

    // return (
    //     <StyledComponent />
    // );
  }
}

export default TEdit;
