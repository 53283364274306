import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import SuperTableShim from "../../../common/SuperTableShim";

//markHardcode -- change to use proper modVars, structures

const numRows = 14;

class PIAvgUnitCostPerMonthPriorPopRefTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      //const costingModeMstID = piasu.getModVarValue(modVarObjList, pisc.costingModuleMVTag);
      const avgCostPrEPByMonthPP3DFltArr = piasu.getModVarValue(modVarObjList, pisc.avgCostPrEPByMonthPTMVTag);
      const methodObjList = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      //const usingCostsLite = piu.usingCostsLite(costingModeMstID);

      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjList, selectedMethodMstID);

      let packTable = gbtu.getNewPackTable();

      /* Number of service delivery strategies + edu code column + personnel type column. */
      const numCols = numPriorPops + 1;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Column headings */
      gbtu.setValue(packTable, 0, 0, RS(SC.GB_stMonthsOnPrEp));
      gbtu.setColWidth(packTable, 0, Theme.dataColWidthSmall);

      for (let m = 0; m <= 12; m++) {
        gbtu.setValue(packTable, m + 1, 0, m);
      }

      for (let pp = 1; pp <= numPriorPops; pp++) {
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, 0, pp, priorPopName);

        for (let m = 0; m <= 12; m++) {
          // if (usingCostsLite) {
          //
          //     gbtu.setValue(packTable, m + 1, pp, 8);
          //
          // }
          // else {

          const value = piasu.getAvgCostPrEPByMonthPP(avgCostPrEPByMonthPP3DFltArr, selectedMethodCurrID, pp, m);
          gbtu.setValue(packTable, m + 1, pp, value);

          // }
        }

        gbtu.setColWidth(packTable, pp, Theme.dataColWidthMed);
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 2);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setRowHeight(packTable, 0, 50);
      gbtu.setWordWrappedCol(packTable, 0, true);
      gbtu.lockPackTable(packTable, true, true);

      gbtu.setID(packTable, "packTable");
      gbtu.setKey(packTable, "packTable");

      if (window.DebugMode) {
        console.log("Component: PIAvgUnitCostPerMonthPriorPopRefTable");
        console.log("ModVar(s):");
        console.log(pisc.avgCostPrEPByMonthPTMVTag);
        console.log(avgCostPrEPByMonthPP3DFltArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"table"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "renderTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAvgUnitCostPerMonthPriorPopRefTable;
