import React, { Component } from "react";
import * as PropTypes from "prop-types";

import TButton from "../../common/TButton";

import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import * as pias from "../NonComponents/PIAppState";
import * as Theme from "../../../app/Theme";

class PIRedirectPage extends Component {
  static propTypes = {
    [pias.onShowRedirectChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onShowRedirectChange]: () => console.log(pias.onShowRedirectChange),
  };

  state = {};

  // myRef = React.createRef();
  //
  // componentDidMount() {
  //     if (this.myRef.current) {
  //         this.myRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
  //     }
  // }

  //==================================================================================================================
  //
  //                                              Utility Functions
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const onShowRedirectChange = props[pias.onShowRedirectChange];

    const forNormalBetaSiteBool = true;

    const divHeight = forNormalBetaSiteBool ? 290 : 120;
    const divPadding = forNormalBetaSiteBool ? 50 : 35;

    let betaTextDiv = null;

    if (forNormalBetaSiteBool) {
      betaTextDiv = (
        <div>
          <br />
          <Divider
            style={{
              backgroundColor: "black",
            }}
          />
          <br />
          To use the beta version of the tool, click "Continue to beta" below. The beta version includes changes that
          may be unstable. If you are unsure which version to use, please use the live version.
          <br />
          <br />
          <TButton
            caption={"Continue to beta"}
            key={"continueBtn"}
            onClick={() => onShowRedirectChange(false)}
            style={{
              backgroundColor: Theme.PI_TertiaryColor,
              display: "block",
              marginBottom: 20,
              marginLeft: 0,
              width: 200,
            }}
          />
        </div>
      );
    }

    return (
      <div
        // className = {"RedirectPage"}
        // ref       = {this.myRef}
        style={{
          display: "flex",
          justifyContent: "center",

          // transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)',
          // height: 'calc(100% - px)',
          // top: 0,
          // overflowY : 'auto',
          // position : 'absolute',
          // bottom: 0,
          // left: 0,
          // right: 0,
        }}
      >
        <div
          style={{
            alignItems: "center",
            borderColor: Theme.PI_PrimaryColor,
            borderRadius: Theme.PI_DivBorderRadius,
            borderWidth: "thin",
            borderStyle: "solid",
            display: "flex",
            //flexGrow        : 1,
            flexWrap: "wrap",
            fontSize: 22,
            height: divHeight,
            justifyContent: "center",
            marginTop: 25,
            padding: divPadding,
            position: "absolute",
            width: 750,
          }}
        >
          <div
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            PrEP-it is now live at PrEPitWeb.org! Please click the link below to proceed to the live tool.
            <br />
            <br />
            <Link
              onClick={() => window.open("https://www.PrEPitWeb.org/", "_self")}
              style={{
                color: Theme.PI_TertiaryColor,
                cursor: "pointer",
              }}
              underline={"hover"}
            >
              https://www.PrEPitWeb.org/
            </Link>
            {betaTextDiv}
          </div>
        </div>
      </div>
    );
  }
}

export default PIRedirectPage;
