import React, { Component } from "react";
import * as PropTypes from "prop-types";

//import * as Theme from "../../../../app/Theme";

//import GbStdTable from "../../../common/GBStdTableAG/GbStdTable";

//import * as gbtu from "../../../GB/GBTableUtil";
import * as gbu from "../../../GB/GBUtil";
//import * as gbtc from "../../../GB/GBTableConst";
import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";
//import * as piasu from "../../NonComponents/PIAppStateUtil";
import PIDefImpactPriorPopTable from "../../Tables/Reference/PIDefImpactPriorPopTable";
import * as pias from "../../NonComponents/PIAppState";

class PIImpactRefForm extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    /* props */

    const props = this.props;
    const modVarObjList = props[pias.modVarObjList];

    return (
      <PIDefImpactPriorPopTable
        {...{
          [pias.modVarObjList]: modVarObjList,
        }}
      />
    );
  };

  render() {
    const noteLab = (
      <p
        key={"noteLab"}
        style={
          {
            //...Theme.labelStyle,
          }
        }
      >
        {RS(SC.GB_stImpactEffectRefNote)}
      </p>
    );

    return (
      <React.Fragment>
        {this.renderTable()}
        {noteLab}
      </React.Fragment>
    );
  }
}

export default PIImpactRefForm;
