import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import * as pias from "../../NonComponents/PIAppState";
import * as pisc from "../../NonComponents/PIServerConst";
import * as pip from "../../NonComponents/PIProps";

import SuperTableShim from "../../../common/SuperTableShim";
import { generateTypes } from "../../../../utilities";

const firstRow = 0;
const numRows = 4;

const firstCol = 0;
const defPriorPopCol = 0;
const STISympCol = 1;
const multiPartnerCol = 2;
const sourceAssumCol = 3;
const numCols = sourceAssumCol + 1;

class PITargDefPriorPopRefTable3 extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),

    [pip.targEst]: PropTypes.object,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],

    [pip.targEst]: {},
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const targEst = props[pip.targEst];

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Column headings */

      gbtu.setValue(packTable, firstRow, defPriorPopCol, RS(SC.GB_stDefaultPriorPop));
      gbtu.setValue(packTable, firstRow, STISympCol, RS(SC.GB_stSTIStymptoms) + " (%)");
      gbtu.setValue(packTable, firstRow, multiPartnerCol, RS(SC.GB_stMultiPartnerCondomlessSex) + " (%)");
      gbtu.setValue(packTable, firstRow, sourceAssumCol, RS(SC.GB_stSourceAssum));

      /* Row headings */

      gbtu.setValue(packTable, 1, firstCol, "AGYW 15-24");
      gbtu.setValue(packTable, 2, firstCol, "Pregnant women");
      gbtu.setValue(packTable, 3, firstCol, "Breastfeeding women");

      /* Values */

      gbtu.setValue(packTable, 1, STISympCol, targEst[pisc.perc_AGYW_15_24_STI_no_dischargeTE]);
      gbtu.setValue(packTable, 2, STISympCol, targEst[pisc.perc_Pregnant_STI_no_dischargeTE]);
      gbtu.setValue(packTable, 3, STISympCol, targEst[pisc.perc_BF_STR_no_dischargeTE]);

      gbtu.setValue(packTable, 1, multiPartnerCol, targEst[pisc.perc_AGYW_15_24_HR2_MPCSTE]);
      gbtu.setValue(packTable, 2, multiPartnerCol, targEst[pisc.perc_Pregnant_MPCSTE]);
      gbtu.setValue(packTable, 3, multiPartnerCol, targEst[pisc.perc_BF_MPCSTE]);

      gbtu.setValue(packTable, 1, sourceAssumCol, targEst[pisc.AGYW_15_24_HIV_sourceTE]);
      gbtu.setValue(packTable, 2, sourceAssumCol, targEst[pisc.BF_sourceTE]);
      gbtu.setValue(packTable, 3, sourceAssumCol, targEst[pisc.BF_sourceTE]);

      gbtu.setColWidth(packTable, firstCol, Theme.itemNameColWidth);
      gbtu.setColWidth(packTable, STISympCol, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, multiPartnerCol, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, sourceAssumCol, Theme.itemNameColWidthExtraWide);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setRowHeights(packTable, 60);
      gbtu.setRowHeight(packTable, firstRow, 75);
      gbtu.setWordWrappedCol(packTable, firstCol, true);
      gbtu.lockPackTable(packTable, true, true);

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"targDefPriorPopRefTable3"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable, ["s", "n", "n", "s"])}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: 30,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "renderTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PITargDefPriorPopRefTable3;
