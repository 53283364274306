export const sex = {
  both: 0,
  male: 1,
  female: 2,
};

export const numSexes = sex.female;

export const dataType = {
  number: 1,
  percent: 2,
};

export const language = {
  english: 1,
  french: 2,
  arabic: 3,
  spanish: 4,
  russian: 5,
  portuguese: 6,
  chinese: 7,
  indonesian: 8,
};

export const monthMstIDs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

/* Screen size breakpoints from Bootstrap. */
export const smScreen = 768;
export const mdScreen = 850; // 992;
export const lgScreen = 1200;
