import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";

import * as pitu from "../../NonComponents/PITableUtil";

import SuperTableShim from "../../../common/SuperTableShim";

const numRows = 2;

class PIAvgMonthlyCostsResTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      let priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      //let costingModeMstID = piasu.getModVarValue(modVarObjList, pisc.costingModuleMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      const selectedMethodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      //const usingCostsLite = piu.usingCostsLite(costingModeMstID);

      const avgMonthlyCostPerPerson2DFltArr = piasu.getModVarValue(modVarObjList, pisc.avgMonthlyCostPerPersonPTMVTag);

      let packTable = gbtu.getNewPackTable();

      /* Number of service delivery strategies + edu code column + personnel type column. */
      const numCols = numPriorPops + 1;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Column headings */
      gbtu.setValue(packTable, 1, 0, RS(SC.GB_stAvgMonthlyCostPerPerson));

      for (let pp = 1; pp <= numPriorPops; pp++) {
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, 0, pp, priorPopName);

        //markElig
        const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, selectedMethodMstID, pp);
        if (methodEligMstIDStr === pisc.yesCVOMstID) {
          gbtu.setValue(
            packTable,
            1,
            pp,
            piasu.getAvgMonthlyCostPerPersonPP(avgMonthlyCostPerPerson2DFltArr, selectedMethodCurrID, pp)
          );
        } else {
          gbtu.lockCol(packTable, pp, true, true);
          const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
          gbtu.setColBGColor(packTable, pp, gainsboroBase10);
        }

        gbtu.setColWidth(packTable, pp, Theme.dataColWidthMed);
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 2);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setRowHeight(packTable, 0, 50);
      gbtu.setWordWrappedCol(packTable, 0, true);
      gbtu.lockPackTable(packTable, true, false);

      gbtu.setID(packTable, "packTable");
      gbtu.setKey(packTable, "packTable");

      if (window.DebugMode) {
        console.log("Component: PIAvgMonthlyCostsResTable");
        console.log("ModVar(s):");
        console.log(pisc.avgMonthlyCostPerPersonPTMVTag + ": ");
        console.log(avgMonthlyCostPerPerson2DFltArr);
        console.log(pisc.priorPopMethodEligMVTag + ": ");
        console.log(priorPopMethodEligObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"table"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIAvgMonthlyCostsResTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAvgMonthlyCostsResTable;
