import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as gbu from "../../../GB/GBUtil";
import * as pip from "../../NonComponents/PIProps";

import PIPercClientsInitPrEPGraph from "../../Graphs/PIPercClientsInitPrEPGraph";

class PITargScaleUpRefForm extends Component {
  static propTypes = {
    [pip.progDataPeriodObj]: PropTypes.object,

    [pip.scaleUpTrendsObjList]: PropTypes.arrayOf(PropTypes.object),

    [pip.targSettingPeriodObj]: PropTypes.object,
  };

  static defaultProps = {
    [pip.progDataPeriodObj]: {},

    [pip.scaleUpTrendsObjList]: [],

    [pip.targSettingPeriodObj]: {},
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const progDataSettingPeriodObj = props[pip.progDataPeriodObj];
    const scaleUpTrendsObjList = props[pip.scaleUpTrendsObjList];
    const targSettingPeriodObj = props[pip.targSettingPeriodObj];

    const percClientsInitPrEPGraph = (
      <PIPercClientsInitPrEPGraph
        {...{
          [pip.progDataPeriodObj]: progDataSettingPeriodObj,

          [pip.scaleUpTrendsObjList]: scaleUpTrendsObjList,

          [pip.targSettingPeriodObj]: targSettingPeriodObj,
        }}
      />
    );

    return <React.Fragment>{percClientsInitPrEPGraph}</React.Fragment>;
  }
}

export default PITargScaleUpRefForm;
