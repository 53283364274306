import * as React from "react";
import { CreateAPITask } from "../api/server_calls";

class App extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  state = {
    //--------------------------------------------
    // Client
    //--------------------------------------------

    developerMode: true,

    // General
    title: "",
    loading: true,
    expanded: true,

    // Navigation
    pageID: "GBMainFm",
    backPageID: "GBMainFm",

    // API
    apiTasks: [],
    heartBeat: 0,

    //--------------------------------------------
    // Server
    //--------------------------------------------

    // Server status
    serverAlive: false,
    version: {},
  };

  heartBeatTimer = null; // Javascript timer
  heartBeatDelay = 5000; // Amount of time to send a heartbeat to the server
  heartBeatsError = 10; // Amount of heartbeats before showing an error

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  componentWillUnmount() {
    this.onStopHeartBeat();
  }

  //==================================================================================================================
  //
  //                                                 Setup
  //
  //==================================================================================================================

  onStartHeartBeat = () => {
    this.heartBeatTimer = setInterval(() => {
      this.onIncrementHeartBeat();

      let arr = this.state.apiTasks.filter((x) => (x.flag === "getStatus" ? true : false));
      arr = arr.filter((x) => (x.completed !== true ? true : false));

      if (arr.length === 0) {
        this.onAddTasks([
          CreateAPITask(
            "getStatus",
            {},
            () => {},
            (msg) => {
              if (this.state.heartBeat > this.heartBeatsError) {
                this.onStopHeartBeat();
                alert("API heartbeat failed multiple times.  The server may be unavailable.");
              } else {
                console.log("Heartbeats: " + this.state.heartBeat);
              }
            }
          ),
        ]);
      }
    }, this.heartBeatDelay);
  };

  onStopHeartBeat = () => {
    clearInterval(this.heartBeatTimer);
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPageChange = (id) => {
    if (typeof id === "undefined" || id === "") {
      this.setState({
        pageID: this.state.pageID,
      });
      return;
    }

    if (id === "back") {
      id = this.state.backPageID;
    }

    this.setState({
      backPageID: this.state.pageID,
      pageID: id,
    });
  };

  onStateChange = (newState, successFn) => {
    let that = this;

    Object.keys(newState).forEach(function (key, index) {
      if (!that.state.hasOwnProperty(key)) {
        console.error("You have added in new state to <App /> that did not exist! Field is : " + key);
      }
    });

    this.setState(
      {
        ...this.state,
        ...newState,
      },
      successFn
    );
  };

  onAddTasks = (arr) => {
    this.setState((prevState, props) => ({
      apiTasks: [...prevState.apiTasks, ...arr],
    }));
  };

  onIncrementHeartBeat = () => {
    this.setState((prevState, props) => ({
      heartBeat: prevState.heartBeat + 1,
    }));
  };

  onHamburgerClick = (value) => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };
}

export default App;
