import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import GbStdTableWithComboBoxes from "../../GB/GbStdTableWithComboBoxes";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import { generateHeterogeneousTypes } from "../../../utilities";

const priorPopCurrIDC = "priorPopCurrID";
const methodCurrIDC = "methodCurrID";

class PIAssignContSchedToPriorPopLiteTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: "",
  };

  state = {
    [pip.focusedCell]: {
      [pip.rowFocus]: 0,
      [pip.colFocus]: 1,
    },
    /* Note: Fixed rows are NOT included. All numbers are zero-based. */
    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 0,
        [pip.rowEnd]: 0,
        [pip.columnStart]: 1,
        [pip.columnEnd]: 1,
      },
    ],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onContVisitSchedComboBoxChange = (value, text, info) => {
    try {
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      //let priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
      let contVisitSchedLiteObjList = piasu.getModVarValue(modVarObjListClone, pisc.contVisitSchedLiteMVTag);
      let costCatLiteObjList = piasu.getModVarValue(modVarObjListClone, pisc.costCategoriesLiteMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
      //const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopMethodEligMVTag);

      const contVisitSchedCurrID = value + 1;
      const priorPopCurrID = info[priorPopCurrIDC];
      const methodCurrID = info[methodCurrIDC];

      const methodMstIDV = piasu.methodMstID(methodObjArr, methodCurrID);

      //const contVisitSchedMstID = piasu.getContVisitSchedMstID(methodMstIDV, contVisitSchedLiteObjList, contVisitSchedCurrID);
      const contVisitSchedMstID = piasu.getContVisitSchedMstID(contVisitSchedLiteObjList, contVisitSchedCurrID);
      piasu.setPriorPopContVisitSchedMstIDCostsLite(
        methodMstIDV,
        costCatLiteObjList,
        priorPopCurrID,
        contVisitSchedMstID
      );

      onModVarsChange(modVarObjListClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const contVisitSchedLiteObjList = piasu.getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);
      const costCatLiteObjList = piasu.getModVarValue(modVarObjList, pisc.costCategoriesLiteMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      console.log(priorPopMethodEligObjArr);

      const numMethods = piasu.getTotalNumMethods(methodObjArr);

      const state = this.state;

      let packTable = gbtu.getNewPackTable();

      const numContVisitSchedules = piasu.getTotalNumContVisitSchedules(contVisitSchedLiteObjList);
      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);

      const numRows = numPriorPops + 1;
      const numCols = numMethods + 1;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, 0, 0, RS(SC.GB_stPriorityPop));

      const comboBoxWidth = 240;

      let comboBoxObj = pitu.getComboBoxObj(numRows, numCols);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, pp, 0, priorPopName);

        for (let m = 1; m <= numMethods; m++) {
          const methodMstID = piasu.methodMstID(methodObjArr, m);

          /* Set column headings. */
          const methodNameV = piasu.methodName(methodObjArr, m);
          gbtu.setValue(packTable, 0, m, methodNameV);

          //markElig
          const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);
          if (methodEligMstIDStr === pisc.yesCVOMstID) {
            gbtu.setHasComboBox(packTable, pp, m, true);

            comboBoxObj[pitu.style2DObjArray][pp][m] = {
              color: Theme.blackColor,
              fontFamily: Theme.fontFamily,
              width: comboBoxWidth,
            };

            let infoObjArr = [];
            infoObjArr.length = numContVisitSchedules;
            infoObjArr.fill({
              [priorPopCurrIDC]: pp,
              [methodCurrIDC]: m,
            });
            comboBoxObj[pitu.info3DIntArray][pp][m] = infoObjArr;

            comboBoxObj[pitu.item3DStrArray][pp][m] = piasu.getContVisitSchedNames(contVisitSchedLiteObjList);

            const contVisitSchedMstID = piasu.getPriorPopContVisitSchedMstIDCostsLite(
              methodMstID,
              costCatLiteObjList,
              pp
            );
            const contVisitSchedCurrID = piasu.getContVisitSchedCurrID(contVisitSchedLiteObjList, contVisitSchedMstID);
            comboBoxObj[pitu.idx2DIntArray][pp][m] = contVisitSchedCurrID - 1;

            comboBoxObj[pitu.onChange2DFuncArray][pp][m] = this.onContVisitSchedComboBoxChange;
          } else {
            // There are some tables were doing this would show "Method not used" for several cells, even
            // if the user only unchecks one prior pop for one method. Would we rather see grayed out cells
            // or have this phrase repeated, say 4 times in a row?
            // gbtu.setValue(packTable, pp, m, RS(SC.GB_stMethodNotUsed));
            // gbtu.lockCell(packTable, pp, m, true, false);

            gbtu.lockCell(packTable, pp, m, true, true);
            const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
            gbtu.setCellBGColor(packTable, pp, m, gainsboroBase10);
          }
        }
      }

      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
      gbtu.setColWidth(packTable, 0, Theme.itemNameColWidthWide);
      gbtu.setRowHeight(packTable, 0, 50);
      gbtu.setWordWrappedCol(packTable, 0, true);

      for (let m = 1; m <= numMethods; m++) {
        gbtu.setColWidth(packTable, m, comboBoxWidth + 10);
      }

      if (window.DebugMode) {
        console.log("Component: PIAssignContSchedToPriorPopLiteTable");
        console.log("ModVar(s):");
        console.log(pisc.contVisitSchedLiteMVTag + ": ");
        console.log(contVisitSchedLiteObjList);
        console.log(pisc.costCategoriesLiteMVTag + ": ");
        console.log(costCatLiteObjList);
        console.log(pisc.priorPopMethodEligMVTag + ": ");
        console.log(priorPopMethodEligObjArr);
        console.log("");
      }

      const stdTable = (
        <GbStdTableWithComboBoxes
          focusedCell={state[pip.focusedCell]}
          onCellFocused={(focusedCell) => pitu.onCellFocused(this, focusedCell)}
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          gridKey={tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateHeterogeneousTypes(packTable, "dd")}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          comboBoxStyle={comboBoxObj[pitu.style2DObjArray]}
          comboBoxInfo={comboBoxObj[pitu.info3DIntArray]}
          comboBoxItems={comboBoxObj[pitu.item3DStrArray]}
          comboBoxIndices={comboBoxObj[pitu.idx2DIntArray]}
          onComboBoxChange={comboBoxObj[pitu.onChange2DFuncArray]}
          width={0}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIAssignContSchedToPriorPopLiteTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAssignContSchedToPriorPopLiteTable;
