import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbu from "../../GB/GBUtil";
import PIAGYWTable from "../Tables/PIAGYWTable";
import PIAGYWCostTable from "../Tables/PIAGYWCostTable";

class PIAGYWResultContent extends Component {
  static propTypes = {
    [pip.countryISO3Str]: PropTypes.string,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.showInDashboardAreaBool]: PropTypes.bool,
    [pip.showInTargetsAreaBool]: PropTypes.bool,
  };

  static defaultProps = {
    [pip.countryISO3Str]: "",

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pip.showInDashboardAreaBool]: false,
    [pip.showInTargetsAreaBool]: false,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderContent = () => {
    const fn = () => {
      const props = this.props;
      const countryISO3Str = props[pip.countryISO3Str];
      const modVarObjList = props[pias.modVarObjList];
      const onModVarsChange = props[pias.onModVarsChange];
      const showInDashboardAreaBool = props[pip.showInDashboardAreaBool];
      const showInTargetsAreaBool = props[pip.showInTargetsAreaBool];

      const levelNames1DStrArr = piasu.getModVarValue(modVarObjList, pisc.adminSubnatLevelsDisagMVTag);

      const level1Name = piasu.adminSubnatLevelName(levelNames1DStrArr, 1);

      const PIAGYWTableComp = (
        <PIAGYWTable
          {...{
            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.showInDashboardAreaBool]: showInDashboardAreaBool,
            [pip.showInTargetsAreaBool]: showInTargetsAreaBool,
          }}
        />
      );

      let specLevel1Lab = null;

      // markAGYW
      if (level1Name === "") {
        specLevel1Lab = (
          <p
            style={{
              display: "block",
              marginTop: 20,
              ...Theme.textFontStyle,
            }}
          >
            {RS(SC.GB_stSpecifyASubnatLevelToViewTable)}
          </p>
        );
      }

      const PIAGYWCostTableComp = (
        <PIAGYWCostTable
          {...{
            [pip.countryISO3Str]: countryISO3Str,

            [pias.modVarObjList]: modVarObjList,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.showInTargetsAreaBool]: showInTargetsAreaBool,
            [pip.showInDashboardAreaBool]: showInDashboardAreaBool,
          }}
        />
      );

      return (
        <React.Fragment>
          {PIAGYWTableComp}
          {specLevel1Lab}
          {PIAGYWCostTableComp}
        </React.Fragment>
      );
    };

    return gbu.tryRenderFn(fn, "renderContent");
  };

  render() {
    try {
      return <React.Fragment>{this.renderContent()}</React.Fragment>;
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIAGYWResultContent;
