import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import { generateHeterogeneousTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

class PIPriorPopMethodEligTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    //[pip.tableKey]           : PropTypes.string,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    //[pip.tableKey]           : "",
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChanged = (newPackTable) => {
    try {
      const props = this.props;
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const methodObjArr = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
      const priorPopObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopMethodEligMVTag);

      const newPackTableClone = gbu.cloneObj(newPackTable);

      const numMethods = piasu.getTotalNumMethods(methodObjArr);
      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArr);

      // const numRows = numPriorPops;
      // const numCols = numMethods + 1;

      for (let pp = 1; pp <= numPriorPops; pp++) {
        for (let m = 1; m <= numMethods; m++) {
          const methodMstIDV = piasu.methodMstID(methodObjArr, m);

          const checkBoxState = gbtu.getCheckBoxState(newPackTableClone, pp, m);
          if (checkBoxState) {
            piasu.setPriorPopMethodElig(priorPopMethodEligObjArr, methodMstIDV, pp, pisc.yesCVOMstID);
          } else {
            piasu.setPriorPopMethodElig(priorPopMethodEligObjArr, methodMstIDV, pp, pisc.noCVOMstID);
          }
        }
      }

      onModVarsChange(modVarObjListClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      //const tableKey = props[pip.tableKey];

      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const priorPopObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      let packTable = gbtu.getNewPackTable();

      const numMethods = piasu.getTotalNumMethods(methodObjArr);
      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArr);

      const numRows = numPriorPops + 1;
      const numCols = numMethods + 1;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, 0, 0, RS(SC.GB_stPriorityPop));

      for (let pp = 1; pp <= numPriorPops; pp++) {
        gbtu.setValue(packTable, pp, 0, piasu.getPriorPopName(priorPopObjArr, pp));

        for (let m = 1; m <= numMethods; m++) {
          const methodMstIDV = piasu.methodMstID(methodObjArr, m);

          if (pp === 1) {
            gbtu.setValue(packTable, 0, m, piasu.methodName(methodObjArr, m));
          }

          gbtu.setHasCheckBox(packTable, pp, m, true);
          gbtu.setAlignment(packTable, pp, m, gbtc.hAlign.center);
          //gbtu.lockCell(packTable, pp, m, true, false);
          const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstIDV, pp);
          gbtu.setCheckBoxState(packTable, pp, m, methodEligMstIDStr === pisc.yesCVOMstID ? true : false);
        }
      }

      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
      gbtu.setColWidth(packTable, 0, Theme.itemNameColWidthWide);
      gbtu.setRowHeight(packTable, 0, 50);
      gbtu.setWordWrappedCol(packTable, 0, true);

      if (window.DebugMode) {
        console.log("Component: PIPriorPopMethodEligTable");
        console.log("ModVar(s):");
        console.log(pisc.priorPopMethodEligMVTag + ": ");
        console.log(priorPopMethodEligObjArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          //key                   = {tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateHeterogeneousTypes(packTable, "cb")}
          onPackTableChanged={this.onPackTableChanged}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing * 2,
            padding: 0,
            width: 1000,
          }}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIPriorPopMethodEligTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIPriorPopMethodEligTable;
