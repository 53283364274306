import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";
import TTabs2 from "../../common/TTabs2";

import Divider from "@material-ui/core/Divider";

import * as Theme from "../../../app/Theme";

import * as pic from "../NonComponents/PIConst";
import * as pias from "../NonComponents/PIAppState";
import PINavBtnDiv, { PINavBtnDivProps } from "../Other/PINavBtnDiv";
import * as pip from "../NonComponents/PIProps";
import PIItemsSlideDrawer from "../Drawers/PIItemsSlideDrawer";
//import PICapacityMonthlyVisitTable from "../Tables/PICapacityMonthlyVisitTable";
import PICapacityStaffMinutesTable from "../Tables/PICapacityStaffMinutesTable";
import PICapacityClientPrEPVisitsTable from "../Tables/PICapacityClientPrEPVisitsTable";
import PICapacityAnnualPrEPTable from "../Tables/PICapacityAnnualPrEPTable";
import PICapacityPillsPerClientPerDayTable from "../Tables/PICapacityPillsPerClientPerDayTable";
import PICapacityMonthlyTable from "../Tables/PICapacityMonthlyTable";
import * as php from "../NonComponents/PIHelp";
//import * as piasu from "../NonComponents/PIAppStateUtil";
//import * as pisc from "../NonComponents/PIServerConst";
import { cloneObj } from "../../GB/GBUtil";
import { onCalculate } from "../NonComponents/PICalc";
//import * as piv from "../NonComponents/PIValidate";

const assignStaffTypesTab = 0;
const firstTab = assignStaffTypesTab;
const minutesTab = 1;
const visitsTab = 2;
const resultsTab = 3;
const finalTab = resultsTab;

const selectedTabIdxC = "selectedTabIdx";
const showServDelivUnitSlideDrawerBoolC = "showServDelivUnitSlideDrawerBool";
const showStaffTypesSlideDrawerBoolC = "showStaffTypesSlideDrawerBool";

class PICapacityForm extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.onDialogChange]: PropTypes.func,

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarChange]: PropTypes.func,
    [pias.onModVarsChange]: PropTypes.func,

    [pias.onPageChange]: PropTypes.func,

    [pias.tableKeyObj]: PropTypes.object,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.onDialogChange]: () => console.log(pias.onDialogChange),

    [pias.helpAreaStr]: php.capacityFM_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarChange]: () => console.log(pias.onModVarChange),
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pias.onPageChange]: () => console.log(pias.onPageChange),

    [pias.tableKeyObj]: {},
  };

  state = {
    [selectedTabIdxC]: assignStaffTypesTab,
    [showServDelivUnitSlideDrawerBoolC]: false,
    [showStaffTypesSlideDrawerBoolC]: false,
  };

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  getHelpAreaStr = (tabIdx) => {
    let helpAreaStr = "";

    switch (tabIdx) {
      case assignStaffTypesTab:
        helpAreaStr = php.capacityFM_HP;
        break;

      case minutesTab:
        helpAreaStr = php.capacityFM_HP;
        break;

      case visitsTab:
        helpAreaStr = php.capacityFM_HP;
        break;

      case resultsTab:
        helpAreaStr = php.capacityFM_HP;
        break;

      default:
        break;
    }

    return helpAreaStr;
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onStaffTypesSlideDrawerClick = () => {
    try {
      this.setState({
        [showStaffTypesSlideDrawerBoolC]: !this.state[showStaffTypesSlideDrawerBoolC],
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onServDelivUnitSlideDrawerClick = () => {
    try {
      this.setState({
        [showServDelivUnitSlideDrawerBoolC]: !this.state[showServDelivUnitSlideDrawerBoolC],
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onTabIdxChange = (tabIdx) => {
    const props = this.props;
    const onDialogChange = props[pias.onDialogChange];
    const onCalculatingChange = props[pias.onCalculatingChange];
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const modVarObjListClone = cloneObj(props[pias.modVarObjList]);
    const onModVarsChange = props[pias.onModVarsChange];

    if (tabIdx === resultsTab) {
      this.setState(
        {
          [selectedTabIdxC]: tabIdx,
        },
        () => {
          onHelpAreaChange(php.capacityFM_HP, () => {
            onModVarsChange(modVarObjListClone, false, () => {
              onCalculatingChange(true, () => {
                /* Put this here because after the editor values change, the user needs to see
                               the graph under it update. */
                onCalculate(
                  modVarObjListClone,
                  "",
                  onDialogChange,
                  (response2) => {
                    onModVarsChange(response2, false, () => {
                      onCalculatingChange(false);
                    });
                  },
                  () => onCalculatingChange(false)
                );
              });
            });
          });
        }
      );
    } else {
      let helpAreaStr = this.getHelpAreaStr(tabIdx);

      onHelpAreaChange(helpAreaStr, () => {
        this.setState({
          [selectedTabIdxC]: tabIdx,
        });
      });
    }
  };

  onNavBtnClick = (direction) => {
    const props = this.props;
    //const modVarObjArray = props[pias.modVarObjList];
    const onPageChange = props[pias.onPageChange];

    const state = this.state;
    let selectedTabIdx = state[selectedTabIdxC];

    let onPageChangeEvent = undefined;

    if (direction === pic.back) {
      if (selectedTabIdx === firstTab) {
        onPageChangeEvent = () => onPageChange(pic.targetsForm);
      } else {
        selectedTabIdx--;
      }
    } else if (direction === pic.next) {
      if (selectedTabIdx === finalTab) {
        onPageChangeEvent = () => onPageChange(pic.disagTargetsForm);
      } else {
        selectedTabIdx++;
      }
    }

    if (typeof onPageChangeEvent !== "undefined") {
      onPageChangeEvent();
    } else {
      this.onTabIdxChange(selectedTabIdx);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderAssignStaffTypesTab = () => {
    const props = this.props;
    const onHelpAreaChange = props[pias.onHelpAreaChange];
    const origModVarObjArr = props[pias.origModVarObjArr];
    const modVarObjArr = props[pias.modVarObjList];
    const onDialogChange = props[pias.onDialogChange];
    const onModVarsChange = props[pias.onModVarsChange];

    const state = this.state;
    const showStaffTypesSlideDrawerBool = state[showStaffTypesSlideDrawerBoolC];

    const estStaffTypesLab = (
      <p
        key={"staffTypeMinLab"}
        style={{
          ...Theme.labelStyle,
          marginTop: 30,
        }}
      >
        {RS(SC.GB_stEstStaffTypes)}
      </p>
    );

    const tempMsg = () => {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = "Coming soon";
      dialogObj[pias.headerStr] = RS(SC.GB_stNote);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert("Coming soon");
    };

    const staffTypesBtn = (
      <TButton
        caption={RS(SC.GB_stStaffTypes)}
        onClick={tempMsg} //this.onStaffTypesSlideDrawerClick}
        containerStyle={{
          display: "block",
          marginTop: 20,
        }}
        style={{
          backgroundColor: Theme.PI_TertiaryColor,
          width: 225,
        }}
      />
    );

    let staffTypesSlideDrawer = null;

    if (showStaffTypesSlideDrawerBool) {
      staffTypesSlideDrawer = (
        <PIItemsSlideDrawer
          {...{
            /* State-related */

            [pias.onDialogChange]: onDialogChange,
            [pias.onDrawerChange]: this.onStaffTypesSlideDrawerClick,

            [pias.onHelpAreaChange]: onHelpAreaChange,

            [pias.modVarObjList]: modVarObjArr,
            [pias.origModVarObjArr]: origModVarObjArr,
            [pias.onModVarsChange]: onModVarsChange,

            [pip.itemType]: pic.staffTypeItems,

            [pias.tableKeyObj]: props[pias.tableKeyObj],

            /* For top form */

            [pip.itemDrawerIDStr]: pic.itemsSlideDrawer,
            [pip.itemDrawerTitleStr]: RS(SC.GB_stStaffTypes),
            [pip.mngCustomItemsStr]: RS(SC.GB_stManageCustomStaffTypes),
            [pip.selectItemsStr]: RS(SC.GB_stSelectStaffTypes),
            [pip.yourItemsStr]: RS(SC.GB_stYourStaffTypes),

            /* For custom item form */

            [pip.addItemNoteStr]: RS(SC.GB_stAddStaffTypeNote),
            [pip.customItemDrawerTitleStr]: RS(SC.GB_stCustomStaffTypes),
            [pip.editItemInstructStr]: RS(SC.GB_stEditStaffTypesInstruct),
            [pip.itemTypeStr]: RS(SC.GB_stStaffType),
            [pip.removeItemNoteStr]: RS(SC.GB_stRemoveStaffTypeNote),
            [pip.moveItemNoteStr]: RS(SC.GB_stMoveStaffTypeUpNote),
            [pip.moveItemDownNoteStr]: RS(SC.GB_stMoveStaffTypeDownNote),
          }}
        />
      );
    }

    return (
      <React.Fragment>
        {estStaffTypesLab}
        {staffTypesBtn}
        {staffTypesSlideDrawer}
      </React.Fragment>
    );
  };

  renderMinutesTab = () => {
    // needs to go by method
    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];

    const staffTypeMinLab = (
      <p
        key={"staffTypeMinLab"}
        style={{
          ...Theme.labelStyle,
          marginTop: 30,
        }}
      >
        {RS(SC.GB_stEstStaffTimeReq)}
      </p>
    );

    const minTable = (
      <PICapacityStaffMinutesTable
        {...{
          [pias.modVarObjList]: modVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,
        }}
      />
    );

    return (
      <React.Fragment>
        {staffTypeMinLab}
        {minTable}
      </React.Fragment>
    );
  };

  renderVisitsTab = () => {
    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];

    const propVisitsLab = (
      <p
        key={"propVisitsLab"}
        style={{
          ...Theme.labelStyle,
          marginTop: 30,
        }}
      >
        {RS(SC.GB_stEstPropClientVisitsPrEPInit)}
      </p>
    );

    const clientPrEPTable = (
      <PICapacityClientPrEPVisitsTable
        {...{
          [pias.modVarObjList]: modVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,
        }}
      />
    );

    return (
      <React.Fragment>
        {propVisitsLab}
        {clientPrEPTable}
      </React.Fragment>
    );
  };

  renderResultsTab = () => {
    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];

    const monthlyCapLab = (
      <p
        key={"monthlyCapLab"}
        style={{
          ...Theme.labelStyle,
          marginTop: 30,
        }}
      >
        {RS(SC.GB_stMonthlyCapacity)}
      </p>
    );

    const monthTable = (
      <PICapacityMonthlyTable
        {...{
          [pias.modVarObjList]: modVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,
        }}
      />
    );

    const drugCapLab = (
      <p
        key={"drugCapLab"}
        style={{
          ...Theme.labelStyle,
          marginTop: 30,
        }}
      >
        {RS(SC.GB_stEnterDataTotalDrugCap)}
      </p>
    );

    const annPrEPTable = (
      <PICapacityAnnualPrEPTable
        {...{
          [pias.modVarObjList]: modVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,
        }}
      />
    );

    // resultsTableLab =
    //     <p
    //         key   = {"resultsTableLab"}
    //         style = {{
    //             ...Theme.labelStyle,
    //         }}
    //     >
    //         {RS(SC.GB_stImpCostsBasedTargs)}
    //     </p>;

    const pillTable = (
      <PICapacityPillsPerClientPerDayTable
        {...{
          [pias.modVarObjList]: modVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,
        }}
      />
    );

    return (
      <React.Fragment>
        {monthlyCapLab}
        {monthTable}
        {drugCapLab}
        {annPrEPTable}
        {pillTable}
      </React.Fragment>
    );
  };

  renderAllTabContent = () => {
    const state = this.state;
    const selectedTabIdx = state[selectedTabIdxC];

    let assignStaffTypesTabContents = null;
    let minutesTabContents = null;
    let costsTabContents = null;
    let resultsTabContents = null;

    if (selectedTabIdx === assignStaffTypesTab) {
      assignStaffTypesTabContents = this.renderAssignStaffTypesTab();
    } else if (selectedTabIdx === minutesTab) {
      minutesTabContents = this.renderMinutesTab();
    } else if (selectedTabIdx === visitsTab) {
      costsTabContents = this.renderVisitsTab();
    } else if (selectedTabIdx === resultsTab) {
      resultsTabContents = this.renderResultsTab();
    }

    return (
      <React.Fragment>
        {assignStaffTypesTabContents}
        {minutesTabContents}
        {costsTabContents}
        {resultsTabContents}
      </React.Fragment>
    );
  };

  render() {
    try {
      //const props = this.props;
      //const onHelpAreaChange = props[pias.onHelpAreaChange];
      //const modVarObjList = props[pias.modVarObjList];
      //const origModVarObjArr = props[pias.origModVarObjArr];
      //const onModVarsChange = props[pias.onModVarsChange];
      //const onPageChange = props[pias.onPageChange];

      const state = this.state;
      const selectedTabIdx = state[selectedTabIdxC];
      //const showServDelivUnitSlideDrawerBool = state[showServDelivUnitSlideDrawerBoolC];

      const areaLab = (
        <p
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stCapacity)}
        </p>
      );

      // const servDelivUnitBtn =
      //     <TButton
      //         caption        = {RS(SC.GB_stServDelivUnit)}
      //         onClick        = {this.onServDelivUnitSlideDrawerClick}
      //         containerStyle = {{
      //             display   : "block",
      //             marginTop : 20,
      //         }}
      //         style          = {{
      //             backgroundColor : Theme.PI_TertiaryColor,
      //             width           : 225,
      //         }}
      //     />;
      //
      // let servDelivUnitSlideDrawer = null;
      //
      // if (showServDelivUnitSlideDrawerBool) {
      //
      //     servDelivUnitSlideDrawer =
      //         <PIItemsSlideDrawer
      //             {...{
      //                 /* State-related */
      //                 [pias.onDrawerChange]      : this.onServDelivUnitSlideDrawerClick,
      //
      //                 [pias.onHelpAreaChange]    : onHelpAreaChange,
      //
      //                 [pias.modVarObjList]       : modVarObjList,
      //                 [pias.origModVarObjArr]    : origModVarObjArr,
      //                 [pias.onModVarsChange]     : onModVarsChange,
      //
      //                 [pip.itemType]             : pic.servDelivUnitItems,
      //
      //                 [pias.tableKeyObj]         : props[pias.tableKeyObj],
      //
      //                 /* For top form */
      //
      //                 [pip.itemDrawerIDStr]      : pic.itemsSlideDrawer,
      //                 [pip.itemDrawerTitleStr]   : RS(SC.GB_stServDelivUnits),
      //                 [pip.mngCustomItemsStr]    : RS(SC.GB_stManageCustomServDelivUnits),
      //                 [pip.selectItemsStr]       : RS(SC.GB_stSelectServDelivUnits),
      //                 [pip.yourItemsStr]         : RS(SC.GB_stYourServDelivUnits),
      //
      //                 /* For custom item form */
      //
      //                 [pip.addItemNoteStr]           : RS(SC.GB_stAddServDelivUnitNote),
      //                 [pip.customItemDrawerTitleStr] : RS(SC.GB_stCustomServDelivUnits),
      //                 [pip.editItemInstructStr]      : RS(SC.GB_stEditServDelivUnitsInstruct),
      //                 [pip.itemTypeStr]              : RS(SC.GB_stServDelivUnit),
      //                 [pip.removeItemNoteStr]        : RS(SC.GB_stRemoveServDelivUnitNote),
      //                 [pip.moveItemNoteStr]          : RS(SC.GB_stMoveServDelivUnitUpNote),
      //                 [pip.moveItemDownNoteStr]      : RS(SC.GB_stMoveServDelivUnitDownNote),
      //             }}
      //         />;
      //
      // }

      // Table1
      // const monthlyVisitLab =
      //     <p
      //         key   = {"monthlyVisitLab"}
      //         style = {{
      //             ...Theme.labelStyle,
      //         }}
      //     >
      //         {RS(SC.GB_stMonthlyCapInstructions)}
      //     </p>;
      //
      // const visitTable =
      //     <PICapacityMonthlyVisitTable
      //         {...{
      //             [pias.modVarObjList]                    : modVarObjList,
      //             [pias.onModVarsChange]                  : onModVarsChange
      //         }}
      //     />;

      const tabs = (
        <TTabs2
          onChange={this.onTabIdxChange}
          selectedTabIdx={selectedTabIdx}
          style={{
            marginTop: 10,
          }}
          tabBackgroundColor={"inherit"}
          tabBarOutline={"none"}
          tabContents={["", "", "", ""]}
          tabTitles={[RS(SC.GB_stAssignStaffTypes), RS(SC.GB_stMinutes), RS(SC.GB_stVisits), RS(SC.GB_stResults)]}
        />
      );

      const allTabContent = this.renderAllTabContent();

      const navBtnDiv = (
        <PINavBtnDiv
          {...{
            [PINavBtnDivProps.onBackBtnClick]: () => this.onNavBtnClick(pic.back),
            [PINavBtnDivProps.onNextBtnClick]: () => this.onNavBtnClick(pic.next),
          }}
        />
      );

      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          {areaLab}

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {tabs}
          {allTabContent}

          {navBtnDiv}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PICapacityForm;
