import * as React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import TSlideDrawer from "../../../common/TSlideDrawer";

import * as gbu from "../../../GB/GBUtil";

import PITargDefPriorPopRefForm from "../../Forms/Reference/PITargDefPriorPopRefForm";
import * as pias from "../../NonComponents/PIAppState";
import * as pip from "../../NonComponents/PIProps";
//import * as pic from "../../NonComponents/PIConst";
import * as php from "../../NonComponents/PIHelp";
import THelpButton from "../../../common/THelpButton";

class PITargDefPriorPopRefSlideDrawer extends React.Component {
  static propTypes = {
    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),

    [pip.targEst]: PropTypes.object,

    [pip.onToggleRefSlideDrawer]: PropTypes.func,
  };

  static defaultProps = {
    [pias.helpAreaStr]: php.config_Cont_RefRD_HP,
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],

    [pip.targEst]: {},

    [pip.onToggleRefSlideDrawer]: () => console.log(pip.onToggleRefSlideDrawer),
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onHelpBtnClick = () => {
    this.props.onHelp();
  };

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  render() {
    /* props */

    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];
    const targEst = props[pip.targEst];
    const onToggleRefSlideDrawer = props[pip.onToggleRefSlideDrawer];

    /* state */

    //const state = this.state;

    const targDefPriorPopRefFormComp = (
      <PITargDefPriorPopRefForm
        {...{
          [pias.modVarObjList]: modVarObjArr,
          [pip.targEst]: targEst,
        }}
      />
    );

    const slideDrawer = (
      <TSlideDrawer
        anchor={"right"}
        onClose={onToggleRefSlideDrawer}
        content={targDefPriorPopRefFormComp}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        saveButton={false}
        title={RS(SC.GB_stDefaultPriorPops)}
        width={Theme.slideDrawerLv2Width}
        helpBtn={<THelpButton Theme={Theme} onClick={this.onHelpBtnClick} />}
      />
    );

    return slideDrawer;
  }
}

export default PITargDefPriorPopRefSlideDrawer;
