import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

class TLabel extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    caption: PropTypes.string,
    onClick: PropTypes.func,
    pictureRight: PropTypes.any,
    style: PropTypes.object,
  };

  static defaultProps = {
    caption: "Caption",
    onClick: () => console.log("onClick"),
    pictureRight: null,
    style: {},
  };

  state = {
    itemIndex: 0,
  };

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  onClick = () => {
    this.props.onClick();
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    return (
      <Typography onClick={this.onClick} style={{ ...this.props.style }}>
        {this.props.caption}
        {this.props.pictureRight}
      </Typography>
    );
  }
}

export default TLabel;
