import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import SuperTableShim from "../../common/SuperTableShim";

export const PICapacityClientPrEPVisitsTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

const catCol = 0;
const valCol = 1;

const firstRow = 0;

const numRows = 5;
const numCols = 2;

class PICapacityClientPrEPVisitsTable extends Component {
  static propTypes = {
    [PICapacityClientPrEPVisitsTableProps.allowEditsBoolC]: PropTypes.bool,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [PICapacityClientPrEPVisitsTableProps.allowEditsBoolC]: true,

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTableChange = (newPackTable) => {
    try {
      const props = this.props;
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const newPackTableClone = gbu.cloneObj(newPackTable);

      let capacity = piasu.getModVarValue(modVarObjListClone, pisc.clientPrEPVisitsMVTag);

      capacity.init.rate = newPackTableClone.tableData.value[1][1];
      capacity.cont.rate = newPackTableClone.tableData.value[2][1];
      capacity.init.months = newPackTableClone.tableData.value[3][1];
      capacity.cont.months = newPackTableClone.tableData.value[4][1];

      piasu.setModVarValue(modVarObjListClone, pisc.clientPrEPVisitsMVTag, capacity);

      onModVarsChange(modVarObjListClone, () => {});
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      let clientPrEPVisits = piasu.getModVarValue(modVarObjList, pisc.clientPrEPVisitsMVTag);

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      // gbtu.setValue(packTable, firstRow, catCol, "Estimate the proportion of all client visits that are PrEP initiations");

      gbtu.setValue(packTable, firstRow + 1, catCol, RS(SC.GB_stPropAllVisitsInit) + " (%)");
      gbtu.setValue(packTable, firstRow + 2, catCol, RS(SC.GB_stPropAllVisitsCont) + " (%)");
      gbtu.setValue(packTable, firstRow + 3, catCol, RS(SC.GB_stMonthsPrEPInit));
      gbtu.setValue(packTable, firstRow + 4, catCol, RS(SC.GB_stMonthsPrEPCont));

      gbtu.setValue(packTable, firstRow + 1, valCol, 17); //capacity.init.rate);
      gbtu.setValue(packTable, firstRow + 2, valCol, 17); //capacity.cont.rate);
      gbtu.setValue(packTable, firstRow + 3, valCol, 317); //capacity.init.months);
      gbtu.setValue(packTable, firstRow + 4, valCol, 317); //capacity.cont.months);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      //gbtu.setRDecByRow(packTable, 2, 1);
      gbtu.setColWidth(packTable, catCol, 350);
      gbtu.setColWidth(packTable, valCol, Theme.dataColWidthSmall);
      gbtu.setWordWrappedCol(packTable, valCol, true);
      // gbtu.setRowHeight(packTable, firstRow, 50);

      gbtu.setMinAllowedValByCol(packTable, valCol, 0);
      gbtu.setMaxAllowedValByCol(packTable, valCol, gbtc.maxInt);

      if (window.DebugMode) {
        console.log("Component: PICapacityClientPrEPVisitsTable");
        console.log("ModVar(s):");
        console.log(pisc.clientPrEPVisitsMVTag);
        console.log(clientPrEPVisits);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          onPackTableChanged={this.onTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: 20,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PICapacityClientPrEPVisitsTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PICapacityClientPrEPVisitsTable;
