import React, { Component } from "react";

import Divider from "@material-ui/core/Divider";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbu from "../../GB/GBUtil";

import Link from "@material-ui/core/Link/Link";

class PIResourcesForm extends Component {
  static propTypes = {};

  static defaultProps = {};

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onRenderLink = (text, key, onClickEvent) => {
    let onLinkClickEvent = {};
    if (typeof onClickEvent !== "undefined") {
      onLinkClickEvent = onClickEvent;
    }

    const link = (
      <div
        key={key + " _div"}
        style={{
          marginLeft: 40,
          marginTop: 10,
        }}
      >
        <Link
          key={key + "_link"}
          style={{
            color: Theme.PI_PrimaryColor,
            cursor: "pointer",
            ...Theme.textFontStyle,
          }}
          underline={"hover"}
          onClick={onLinkClickEvent}
        >
          <p key={key + "_p"}>{text}</p>
        </Link>
      </div>
    );

    return link;
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    try {
      /************   Intro - Resources   *************/

      const areaLab = (
        <p
          key={"areaLab"}
          style={{
            display: "inline-block",
            ...Theme.pageHeadingFontStyle,
            marginTop: 10,
          }}
        >
          {RS(SC.GB_stResources)}
        </p>
      );

      const aboutPara1Text = (
        <p
          key={"aboutPara1Text"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stAboutPara1)}
        </p>
      );

      /************   Getting started   *************/

      const gettingStartedLab = (
        <p
          key={"gettingStartedLab"}
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stGettingStarted)}
        </p>
      );

      const gettingStartedPara1Text = (
        <p
          key={"gettingStartedPara1Text"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stGettingStartedPara1)}
        </p>
      );

      /************   Help   *************/

      const helpLab = (
        <p
          key={"helpLab"}
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stHelp)}
        </p>
      );

      const helpPara1Text = (
        <p
          key={"helpPara1Text"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stHelpPara1)}
        </p>
      );

      const helpManualDownloadText = (
        <p
          key={"clickHereHelpManualPDFText"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          <Link
            onClick={() => gbu.resourceClick("PrEP-it Help Manual.pdf")}
            style={{
              color: Theme.PI_PrimaryColor,
              cursor: "pointer",
              fontWeight: "bold",
            }}
            underline={"hover"}
          >
            {RS(SC.GB_stClickHere)}
          </Link>
          &nbsp;
          {RS(SC.GB_stToDownloadHelpManual)}
        </p>
      );

      /************   Saving your work   *************/

      const saveWorkLab = (
        <p
          key={"saveWorkLab"}
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stSaveWork)}
        </p>
      );

      const saveWorkPara1Text = (
        <p
          key={"saveWorkPara1Text"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stSaveWorkPara1)}
        </p>
      );

      /************   Quick guides  *************/

      const guickGuidesLab = (
        <p
          key={"guickGuidesLab"}
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stQuickGuides)}
        </p>
      );

      const quickGuidesIntroText = (
        <p
          key={"quickGuidesIntroText"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stQuickGuidesIntro)}
        </p>
      );

      const quickGuidesOL = (
        <ol>
          <li>
            <Link
              onClick={() => gbu.resourceClick("Quick guide - Commodities.pdf")}
              style={{
                color: Theme.PI_PrimaryColor,
                cursor: "pointer",
                fontWeight: "bold",
              }}
              underline={"hover"}
            >
              {RS(SC.GB_stQuickGuideCommodities)}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => gbu.resourceClick("Quick guide - Target costs.pdf")}
              style={{
                color: Theme.PI_PrimaryColor,
                cursor: "pointer",
                fontWeight: "bold",
              }}
              underline={"hover"}
            >
              {RS(SC.GB_stQuickGuideTargCosts)}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => gbu.resourceClick("Quick guide - Set targets.pdf")}
              style={{
                color: Theme.PI_PrimaryColor,
                cursor: "pointer",
                fontWeight: "bold",
              }}
              underline={"hover"}
            >
              {RS(SC.GB_stQuickGuideSetTargets)}
            </Link>
          </li>
        </ol>
      );

      /************   PrEP-it data needs   *************/

      const dataNeedsTargSettingLab = (
        <p
          key={"dataNeedsTargSettingLab"}
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stDataNeedsTargSetting)}
        </p>
      );

      // const clickHerePDFText2 =
      //     <p
      //         key   = {"clickHerePDFText"}
      //         style = {{
      //             ...Theme.textFontStyle,
      //         }}
      //     >
      //         <Link
      //             onClick   = {() => gbu.resourceClick("PrEP-it data needs.pdf")}
      //             style     = {{
      //                 color      : Theme.PI_PrimaryColor,
      //                 cursor     : "pointer",
      //                 fontWeight : "bold",
      //             }}
      //             underline = {"hover"}
      //         >
      //             {RS(SC.GB_stClickHere)}
      //         </Link>
      //
      //         &nbsp;
      //
      //         {RS(SC.GB_stClickHerePDF)}
      //     </p>;

      const inputTrackerPDFPara = (
        <p
          key={"clickHerePDFText"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          <Link
            onClick={() => gbu.resourceClick("PrEP-it input tracker.pdf")}
            style={{
              color: Theme.PI_PrimaryColor,
              cursor: "pointer",
              fontWeight: "bold",
            }}
            underline={"hover"}
          >
            {RS(SC.GB_stClickHere)}
          </Link>
          &nbsp;
          {RS(SC.GB_stToDownloadInputTracker)}
        </p>
      );

      /************   Instructional videos   *************/

      const instructVideoLab = (
        <p
          key={"instructVideoLab"}
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stInstructVideos)}
        </p>
      );

      const instructVideoParaText = (
        <p
          key={"instructVideoParaText"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          <Link
            onClick={() => gbu.URL_Click("https://www.prepwatch.org/resource/prep-it/")}
            style={{
              color: Theme.PI_PrimaryColor,
              cursor: "pointer",
              fontWeight: "bold",
            }}
            underline={"hover"}
          >
            {RS(SC.GB_stClickHere)}
          </Link>
          &nbsp;
          {RS(SC.GB_stInstructVideosPara)}
        </p>
      );

      const demoText = (
        <p
          key={"demoText"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          <Link
            onClick={() => gbu.URL_Click("https://youtu.be/ew_Yq0Rs_II")}
            style={{
              color: Theme.PI_PrimaryColor,
              cursor: "pointer",
              fontWeight: "bold",
            }}
            underline={"hover"}
          >
            {RS(SC.GB_stClickHere)}
          </Link>
          &nbsp;
          {RS(SC.GB_stClickHereViewDemo)}
        </p>
      );

      /************   Continuation Calculator   *************/
      const contCalcLab = (
        <p
          key={"contCalcLab"}
          style={{
            marginTop: 30,
            ...Theme.labelStyle,
          }}
        >
          {RS(SC.GB_stContCalculator)}
        </p>
      );

      const contCalcInfo = (
        <p
          key={"contCalcInfo"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          {RS(SC.GB_stContCalcInfo)}
        </p>
      );

      const contCalcDownloadLink = (
        <p
          key={"contCalcDownloadLink"}
          style={{
            ...Theme.textFontStyle,
          }}
        >
          <Link
            onClick={() => gbu.resourceClick("PrEP-it_continuation_calculator.xlsm")}
            style={{
              color: Theme.PI_PrimaryColor,
              cursor: "pointer",
              fontWeight: "bold",
            }}
            underline={"hover"}
          >
            {RS(SC.GB_stContCalcDownload)}
          </Link>
        </p>
      );

      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          {areaLab}

          <Divider
            style={{
              ...Theme.dividerStyle,
            }}
          />

          {aboutPara1Text}

          {gettingStartedLab}
          {gettingStartedPara1Text}

          {helpLab}
          {helpPara1Text}
          {helpManualDownloadText}

          {saveWorkLab}
          {saveWorkPara1Text}

          {guickGuidesLab}
          {quickGuidesIntroText}
          {quickGuidesOL}

          {dataNeedsTargSettingLab}
          {inputTrackerPDFPara}

          {instructVideoLab}
          {instructVideoParaText}
          {demoText}

          {contCalcLab}
          {contCalcInfo}
          {contCalcDownloadLink}
        </div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PIResourcesForm;
