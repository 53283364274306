import React from "react";
import PropTypes from "prop-types";

class TAppContent extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    appBarHeight: PropTypes.any,
    sideBarWidth: PropTypes.any,
    style: PropTypes.object,
  };

  static defaultProps = {
    appBarHeight: "0px",
    sideBarWidth: "300px",
    style: {},
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    let styles = {
      page: {
        transition: "margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)",
        height: "calc(100% - " + this.props.appBarHeight + "px)",
        //overflowY: 'auto', //markChange
        //position: 'absolute',// markThomas
        top: 0,
        overflowY: "auto", // mr3
        position: "absolute", // mr3
        bottom: 0,
        left: 0, //mr3 this.props.sideBarWidth, // 0 mr2
        right: 0,
        //mr3 marginTop: this.props.appBarHeight, // markThomas
        //mr3 verticalAlign : "top",
        //mr3 display       : "flex",
        ...this.props.style,
      },
    };

    return (
      <div
        className="AppContent"
        style={{
          //width : "100%",
          ...styles.page,
        }}
      >
        {this.props.children ? this.props.children : <div>No children given</div>}
      </div>
    );
  }
}

export default TAppContent;
