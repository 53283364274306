import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

export const PIAGYWTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

const catCol = 0;
const valCol = 1;
// const extraCol = 2;

const firstRow = 0;

const numRows = 3; //5;//7;
const numCols = 2;

class PIAGYWTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.showInDashboardAreaBool]: PropTypes.bool,
    [pip.showInTargetsAreaBool]: PropTypes.bool,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},

    [pip.showInDashboardAreaBool]: false,
    [pip.showInTargetsAreaBool]: false,
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const showInTargetsAreaBool = props[pip.showInTargetsAreaBool];
      const showInDashboardAreaBool = props[pip.showInDashboardAreaBool];

      const areasBelowThresholdObj = piasu.getModVarValue(modVarObjList, pisc.thresholdsResTableAGYWMVTag);
      const easyStartOptionObjArr = piasu.getModVarValue(modVarObjList, pisc.easyStartOptionsMVTag);

      let AGYW_ABT = piasu.getAGYW_ABT(areasBelowThresholdObj);
      let PrevalenceAGYW_ABT = piasu.getPrevalenceAGYW_ABT(areasBelowThresholdObj);
      //let PrEPCostABT = piasu.getPrEPCostABT(areasBelowThresholdObj);
      //let SavingsABT = piasu.getSavingsABT(areasBelowThresholdObj);
      const showTargets = piasu.showTargets(modVarObjList);
      const doNotSetTargsBool = piasu.easyStartModeOptionOn(easyStartOptionObjArr, pisc.doNotSetTargsESMstID);

      const showAllRows = showInTargetsAreaBool || showInDashboardAreaBool || !showTargets || doNotSetTargsBool;

      //const totalCostSavings = piasu.getModVarValue(modVarObjList, pisc.totalCostSavingsAllAreasMVTag);
      //const addCostSavingTarg = piasu.getModVarValue(modVarObjList, pisc.addCostSavingsTargMVTag);
      //const addCostSavingsTargPerc = piasu.getModVarValue(modVarObjList, pisc.addCostSavingsTargPercMVTag); #notused

      let packTable = gbtu.getNewPackTable();

      const numRowsMod = showAllRows ? numRows : 3;

      packTable = gbtu.resizePackTable(packTable, numRowsMod, numCols);

      gbtu.setValue(packTable, firstRow + 1, catCol, RS(SC.GB_stNumAGYWBelowThreshold));
      gbtu.setValue(packTable, firstRow + 2, catCol, RS(SC.GB_stPrevAmongAGYW) + " (%)");

      if (showAllRows) {
        //gbtu.setValue(packTable, firstRow + 3, catCol, RS(SC.GB_stTotalPrEPBelowThreshold) + " (" + RS(SC.GB_stUSD) + ")");
        //gbtu.setValue(packTable, firstRow + 4, catCol, RS(SC.GB_stTotalSavingsBelowThreshold) + " (" + RS(SC.GB_stUSD) + ")");
        //gbtu.setValue(packTable, firstRow + 5, catCol, RS(SC.GB_stTotalSavingsAllAreas) + " (" + RS(SC.GB_stUSD) + ")");
        //gbtu.setValue(packTable, firstRow + 6, catCol, RS(SC.GB_stAddSavingsAchTargeting) + " (" + RS(SC.GB_stUSD) + ")");
      }

      gbtu.setValue(packTable, firstRow + 1, valCol, AGYW_ABT);
      gbtu.setValue(packTable, firstRow + 2, valCol, PrevalenceAGYW_ABT);

      if (showAllRows) {
        //gbtu.setValue(packTable, firstRow + 3, valCol, PrEPCostABT);
        //gbtu.setValue(packTable, firstRow + 4, valCol, SavingsABT);
        //gbtu.setValue(packTable, firstRow + 5, valCol, totalCostSavings);
        //gbtu.setValue(packTable, firstRow + 6, valCol, addCostSavingTarg);
      }

      //gbtu.setValue(packTable, firstRow + 6, extraCol, addCostSavingsTargPerc);

      //gbtu.setValue(packTable, 0, extraCol, "%");

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      gbtu.setRDecByRow(packTable, 2, 1);
      gbtu.setColWidth(packTable, catCol, 375);
      gbtu.setColWidth(packTable, valCol, Theme.dataColWidthSmall);
      gbtu.setWordWrappedCol(packTable, valCol, true);
      gbtu.lockPackTable(packTable, true, false);

      gbtu.setMinAllowedValByCol(packTable, valCol, 0);
      gbtu.setMaxAllowedValByCol(packTable, valCol, gbtc.maxInt);

      if (window.DebugMode) {
        console.log("Component: PIAGYWTable");
        console.log("ModVar(s):");
        console.log(pisc.thresholdsResTableAGYWMVTag);
        console.log(areasBelowThresholdObj);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: 20,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIAGYWTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAGYWTable;
