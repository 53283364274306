import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../../app/Theme";

import PITargDefPriorPopRefTable1 from "../../Tables/Reference/PITargDefPriorPopRefTable1";
import PITargDefPriorPopRefTable2 from "../../Tables/Reference/PITargDefPriorPopRefTable2";
import PITargDefPriorPopRefTable3 from "../../Tables/Reference/PITargDefPriorPopRefTable3";

import * as gbu from "../../../GB/GBUtil";
import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as pias from "../../NonComponents/PIAppState";
import * as pip from "../../NonComponents/PIProps";

class PITargDefPriorPopRefForm extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),

    [pip.targEst]: PropTypes.object,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],

    [pip.targEst]: {},
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    const props = this.props;
    const modVarObjArr = props[pias.modVarObjList];
    const targEst = props[pip.targEst];

    const popAndHIVPrevLab = (
      <p
        key={"popAndHIVPrevLab"}
        style={{
          marginLeft: Theme.leftIndent,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stPopHIVPrevalence)}
      </p>
    );

    const targDefPriorPopRefTable1 = (
      <PITargDefPriorPopRefTable1
        {...{
          [pias.modVarObjList]: modVarObjArr,

          [pip.targEst]: targEst,
        }}
      />
    );

    const table1SourceLab = (
      <p
        key={"table1SourceLab"}
        style={{
          marginBottom: 0,
          marginLeft: Theme.leftIndent,
          marginTop: 5,
        }}
      >
        {RS(SC.GB_stSrcNoteRegAvgDHSNotAvail)}
      </p>
    );

    const elevRiskLab = (
      <p
        key={"elevRiskLab"}
        style={{
          marginBottom: 5,
          marginLeft: Theme.leftIndent,
          marginTop: 30,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stElevatedRiskPerc)}
      </p>
    );

    const elevRiskNoteLab = (
      <p
        key={"elevRiskNoteLab"}
        style={{
          marginBottom: 10,
          marginLeft: Theme.leftIndent,
          marginTop: 0,
        }}
      >
        {RS(SC.GB_stElevatedRiskNote)}
      </p>
    );

    const targDefPriorPopRefTable2 = (
      <PITargDefPriorPopRefTable2
        {...{
          [pias.modVarObjList]: modVarObjArr,
        }}
      />
    );

    const targDefPriorPopRefTable3 = (
      <PITargDefPriorPopRefTable3
        {...{
          [pias.modVarObjList]: modVarObjArr,
          [pip.targEst]: targEst,
        }}
      />
    );

    const table3SourceLab = (
      <p
        key={"table3SourceLab"}
        style={{
          marginBottom: 0,
          marginLeft: Theme.leftIndent,
          marginTop: 5,
        }}
      >
        {RS(SC.GB_stSrcNoteRegAvgDHSNotAvail)}
      </p>
    );

    const definitionsDiv = (
      <div
        key={"definitionsDiv"}
        style={{
          marginLeft: Theme.leftIndent,
          marginTop: 30,
        }}
      >
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          {RS(SC.GB_stDefinitions)}
        </p>

        <ul>
          <li>{RS(SC.GB_stSTISymptomsDef)}</li>
          <li>{RS(SC.GB_stMultiPartnerSexDef)}</li>
          <li>{RS(SC.GB_stInjectableFPDef)}</li>
          <li>{RS(SC.GB_stPregnantWomenDef)}</li>
          <li>{RS(SC.GB_stBFWomenDef)}</li>
        </ul>
      </div>
    );

    return (
      <React.Fragment>
        {popAndHIVPrevLab}
        {targDefPriorPopRefTable1}
        {table1SourceLab}

        {elevRiskLab}
        {elevRiskNoteLab}
        {targDefPriorPopRefTable2}

        {targDefPriorPopRefTable3}
        {table3SourceLab}

        {definitionsDiv}
      </React.Fragment>
    );
  }
}

export default PITargDefPriorPopRefForm;
