import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import SuperTableShim from "../../common/SuperTableShim";

export const PICapacityPillsPerClientPerDayTableProps = {
  allowEditsBoolC: "allowEditsBool",
};

const catCol = 0;
const valCol = 1;

const firstRow = 0;

const numRows = 4;
const numCols = 2;

class PICapacityPillsPerClientPerDayTable extends Component {
  static propTypes = {
    [PICapacityPillsPerClientPerDayTableProps.allowEditsBoolC]: PropTypes.bool,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [PICapacityPillsPerClientPerDayTableProps.allowEditsBoolC]: true,

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => {},
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onTableChange = (newPackTable) => {
    try {
      const props = this.props;
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      const newPackTableClone = gbu.cloneObj(newPackTable);

      let numPills = piasu.getModVarValue(modVarObjListClone, pisc.annualPrEPCapacityMVTag);
      let pillsPerClientDay = piasu.getModVarValue(modVarObjListClone, pisc.pillsPerClientPerDayMVTag);

      numPills = newPackTableClone.tableData.value[1][1];
      pillsPerClientDay = newPackTableClone.tableData.value[2][1];

      piasu.setModVarValue(modVarObjListClone, pisc.annualPrEPCapacityMVTag, numPills);
      piasu.setModVarValue(modVarObjListClone, pisc.pillsPerClientPerDayMVTag, pillsPerClientDay);

      onModVarsChange(modVarObjListClone, () => {});
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      //const props = this.props;  #notused
      //const modVarObjList = props[pias.modVarObjList]; #notused

      let lies = 317;

      let numPills = 317; //piasu.getModVarValue(modVarObjList, pisc.annualPrEPCapacityMVTag);
      let pillsPerClientDay = 317; //piasu.getModVarValue(modVarObjList, pisc.pillsPerClientPerDayMVTag);

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      // gbtu.setValue(packTable, firstRow, catCol, "Enter data on total drug capacity and pills per client-day");

      gbtu.setValue(packTable, firstRow + 1, catCol, RS(SC.GB_stAnnPrEPCap) + ", " + RS(SC.GB_stNPills));
      gbtu.setValue(packTable, firstRow + 2, catCol, RS(SC.GB_stNumPillsPerClientDay));
      gbtu.setValue(packTable, firstRow + 3, catCol, RS(SC.GB_stMonthDrugCap) + ", " + RS(SC.GB_stClientMonthsPrEP));

      gbtu.setValue(packTable, firstRow + 1, valCol, numPills);
      gbtu.setValue(packTable, firstRow + 2, valCol, pillsPerClientDay);
      gbtu.setValue(packTable, firstRow + 3, valCol, lies);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      //gbtu.setRDecByRow(packTable, 2, 1);
      gbtu.setColWidth(packTable, catCol, 350);
      gbtu.setColWidth(packTable, valCol, Theme.dataColWidthSmall);
      gbtu.setWordWrappedCol(packTable, valCol, true);
      // gbtu.setRowHeight(packTable, firstRow, 50);

      gbtu.setMinAllowedValByCol(packTable, valCol, 0);
      gbtu.setMaxAllowedValByCol(packTable, valCol, gbtc.maxInt);

      if (window.DebugMode) {
        console.log("Component: PICapacityMonthlyVisitTable");
        console.log("ModVar(s): hardcoded");
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          onPackTableChanged={this.onTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: 20,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PICapacityPillsPerClientPerDayTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PICapacityPillsPerClientPerDayTable;
