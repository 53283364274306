import React, { Component } from "react";
import * as PropTypes from "prop-types";

import * as Theme from "../../../app/Theme";

import * as pias from "../NonComponents/PIAppState";

class PITestForm extends Component {
  static propTypes = {
    [pias.countryObjList]: PropTypes.array,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.origModVarObjArr]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarChange]: PropTypes.func,
    [pias.onModVarsChange]: PropTypes.func,
    [pias.onOrigModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.countryObjList]: [],

    [pias.modVarObjList]: [],
    [pias.origModVarObjArr]: [],
    [pias.onModVarChange]: () => console.log(pias.onModVarChange),
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
    [pias.onOrigModVarsChange]: () => console.log(pias.onOrigModVarsChange),
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Utility functions
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    try {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        ></div>
      );
    } catch (exception) {
      return (
        <div
          style={{
            marginLeft: Theme.contentMarginLeft,
            marginTop: Theme.contentMarginTop,
          }}
        >
          <h3>{exception.name + ": " + exception.message}</h3>
        </div>
      );
    }
  }
}

export default PITestForm;
