import { PropTypes } from "prop-types";

import * as piasu from "../NonComponents/PIAppStateUtil";
import * as piu from "../NonComponents/PIUtil";
import * as gbu from "../../GB/GBUtil";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import TButton from "../../common/TButton";
import * as Theme from "../../../app/Theme";

import { promisify } from "../../../utilities";

import { getProjDefaultsAsync } from "../../../api/server_calls";
import { onCalculateAsync } from "../NonComponents/PICalc";

const PIResetDefaultMethodCostsBtn = (props) => {
  const onModVarsChange = promisify(props.onModVarsChange);

  const onResetClick = async () => {
    try {
      props.onCalculatingChange(true);

      const modVarObjList = gbu.cloneObj(props.modVarObjList);

      // Fetch defaults from server, inc. hoop jumping
      const countryCode = piasu.getModVarValue(modVarObjList, "PI_CountryISO");
      const { modvars: defaults } = await getProjDefaultsAsync({ countryCode });

      const defModVars = await onCalculateAsync(defaults, "", props.onDialogChange);

      // Rebuild methods with defaults
      const methods = piasu.getModVarValue(modVarObjList, "PI_Methods");
      let defMethods = piasu.getModVarValue(defModVars, "PI_Methods");
      defMethods = methods.map((v) => {
        const ret = defMethods.find((m) => m.mstID === v.mstID);
        if (ret === undefined) return v;

        return {
          ...ret,
          name: piu.getDefMethodNameFromMstID(v.mstID),
          unitOfCommodity: piu.getDefMethodUnitOfCommodityFromMstID(v.mstID),
        };
      });
      piasu.setModVarValue(modVarObjList, "PI_Methods", defMethods);

      // Re-calculate to fix anything caused by the above
      const newModVars = await onCalculateAsync(modVarObjList, "", props.onDialogChange);

      // Update mod vars
      await onModVarsChange(newModVars);
    } catch (err) {
      console.log(err);
    } finally {
      props.onCalculatingChange(false);
    }
  };

  return (
    <TButton
      caption={RS(SC.GB_stResetToDefault)}
      key={"defaultCostsBtn"}
      onClick={onResetClick}
      containerStyle={{
        display: "inline-block",
        marginRight: 10,
        marginTop: 0,
      }}
      style={{
        backgroundColor: Theme.PI_TertiaryColor,
        padding: 0,
      }}
      {...props}
    />
  );
};

PIResetDefaultMethodCostsBtn.propTypes = {
  modVarObjList: PropTypes.array.isRequired,
  onModVarsChange: PropTypes.func,
  onDialogChange: PropTypes.func,
  onCalculatingChange: PropTypes.func,
};

PIResetDefaultMethodCostsBtn.defaultProps = {
  onModVarsChange: () => {},
  onDialogChange: () => {},
  onCalculatingChange: () => {},
};

export default PIResetDefaultMethodCostsBtn;
