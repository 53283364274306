import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import * as gbtu from "../../GB/GBTableUtil";
import * as gbtc from "../../GB/GBTableConst";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";
import * as gbu from "../../GB/GBUtil";

import TComboBox from "../../common/TComboBox";
import TButton from "../../common/TButton";

import { generateTypes, repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const SpecifyCostsAsValues = ["lump-sum", "percentage"];

export default class PICostCatAboveSiteTable extends Component {
  static propTypes = {
    modVarObjList: PropTypes.arrayOf(PropTypes.object),
    onModVarsChange: PropTypes.func,
    tableKey: PropTypes.string,
  };

  static defaultProps = {
    modVarObjList: [],
    onModVarsChange: PropTypes.func,
    tableKey: "",
  };

  state = {
    rdec: [],
  };

  createCategory(name) {
    return {
      name,
      costs: [
        {
          period: RS(SC.GB_stYear1),
          value: 0,
        },
        {
          period: RS(SC.GB_stYear2),
          value: 0,
        },
        {
          period: RS(SC.GB_stYear3),
          value: 0,
        },
        {
          period: RS(SC.GB_stYear4),
          value: 0,
        },
        {
          period: RS(SC.GB_stYear5),
          value: 0,
        },
      ],
    };
  }

  componentDidMount() {
    this.setState((state, props) => {
      const onModVarsChange = props[pias.onModVarsChange];

      let modVarObjArrClone = gbu.cloneObj(props[pias.modVarObjList]);

      const methodMstID = piasu.getModVarValue(modVarObjArrClone, pisc.selectedMethodMVTag);
      const summaryCostsMV = piasu.getModVarValue(modVarObjArrClone, pisc.aboveSiteSummaryCostsMVTag);
      let current = summaryCostsMV.find((v) => v.mstID === methodMstID);
      if (current !== undefined) return {};

      // Setup defaults
      current = {
        mstID: methodMstID,
        units: SpecifyCostsAsValues[0],
        categories: [RS(SC.GB_stTrainingCat), RS(SC.GB_stDemandCreationCat), RS(SC.GB_stManagementCat)].map((v) =>
          this.createCategory(v)
        ),
      };
      summaryCostsMV.push(current);

      piasu.setModVarValue(modVarObjArrClone, pisc.aboveSiteSummaryCostsMVTag, summaryCostsMV);
      onModVarsChange(modVarObjArrClone);

      return {};
    });
  }

  onPackTableChange = (newPackTable) => {
    try {
      const onModVarsChange = this.props[pias.onModVarsChange];

      let modVarObjArrClone = gbu.cloneObj(this.props[pias.modVarObjList]);

      const methodMstID = piasu.getModVarValue(modVarObjArrClone, pisc.selectedMethodMVTag);
      const summaryCostsMV = piasu.getModVarValue(modVarObjArrClone, pisc.aboveSiteSummaryCostsMVTag);
      let current = summaryCostsMV.find((v) => v.mstID === methodMstID);
      if (current == null) {
        throw new Error("Table data corrupted");
      }

      for (let row = 1; row < gbtu.getNumRows(newPackTable); ++row) {
        const categoryName = gbtu.getValue(newPackTable, row, 1);

        const cat = current.categories[row - 1];
        if (cat == null) throw new Error("Table data corrupted");

        cat.name = categoryName;

        const multiplier = current.units === "percentage" ? 100 : 1;
        for (let col = 2; col < gbtu.getNumCols(newPackTable) - 1; ++col) {
          const colVal = gbtu.getValue(newPackTable, row, col) / multiplier;
          cat.costs[col - 2].value = colVal;
        }
      }

      piasu.setModVarValue(modVarObjArrClone, pisc.aboveSiteSummaryCostsMVTag, summaryCostsMV);

      this.setState(
        {
          rdec: newPackTable.RDec,
        },
        () => {
          onModVarsChange(modVarObjArrClone);
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  onDeleteClick = (row) => {
    try {
      const onModVarsChange = this.props[pias.onModVarsChange];

      const rdec = gbu.cloneObj(this.state.rdec);
      if (rdec !== undefined) rdec.splice(row, 1);

      let modVarObjArrClone = gbu.cloneObj(this.props[pias.modVarObjList]);

      const methodMstID = piasu.getModVarValue(modVarObjArrClone, pisc.selectedMethodMVTag);
      const summaryCostsMV = piasu.getModVarValue(modVarObjArrClone, pisc.aboveSiteSummaryCostsMVTag);
      let current = summaryCostsMV.find((v) => v.mstID === methodMstID);
      if (current == null) {
        throw new Error("Table data corrupted");
      }

      current.categories.splice(row - 1, 1);
      piasu.setModVarValue(modVarObjArrClone, pisc.aboveSiteSummaryCostsMVTag, summaryCostsMV);

      this.setState({ rdec }, () => {
        onModVarsChange(modVarObjArrClone);
      });
    } catch (err) {
      alert(err.message);
    }
  };

  onAddCategoryClick = () => {
    try {
      const onModVarsChange = this.props[pias.onModVarsChange];

      let modVarObjArrClone = gbu.cloneObj(this.props[pias.modVarObjList]);

      const methodMstID = piasu.getModVarValue(modVarObjArrClone, pisc.selectedMethodMVTag);
      const summaryCostsMV = piasu.getModVarValue(modVarObjArrClone, pisc.aboveSiteSummaryCostsMVTag);
      let current = summaryCostsMV.find((v) => v.mstID === methodMstID);
      if (current == null) {
        throw new Error("Table data corrupted");
      }

      current.categories.push(this.createCategory(RS(SC.GB_stNewCategory)));
      piasu.setModVarValue(modVarObjArrClone, pisc.aboveSiteSummaryCostsMVTag, summaryCostsMV);
      onModVarsChange(modVarObjArrClone);
    } catch (err) {
      alert(err.message);
    }
  };

  onCostTypeChange = (value) => {
    const onModVarsChange = this.props[pias.onModVarsChange];

    let modVarObjArrClone = gbu.cloneObj(this.props[pias.modVarObjList]);

    const methodMstID = piasu.getModVarValue(modVarObjArrClone, pisc.selectedMethodMVTag);
    const summaryCostsMV = piasu.getModVarValue(modVarObjArrClone, pisc.aboveSiteSummaryCostsMVTag);
    let current = summaryCostsMV.find((v) => v.mstID === methodMstID);
    if (current == null) {
      current = { mstID: methodMstID, categories: {} };
      summaryCostsMV.push(current);
    }

    current.units = SpecifyCostsAsValues[value] ?? SpecifyCostsAsValues[0];
    piasu.setModVarValue(modVarObjArrClone, pisc.aboveSiteSummaryCostsMVTag, summaryCostsMV);

    onModVarsChange(modVarObjArrClone);
  };

  renderTable(current) {
    const fn = () => {
      const props = this.props;
      const rows = current.categories;
      if (rows === undefined || rows.length === 0) return null;

      const rdec = this.state.rdec;

      const DeleteButton = ({ id, row }) => (
        <IconButton
          key={id}
          aria-label={"delete"}
          style={{
            marginLeft: 14,
            marginTop: -14, // FIXME: Not sure why this is necessary, but the button is offset out of the row otherwise
          }}
          onClick={() => this.onDeleteClick(row)}
        >
          <DeleteIcon
            style={{
              color: Theme.PI_TertiaryColor,
            }}
          />
        </IconButton>
      );

      const years = 5;

      let packTable = gbtu.getNewPackTable();
      packTable = gbtu.resizePackTable(packTable, rows.length + 1, years + 3);

      // This has to be before any cell locking, otherwise locked cells will have their color overridden
      // pitu.colorizeTable(packTable);

      gbtu.setColWidth(packTable, 0, 0);
      gbtu.setColWidth(packTable, 1, 275);
      gbtu.lockCol(packTable, 7, true, false);

      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);

      // Set column headers
      gbtu.setValue(packTable, 0, 1, RS(SC.GB_stCategoryHeader));
      for (let col = 0; col < rows[0].costs.length; ++col) {
        gbtu.setValue(packTable, 0, col + 2, rows[0].costs[col].period);
      }

      const multiplier = current.units === "percentage" ? 100 : 1;
      for (let row = 1; row <= rows.length; ++row) {
        const catInfo = rows[row - 1];
        const headerName = catInfo.name;

        gbtu.setValue(packTable, row, 1, headerName);
        for (let col = 2; col <= years + 1; ++col) {
          gbtu.setValue(packTable, row, col, catInfo.costs[col - 2].value * multiplier);
        }
        packTable.components[row][7] = () => <DeleteButton id={`delbtn${row}`} row={row} />;
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);

      if (current.units === "lump-sum") gbtu.setRDecs(packTable, 0);
      else gbtu.setRDecs(packTable, 2);

      if (rdec.length > 0) {
        for (let r = 0; r < rdec.length; r++) {
          for (let c = 0; c < rdec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rdec[r][c]);
          }
        }
      }

      gbtu.setRowHeight(packTable, 0, 120);
      gbtu.setColWidths(packTable, Theme.dataColWidthLarge);
      gbtu.setColWidth(packTable, 0, 0);
      gbtu.setColWidth(packTable, 7, Theme.dataColWidthSmall);
      gbtu.setColAlignment(packTable, 7, gbtc.hAlign.center);

      gbtu.setID(packTable, "packTable");
      gbtu.setKey(packTable, "packTable");

      // FIXME: We should use PercRDec and "p" type for percentage
      // ...repeat(current.units === "lump-sum" ? "n" : "p", packTable.GBColCount - packTable.GBFixedCols - 2),
      return (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          gridKey={props.tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable, [
            ...repeat("s", packTable.GBFixedCols + 1),
            ...repeat("n", packTable.GBColCount - packTable.GBFixedCols - 2),
            "cm",
          ])}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            // marginLeft: Theme.leftIndent,
            marginTop: Theme.topIndent,
            padding: 0,
          }}
          limitWidthToContainer={true}
          undoDisabled={true}
        />
      );
    };

    return gbu.tryRenderFn(fn, "render PICostCatAboveSiteTable");
  }

  render() {
    const modVarObjList = this.props[pias.modVarObjList];

    const methodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
    const summaryCostsMV = piasu.getModVarValue(modVarObjList, pisc.aboveSiteSummaryCostsMVTag);
    let current = summaryCostsMV.find((v) => v.mstID === methodMstID);
    if (current === undefined) return null;

    const specifyAs = current?.units ?? SpecifyCostsAsValues[0];
    return (
      <React.Fragment>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TComboBox
            caption={RS(SC.GB_stSpecifyCostsAs)}
            items={[RS(SC.GB_stLumpSumAmount), RS(SC.GB_stPercentageOfTotal)]}
            itemIndex={SpecifyCostsAsValues.indexOf(specifyAs)}
            onChange={this.onCostTypeChange}
            style={{ width: "fit-content" }}
          />
          {this.renderTable(current)}
          <TButton
            caption={RS(SC.GB_stAddCategory)}
            key={"addCatBtn"}
            onClick={this.onAddCategoryClick}
            style={{
              backgroundColor: Theme.PI_TertiaryColor,
              display: "block",
              marginBottom: 20,
              // marginLeft: Theme.leftIndent,
              marginTop: Theme.topIndent,
              width: 200,
            }}
            disabled={current.categories.length >= 6}
          />
        </div>
      </React.Fragment>
    );
  }
}
