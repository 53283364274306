import React from "react";
import { render } from "react-dom";
import "./app/index.css";
import Root from "./app/Root";
import * as serviceWorker from "./app/serviceWorker";
import configure from "./config";

configure();

const container = document.getElementById("root");
render(<Root />, container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
