import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import Divider from "@material-ui/core/Divider";

import * as Theme from "../../../app/Theme";

import * as pias from "../NonComponents/PIAppState";
import * as pip from "../NonComponents/PIProps";
import * as php from "../NonComponents/PIHelp";

import PIIntroBanner from "../Other/PIIntroBanner";

class PIWelcomeForm extends Component {
  static propTypes = {
    [pip.appSideBarWidth]: PropTypes.number,
    [pias.onPageChange]: PropTypes.func,
    [pias.onSideBarArrowClick]: PropTypes.func,
    [pias.sideBarOpen]: PropTypes.bool,
    [pias.screenSize]: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelpAreaChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
    [pias.onRunInitialRESTCalls]: PropTypes.func,
  };

  static defaultProps = {
    [pip.appSideBarWidth]: 0,
    [pias.onPageChange]: () => console.log([pias.onPageChange]),
    [pias.onSideBarArrowClick]: () => console.log([pias.onSideBarArrowClick]),
    [pias.sideBarOpen]: false,
    [pias.screenSize]: 0,

    [pias.helpAreaStr]: php.general_HP,
    [pias.onHelpAreaChange]: () => console.log(pias.onHelpAreaChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log([pias.onModVarsChange]),
    [pias.onRunInitialRESTCalls]: () => console.log(pias.onRunInitialRESTCalls),
  };

  state = {};

  componentDidMount() {
    this.props[pias.onHelpAreaChange](php.welcome_HP);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPageChange = (pageID) => {
    this.props[pias.onPageChange](pageID);
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTextDiv = () => {
    let textDiv = (
      <div
        style={{
          ...Theme.textFontStyle,
          marginLeft: Theme.leftIndent,
        }}
      >
        <p
          style={{
            ...Theme.pageHeadingFontStyle,
            ...Theme.pageHeadingWithBannerStyle,
          }}
        >
          {RS(SC.GB_stIntroduction)}
        </p>

        <Divider
          style={{
            ...Theme.dividerStyle,
          }}
        />

        <p
          style={{
            color: Theme.PI_SecondaryColor,
            ...Theme.labelFontStyle,
            fontSize: 18,
          }}
        >
          {RS(SC.GB_stFunctions)}
        </p>

        <ol>
          <li> {RS(SC.GB_stFunction1)} </li>
          <li> {RS(SC.GB_stFunction2)} </li>
          <li> {RS(SC.GB_stFunction3)} </li>
          <li> {RS(SC.GB_stFunction4)} </li>
          <li> {RS(SC.GB_stFunction5)} </li>
          <li> {RS(SC.GB_stFunction6)} </li>
        </ol>
      </div>
    );

    return textDiv;
  };

  render() {
    const props = this.props;
    const left = props[pip.appSideBarWidth];
    const onPageChange = props.onPageChange;
    const sideBarOpen = props[pias.sideBarOpen];
    const screenSize = props[pias.screenSize];
    const onSideBarArrowClick = props[pias.onSideBarArrowClick];
    const modVarObjArr = props[pias.modVarObjList];
    const onModVarsChange = props[pias.onModVarsChange];
    const onRunInitialRESTCalls = props[pias.onRunInitialRESTCalls];
    const onHelpAreaChange = props[pias.onHelpAreaChange];

    const introBanner = (
      <PIIntroBanner
        {...{
          [pip.appSideBarWidth]: left,
          [pias.onPageChange]: onPageChange,
          [pias.screenSize]: screenSize,
          [pias.sideBarOpen]: sideBarOpen,
          [pias.onSideBarArrowClick]: onSideBarArrowClick,
          [pias.modVarObjList]: modVarObjArr,
          [pias.onModVarsChange]: onModVarsChange,
          [pias.onRunInitialRESTCalls]: onRunInitialRESTCalls,
          [pias.onHelpAreaChange]: onHelpAreaChange,
        }}
      />
    );

    return <div>{introBanner}</div>;
  }
}

export default PIWelcomeForm;
