import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import * as pias from "../../NonComponents/PIAppState";
import * as pisc from "../../NonComponents/PIServerConst";
import * as pip from "../../NonComponents/PIProps";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const firstRow = 0;
const numRows = 9;

const firstCol = 0;
const defPriorPopCol = 0;
const natPriorPopSizeCol = 1;
const HIVPrevCol = 2;
const sourceAssumPopCol = 3;
const sourceAssumPrevCol = 4;
const numCols = sourceAssumPrevCol + 1;

class PITargDefPriorPopRefTable1 extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),

    [pip.targEst]: PropTypes.object,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],

    [pip.targEst]: {},
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const targEst = props[pip.targEst];

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Column headings */

      gbtu.setValue(packTable, firstRow, defPriorPopCol, RS(SC.GB_stDefaultPriorPop));
      gbtu.setValue(packTable, firstRow, natPriorPopSizeCol, RS(SC.GB_stNatPriorPopSize));
      gbtu.setValue(packTable, firstRow, HIVPrevCol, RS(SC.GB_stHIVPrev) + " (%)");
      gbtu.setValue(packTable, firstRow, sourceAssumPopCol, RS(SC.GB_stSourceAssumPop));
      gbtu.setValue(packTable, firstRow, sourceAssumPrevCol, RS(SC.GB_stSourceAssumPrev));

      /* Row headings */

      gbtu.setValue(packTable, 1, firstCol, "SDC");
      gbtu.setValue(packTable, 2, firstCol, "MSM");
      gbtu.setValue(packTable, 3, firstCol, "FSW");
      gbtu.setValue(packTable, 4, firstCol, "TW");
      gbtu.setValue(packTable, 5, firstCol, "PWID");
      gbtu.setValue(packTable, 6, firstCol, "AGYW 15-24");
      gbtu.setValue(packTable, 7, firstCol, "Pregnant women");
      gbtu.setValue(packTable, 8, firstCol, "Breastfeeding women");

      /* Values */

      gbtu.setValue(packTable, 1, natPriorPopSizeCol, targEst[pisc.SDCsTE]);
      gbtu.setValue(packTable, 2, natPriorPopSizeCol, targEst[pisc.MSMTE]);
      gbtu.setValue(packTable, 3, natPriorPopSizeCol, targEst[pisc.SWTE]);
      gbtu.setValue(packTable, 4, natPriorPopSizeCol, targEst[pisc.TGTE]);
      gbtu.setValue(packTable, 5, natPriorPopSizeCol, targEst[pisc.PWIDTE]);
      gbtu.setValue(packTable, 6, natPriorPopSizeCol, targEst[pisc.AGYW_15_24TE]);
      gbtu.setValue(packTable, 7, natPriorPopSizeCol, targEst[pisc.PregnantTE]);
      gbtu.setValue(packTable, 8, natPriorPopSizeCol, targEst[pisc.BreastfeedingTE]);

      gbtu.setValue(packTable, 1, HIVPrevCol, 0);
      gbtu.setValue(packTable, 2, HIVPrevCol, targEst[pisc.perc_MSM_HIVTE]);
      gbtu.setValue(packTable, 3, HIVPrevCol, targEst[pisc.perc_SW_HIVTE]);
      gbtu.setValue(packTable, 4, HIVPrevCol, targEst[pisc.perc_TG_HIVTE]);
      gbtu.setValue(packTable, 5, HIVPrevCol, targEst[pisc.perc_PWID_HIVTE]);
      gbtu.setValue(packTable, 6, HIVPrevCol, targEst[pisc.perc_AGYW_15_24_HIVTE]);
      gbtu.setValue(packTable, 7, HIVPrevCol, targEst[pisc.perc_Pregnant_HIVTE]);
      gbtu.setValue(packTable, 8, HIVPrevCol, targEst[pisc.perc_BF_HIVTE]);

      gbtu.setValue(packTable, 1, sourceAssumPopCol, "Pop 15-64 * Prev 15-49 * % married");
      gbtu.setValue(packTable, 2, sourceAssumPopCol, targEst[pisc.MSM_sourceTE]);
      gbtu.setValue(packTable, 3, sourceAssumPopCol, targEst[pisc.SW_sourceTE]);
      gbtu.setValue(packTable, 4, sourceAssumPopCol, targEst[pisc.TG_sourceTE]);
      gbtu.setValue(packTable, 5, sourceAssumPopCol, targEst[pisc.PWID_sourceTE]);
      gbtu.setValue(packTable, 6, sourceAssumPopCol, targEst[pisc.AGYW_15_24_HIV_sourceTE]);
      gbtu.setValue(packTable, 7, sourceAssumPopCol, targEst[pisc.BF_sourceTE]);
      gbtu.setValue(packTable, 8, sourceAssumPopCol, targEst[pisc.BF_sourceTE]);

      gbtu.setValue(packTable, 1, sourceAssumPrevCol, "Definition of SDC partner");
      gbtu.setValue(packTable, 2, sourceAssumPrevCol, targEst[pisc.MSM_HIV_sourceTE]);
      gbtu.setValue(packTable, 3, sourceAssumPrevCol, targEst[pisc.SW_HIV_sourceTE]);
      gbtu.setValue(packTable, 4, sourceAssumPrevCol, targEst[pisc.TG_HIV_sourceTE]);
      gbtu.setValue(packTable, 5, sourceAssumPrevCol, targEst[pisc.PWID_HIV_sourceTE]);
      gbtu.setValue(packTable, 6, sourceAssumPrevCol, targEst[pisc.AGYW_15_24_HIV_sourceTE]);
      gbtu.setValue(packTable, 7, sourceAssumPrevCol, "85% * Prev 15-49");
      gbtu.setValue(packTable, 8, sourceAssumPrevCol, "85% * Prev 15-49");

      gbtu.setColWidth(packTable, firstCol, Theme.itemNameColWidth);
      gbtu.setColWidth(packTable, natPriorPopSizeCol, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, HIVPrevCol, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, sourceAssumPopCol, Theme.itemNameColWidthExtraWide);
      gbtu.setColWidth(packTable, sourceAssumPrevCol, Theme.itemNameColWidthExtraWide);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 0);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setRowHeights(packTable, 60);
      gbtu.setRowHeight(packTable, firstRow, 75);
      gbtu.setWordWrappedCol(packTable, firstCol, true);
      gbtu.lockPackTable(packTable, true, true);

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"targDefPriorPopRefTable1"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable, ["s", "n", "n", "s", "s"])}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "renderTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PITargDefPriorPopRefTable1;
