import * as colors from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

/* Colors - names from htmlcsscolor.com,
   Variations from https://material.io/resources/color/#!/?view.left=0&view.right=0 and
   https://www.w3schools.com/colors/colors_picker.asp */

/* From Center for Humanitarian Health website. */
// const PI_PictonBlue            = "#418FDE";
// const PI_Sapphire              = "#092C74";
// const PI_Tranquil              = "#D3F8F0";
// const PI_BondiBlue             = "#008CBA"; // secondary labels
// const PI_JordyBlue             = "#67A5E4"; // dividers on white background
const PI_LightYellow = "#FFFFE6";
const PI_CuriousBlue = "#2F8CC0";

/* triadic color scheme from:
        https://www.sessions.edu/color-calculator/

   color names from:
        https://www.htmlcsscolor.com/

   band color from (95% of secondary triadic color value):
        https://www.w3schools.com/colors/colors_picker.asp
*/
const PI_SherpaBlue = "#00374D";
const PI_MadrasBrown = "#4D3F00";
const PI_BordeauxRed = "#4D001B";
const PI_AliceBlue = "#E6F8FF"; // 95% of Sherpa blue
const PI_CornSilkYellow = "#FFFAE6"; // 95% of Madras brown
const PI_CeruleanBlue = "#006E99"; // 30% of Sherpas blue

/* Main app colors */
export const PI_PrimaryColor = PI_SherpaBlue;
export const PI_SecondaryColor = PI_MadrasBrown;
export const PI_TertiaryColor = PI_BordeauxRed;
export const PI_BandColor = PI_AliceBlue;
export const PI_InputIndicatorColor = PI_CornSilkYellow;

/* Special colors */
export const elfGreenColor = "#139864";

/* Common colors */
export const bandColor = colors.blue[50];
export const lightGrayColor = colors.grey[100];
export const orangeColor = "#F7991E"; //#1293AC";
export const gray29Color = "#4A4A4A";
export const gray15Color = "#262626";
export const darkGrayColor = "#A9A9A9";
export const gray = "#808080";
export const whiteColor = "#FFFFFF";
export const blackColor = "#000000";
export const hyperLinkColor = "#337AB7";
export const red = "#FF0000";

export const amberTableColor = "#FFC000";
export const darkGrayTableColor = "#A5A5A5";
export const whisperGrayTableColor = "#E6E6E6";

/* Component color constants */
export const PI_ExampleDivColor = PI_SecondaryColor;
export const PI_LabColor = PI_SecondaryColor;
export const PI_BtnBackgroundColor = PI_PrimaryColor;
export const PI_DividerColor = PI_CeruleanBlue;
export const PI_FormYellowColor = PI_LightYellow;
export const PI_LinkBlue = PI_CuriousBlue;
export const PI_CheckColor = elfGreenColor;

const hippieBlueColor = "#3E8AA0";

export const tableFont = "lato";
export const fontFamily = ["lato, sans-serif"];

export const PI_AppBarStdHeight = 64;
export const PI_JHU_LogoMinWidth = 255; // width of Hopkins icons must be 190 pixels from the most left to most right visible parts of image
export const PI_JHU_LogoMinHeight = 109; // minimum height gotten from using object inspector on logo when width is 255

export const PI_FooterPosition = 750;
export const PI_FooterOffset = 80;

export const contentMarginLeft = 40;
export const contentMarginTop = 20;
export const leftIndent = 20;
export const topIndent = 20;
export const ctrlSpacing = 10;

export const navBtnWidth = 120;

export const lineWidthGraph = 4;

export const dataColWidthIcon = 50;
export const dataColWidthSmall = 100;
export const dataColWidthMed = 125;
export const dataColWidthLarge = 150;
export const itemNameColWidth = 275;
export const itemNameColWidthWide = 375;
export const itemNameColWidthExtraWide = 400;
export const descrColWidth = 400; // for long descriptions

export const slideDrawerLv1Width = "75%";
export const slideDrawerLv2Width = "65%";
export const slideDrawerLv3Width = "55%";

/* Common style objects */

export const dividerStyle = {
  backgroundColor: PI_DividerColor,
};

export const pageHeadingWithBannerStyle = {
  marginTop: 20,
};

export const pageHeadingFontStyle = {
  color: blackColor,
  fontFamily: fontFamily,
  fontSize: 24,
  fontWeight: 800,
  lineHeight: 1.15,
  letterSpacing: "0.01rem",
  margin: 0,
  marginBottom: 15,
  marginRight: 20,
  marginTop: 30,
};

export const stepSubTextHeadingFontStyle = {
  fontWeight: 400,
};

export const exampleDivStyle = {
  backgroundColor: PI_ExampleDivColor,
  border: "solid",
  borderColor: PI_ExampleDivColor,
  borderWidth: 2,
  color: whiteColor,
  marginRight: 20,
  marginTop: 20,
  paddingLeft: 10,
  paddingRight: 10,
};

export const simpleBorderStyle = {
  border: "solid",
  borderCollapse: "collapse",
  borderColor: blackColor,
  borderWidth: 1,
};

export const appTitleFontStyle = {
  fontFamily: fontFamily,
};

export const menuItemFontStyle = {
  fontFamily: fontFamily,
  //fontWeight    : 700,
  letterSpacing: "0.05em",
};

export const labelFontStyle = {
  fontFamily: fontFamily, //["Gentona Semi Bold", 'Open Sans Condensed', "sans-serif"],
  fontWeight: 600, // 700
  letterSpacing: "0.05em",
  lineHeight: 1.15,
};

export const textFontStyle = {
  fontFamily: fontFamily,
  fontWeight: 400,
  lineHeight: 1.4,
};

export const fontStyle = {
  fontFamily: fontFamily,
  letterSpacing: "0.05em",
  fontWeight: 700,
};

export const labelStyle = {
  color: PI_LabColor,
  ...labelFontStyle,
  fontSize: 17,
};

/* Centers a component horizontally. */
export const hCenteredStyle = {
  display: "flex",
  justifyContent: "center",
};

/* Aligns a component to the right. */
export const hRightStyle = {
  display: "flex",
  justifyContent: "flex-end",
};

/* Centers a component vertically. */
export const vCenteredStyle = {
  display: "flex",
  alignItems: "center",
};

/* Places components next to each other in a row. */
export const sideBySideStyle = {
  display: "flex",
  flexDirection: "row",
};

export const bulletListIndent2 = 40;

export const PI_BtnBorderRadius = "4px"; // radius used for TButton
export const PI_DivBorderRadius = 10;

/* These are for styling non-Material UI components using the same colors that the
   Material UI components use. Thus, styles should come from Theme.js */

export const styles = {
  listItemLab: {
    fontSize: "16px",
    color: gray29Color,
    fontWeight: 500,
    fontStyle: "normal",
  },
  listItemSelectedLab: {
    fontSize: "16px",
    color: hippieBlueColor,
    fontWeight: "bold",
    fontStyle: "normal",
  },
  listItemDisabledLab: {
    fontSize: "16px",
    color: darkGrayColor,
    fontWeight: 500,
    fontStyle: "normal",
  },
  buttonLab: {
    color: gray29Color,
    fontSize: "16px",
    fontStyle: "normal",
    ...fontStyle,
    fontWeight: 500,
  },
  buttonSelectedLab: {
    color: orangeColor,
    fontSize: "16px",
    fontStyle: "normal",
    ...fontStyle,
    fontWeight: "bold",
  },
  buttonDisabledLab: {
    color: darkGrayColor,
    fontSize: "16px",
    fontStyle: "normal",
    ...fontStyle,
    fontWeight: 500,
  },
};

const muiTheme = createTheme({
  typography: {
    fontStretch: "normal",
    ...fontStyle,
    //useNextVariants: true,
  },
  palette: {
    primary: {
      main: PI_PrimaryColor,
      contrastText: whiteColor,
    },
    secondary: {
      main: PI_SecondaryColor,
      contrastText: whiteColor,
    },
  },
  appBar: {
    backgroundColor: gray15Color, //whiteColor,
  },
  standardSideBar: {
    backgroundColor: orangeColor, //whiteColor,//'#05225d',
    //border: 'none'
  },
  toolsSideBar: {
    backgroundColor: whiteColor,
    border: "none",
  },
  editor: {
    bandColor: "#f0f6df",
    fixedRowColor: "#9cc025",
    highlightColor: "#fff1a6",
    cellPadding: "3",
  },
  bandColor: {
    backgroundColor: bandColor, //'e8ffdd' //'#f0f6df'
  },
  commandBar: {
    backgroundColor: colors.blue[100], //'hotpink',
    color: blackColor,
  },
  errorTitle: {
    margin: "0 0 10px 0",
    fontWeight: "bold",
  },
  errorParagraph: {
    margin: "0 0 10px 0",
  },
  errorBox: {
    background: "#EF9A9A",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "20px",
  },
  paperAnchorDockedLeft: {
    borderRight: "none",
  },
});

export default muiTheme;
