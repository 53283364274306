import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as pip from "../NonComponents/PIProps";
import * as gbtu from "../../GB/GBTableUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import * as pitu from "../NonComponents/PITableUtil";

import { generateTypes } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

const firstRow = 0;
const secondHeaderRow = 1;
const firstCol = 0;
const priorPopCol = 0;

// Table 2 on Targets form

class PICovConstraintsOptTargTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,

    activeTable: PropTypes.number,
    onTableChanged: PropTypes.func,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pip.tableKey]: "",

    activeTable: 2,
    onTableChanged: () => console.log("onTableChanged"),
  };

  state = {
    /* Note: Fixed rows are NOT included. All numbers are zero-based. */
    [pip.focusedCell]: {
      [pip.rowFocus]: 0,
      [pip.colFocus]: 1,
    },

    [pip.selectedRegions]: [
      {
        [pip.rowStart]: 0,
        [pip.rowEnd]: 0,
        [pip.columnStart]: 1,
        [pip.columnEnd]: 1,
      },
    ],

    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onModVarsChange = props[pias.onModVarsChange];
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

      let priorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.priorPopsMVTag);
      //let targSetOptionMstIDStr = piasu.getModVarValue(modVarObjListClone, pisc.targetSettingMVTag);
      // let potUsersToTakePrEPConstrObjArr = piasu.getModVarValue(modVarObjListClone, pisc.covConstrByPriorityPopMVTag);
      let potUsersToTakePrEPObjArray = piasu.getModVarValue(modVarObjListClone, pisc.coverageByPriorityPopMVTag);
      const methodsObjArr = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjListClone, pisc.priorPopMethodEligMVTag);

      const newPackTableClone = gbu.cloneObj(newPackTable);

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numMethods = piasu.getTotalNumMethods(methodsObjArr);

      for (let pp = 1; pp <= numPriorPops; pp++) {
        for (let m = 1; m <= numMethods; m++) {
          const methodMstID = piasu.methodMstID(methodsObjArr, m);
          const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);
          if (methodEligMstIDStr === pisc.yesCVOMstID) {
            const valueFlt = gbtu.getValue(newPackTableClone, pp + 1, m);
            //piasu.setPotUsersToTakePrEPConstr(methodMstID, potUsersToTakePrEPConstrObjArr, pp, valueFlt / 100);
            piasu.setPotUsersToTakePrEP(methodMstID, potUsersToTakePrEPObjArray, pp, valueFlt / 100);
          }
        }
      }

      this.setState(
        {
          [pip.rDec]: newPackTable[gbtc.rDec],
        },
        () => {
          this.props.onTableChanged(2, () => {
            onModVarsChange(modVarObjListClone, false);
          });
        }
      );
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      const state = this.state;
      const rDec = state[pip.rDec];

      // temporary? elric wants me to show this for actual coverage (also used for regular constraints option)
      //const potUsersToTakePrEPObjArray = piasu.getModVarValue(modVarObjList, pisc.coverageByPriorityPopMVTag);
      const priorPopObjList = piasu.getModVarValue(modVarObjList, pisc.priorPopsMVTag);
      // const potUsersToTakePrEPConstrObjArr = piasu.getModVarValue(modVarObjList, pisc.covConstrByPriorityPopMVTag);
      const potUsersToTakePrEPObjArray = piasu.getModVarValue(modVarObjList, pisc.coverageByPriorityPopMVTag);
      const actUsersToTakePrEPObjArray = piasu.getModVarValue(modVarObjList, pisc.covConstrActualMVTag);
      const methodsObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);
      const priorPopMethodEligObjArr = piasu.getModVarValue(modVarObjList, pisc.priorPopMethodEligMVTag);

      let packTable = gbtu.getNewPackTable();

      const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjList);
      const numMethods = piasu.getTotalNumMethods(methodsObjArr);
      const numRows = numPriorPops + 2; // +2 for headers
      const numCols = numMethods * 2 + 3; //  +1 for first column, +2 for two total columns

      const badNumberFontColor = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.red));

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings - first header row */

      gbtu.setValue(packTable, firstRow, 1, RS(SC.GB_stProposedCoverage) + " (%)");
      gbtu.mergeCells(packTable, firstRow, 1, 1, numMethods + 1);

      gbtu.setValue(packTable, firstRow, numMethods + 2, RS(SC.GB_stActualCovToBeAchieved) + " (%)");
      gbtu.mergeCells(packTable, firstRow, numMethods + 2, 1, numMethods + 1);

      gbtu.setValue(packTable, firstRow, priorPopCol, RS(SC.GB_stPriorityPop));

      /* Set column headings - second header row */

      let col = 1;
      for (let m = 1; m <= numMethods; m++) {
        gbtu.setValue(packTable, secondHeaderRow, col, piasu.methodName(methodsObjArr, m));
        col++;
      }

      gbtu.setValue(packTable, secondHeaderRow, col, RS(SC.GB_stTotal));
      col++;

      for (let m = 1; m <= numMethods; m++) {
        gbtu.setValue(packTable, secondHeaderRow, col, piasu.methodName(methodsObjArr, m));
        col++;
      }

      gbtu.setValue(packTable, secondHeaderRow, col, RS(SC.GB_stTotal));

      for (let pp = 1; pp <= numPriorPops; pp++) {
        /* Set row headings. */
        const priorPopName = piasu.getPriorPopName(priorPopObjList, pp);
        gbtu.setValue(packTable, pp + 1, priorPopCol, priorPopName);

        let col = 1;

        let grayOutTotal = true;
        let total = 0;

        for (let m = 1; m <= numMethods; m++) {
          const methodMstID = piasu.methodMstID(methodsObjArr, m);
          const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);
          if (methodEligMstIDStr === pisc.yesCVOMstID) {
            //const valueFlt = piasu.getPotUsersToTakePrEPConstr(methodMstID, potUsersToTakePrEPConstrObjArr, pp) * 100;
            const valueFlt = piasu.getPotUsersToTakePrEP(methodMstID, potUsersToTakePrEPObjArray, pp) * 100;
            gbtu.setValue(packTable, pp + 1, col, valueFlt);

            total = total + valueFlt;

            grayOutTotal = false;
          } else {
            gbtu.lockCell(packTable, pp + 1, col, true, true);
            const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
            gbtu.setCellBGColor(packTable, pp + 1, col, gainsboroBase10);
          }

          col++;
        }

        if (grayOutTotal) {
          const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
          gbtu.setCellBGColor(packTable, pp + 1, col, gainsboroBase10);
        } else {
          gbtu.setValue(packTable, pp + 1, col, total);

          let useGrayBool = gbu.equal(total, 100) || total < 100;

          gbtu.lockCell(packTable, pp + 1, col, true, useGrayBool);

          if (!useGrayBool) {
            gbtu.setFontColor(packTable, pp + 1, col, badNumberFontColor);
          }
        }

        col++;

        /* Actual coverage to be achieved section */

        total = 0;

        for (let m = 1; m <= numMethods; m++) {
          const methodMstID = piasu.methodMstID(methodsObjArr, m);
          const methodEligMstIDStr = piasu.getPriorPopMethodElig(priorPopMethodEligObjArr, methodMstID, pp);
          if (methodEligMstIDStr === pisc.yesCVOMstID) {
            const valueFlt = piasu.getActUsersToTakePrEP_TSP(methodMstID, actUsersToTakePrEPObjArray, pp) * 100; //piasu.getPotUsersToTakePrEP(methodMstID, potUsersToTakePrEPObjArray, pp) * 100
            gbtu.setValue(packTable, pp + 1, col, valueFlt);
            gbtu.lockCell(packTable, pp + 1, col, true, true);

            total = total + valueFlt;
          } else {
            gbtu.lockCell(packTable, pp + 1, col, true, true);
            const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
            gbtu.setCellBGColor(packTable, pp + 1, col, gainsboroBase10);
          }

          col++;
        }

        if (grayOutTotal) {
          const gainsboroBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whisperGrayTableColor));
          gbtu.setCellBGColor(packTable, pp + 1, col, gainsboroBase10);
        } else {
          gbtu.setValue(packTable, pp + 1, col, total);
        }

        gbtu.lockCell(packTable, pp + 1, col, true, true);
      }

      for (let c = 0; c < numCols; c++) {
        const primaryBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.PI_PrimaryColor));
        gbtu.setCellBGColor(packTable, secondHeaderRow, c, primaryBase10);

        const whiteBase10 = gbu.toBase10(gbu.getDelphiHexFromHexColor(Theme.whiteColor));
        gbtu.setFontColor(packTable, secondHeaderRow, c, whiteBase10);
      }

      gbtu.alignNumericCellsRight(packTable);
      //gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      //gbtu.setRowAlignment(packTable, secondHeaderRow, gbtc.hAlign.center);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, 100);
      gbtu.setRowHeight(packTable, firstRow, 50);
      gbtu.setRowHeight(packTable, secondHeaderRow, 50);
      gbtu.setWordWrappedCols(packTable, true);
      gbtu.setColWidths(packTable, Theme.dataColWidthMed);
      gbtu.setColWidth(packTable, firstCol, Theme.itemNameColWidth);
      /* Needed for merging cells in the first row and for changing the row height
               of the second row. */
      gbtu.setFixedRows(packTable, 2);

      if (rDec.length === 0) {
        gbtu.setRDecs(packTable, 1);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      let focusedCell = undefined;
      let onCellFocusedEvent = () => {};
      if (this.props.activeTable === 2) {
        focusedCell = state[pip.focusedCell];
        onCellFocusedEvent = (focusedCell) => pitu.onCellFocused(this, focusedCell);
      }

      console.log("active table: " + this.props.activeTable);
      console.log("");

      if (window.DebugMode) {
        console.log("Component: PICovConstraintsOptTargTable");
        console.log("ModVar(s):");
        console.log(pisc.coverageByPriorityPopMVTag);
        console.log(potUsersToTakePrEPObjArray);
        console.log(pisc.covConstrActualMVTag);
        console.log(actUsersToTakePrEPObjArray);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          focusedCell={focusedCell}
          onCellFocused={onCellFocusedEvent}
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          gridKey={tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          selectedRegions={state[pip.selectedRegions]}
          onSelectionChanged={(selectedRegions) => pitu.onSelectionChanged(this, selectedRegions)}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          limitWidthToContainer={true}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PICovConstraintsOptTargTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PICovConstraintsOptTargTable;
