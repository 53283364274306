import {
  calculate,
  calculateTargetsWithConstraints,
  calculateCostDetailTable,
  calculateCostLiteTable,
  calculateAGYWThresholdsTable,
  calculateTargetImpact,
} from "../../../api/server_calls";

import * as gbu from "../../GB/GBUtil";

import * as pisc from "./PIServerConst";
import * as piasu from "./PIAppStateUtil";
import * as piu from "./PIUtil";
import * as pias from "./PIAppState";
import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import { promisify } from "../../../utilities";

//import * as gbtu from "../../GB/GBTableUtil";

// /* Change one ModVar with a calculated field. */
// if (modVarStrTag !== "") {
//
//     const modVarValueResponse = piasu.getModVarValue(response, modVarStrTag);
//
//     gbu.safeCallFn(successFn(modVarValueResponse));
//
// }
// /* Change all ModVars with calculated fields, and add new ModVars. */
// else {
// let modVarObjListClone = gbu.cloneObj(modVarObjList);
// let responseModVarObjList = response[pisc.modVars];
//
// for (let mv = 0; mv < responseModVarObjList.length; mv++) {
//
//     const responseMV = responseModVarObjList[mv];
//     const responseMVValue = responseMV[pisc.mvValue];
//     const responseMVTag = responseMV[pisc.mvTag];
//
//     if (piasu.getModVarExists(modVarObjListClone, responseMVTag)) {
//
//         piasu.setModVarValue(modVarObjListClone, responseMVTag, responseMVValue);
//
//     }
//     else {
//
//         piasu.addModVarObj(modVarObjListClone, responseMV);
//
//     }
//
// }

/* The server will accept all input ModVars but return only a small subset of
   the original input ModVars (only those needed to store calculated values).
   They will be modified versions of the original ModVars with additional calculated
   fields added to them. */
export function onCalculate(modVarObjList, modVarStrTag, onDialogChange, successFn, errorFn) {
  calculate(
    modVarObjList,
    (response) => {
      /* For now, calculate all ModVars and set them all back each time you calculate to
           avoid inconsistencies. We may optimize the server later to send and receive only
           the required ModVars. */

      if (piu.serverCallSuccessful(response)) {
        let responseModVarObjList = response[pisc.modVars];

        /* The default impact priority population objects aren't returned until we calculate, so
               after we do, set their names. */
        let defImpPriorPopObjList = piasu.getModVarValue(responseModVarObjList, pisc.impactDefPriorPopMVTag);
        piasu.setDefImpPriorPopNames(defImpPriorPopObjList);
        piasu.setModVarValue(responseModVarObjList, pisc.impactDefPriorPopMVTag, defImpPriorPopObjList);

        gbu.safeCallFn(successFn(responseModVarObjList));

        // /* The default impact priority population objects aren't returned until we calculate, so
        //    after we do, set their names. */
        // let defImpPriorPopObjList = piasu.getModVarValue(modVarObjListClone, pisc.impactDefPriorPopMVTag);
        // piasu.setDefImpPriorPopNames(defImpPriorPopObjList);
        // piasu.setModVarValue(modVarObjListClone, pisc.impactDefPriorPopMVTag, defImpPriorPopObjList);
        //
        // gbu.safeCallFn(successFn(modVarObjListClone));

        //}
      } else {
        piu.notifyUserOfError(response, onDialogChange);
        gbu.safeCallFn(errorFn);
      }
    },
    () => {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = "An error occurred while calculating.";
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert("An error occurred while calculating.");
      gbu.safeCallFn(errorFn);
    }
  );
}

export const onCalculateAsync = promisify(onCalculate);

export function onCalculateTargWithConstraints(modVarObjList, onDialogChange, successFn, errorFn) {
  calculateTargetsWithConstraints(
    modVarObjList,
    (response) => {
      /* For now, calculate all ModVars and set them all back each time you calculate to
           avoid inconsistencies. We may optimize the server later to send and receive only
           the required ModVars. */

      if (piu.serverCallSuccessful(response)) {
        let responseModVarObjList = response[pisc.modVars];

        // /* The default impact priority population objects aren't returned until we calculate, so
        //    after we do, set their names. */
        // let defImpPriorPopObjList = piasu.getModVarValue(responseModVarObjList, pisc.impactDefPriorPopMVTag);
        // piasu.setDefImpPriorPopNames(defImpPriorPopObjList);
        // piasu.setModVarValue(responseModVarObjList, pisc.impactDefPriorPopMVTag, defImpPriorPopObjList);

        gbu.safeCallFn(successFn(responseModVarObjList));
      } else {
        piu.notifyUserOfError(response, onDialogChange);
        gbu.safeCallFn(errorFn);
      }
    },
    () => {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = "An error occurred while calculating targets with constraints.";
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);
      //alert("An error occurred while calculating targets with constraints.");
      gbu.safeCallFn(errorFn);
    }
  );
}

export const onCalculateTargWithConstraintsAsync = promisify(onCalculateTargWithConstraints);

export function onCalculateTargetImpact(modVarObjList, onDialogChange, successFn, errorFn) {
  calculateTargetImpact(
    modVarObjList,
    (response) => {
      /* For now, calculate all ModVars and set them all back each time you calculate to
           avoid inconsistencies. We may optimize the server later to send and receive only
           the required ModVars. */

      if (piu.serverCallSuccessful(response)) {
        let responseModVarObjList = response[pisc.modVars];
        gbu.safeCallFn(successFn(responseModVarObjList));
      } else {
        piu.notifyUserOfError(response, onDialogChange);
        gbu.safeCallFn(errorFn);
      }
    },
    () => {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = "An error occurred while calculating target impact.";
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert("An error occurred while calculating target impact.");
      gbu.safeCallFn(errorFn);
    }
  );
}

export const onCalculateTargetImpactAsync = promisify(onCalculateTargetImpact);

/* The server will accept all input ModVars but return only a small subset of
   the original input ModVars (only those needed to store calculated values).
   They will be modified versions of the original ModVars with additional calculated
   fields added to them. */
export function onCalculateCostDetailedTable(modVarObjList, modVarStrTag, onDialogChange, successFn, errorFn) {
  calculateCostDetailTable(
    modVarObjList,
    (response) => {
      /* For now, calculate all ModVars and set them all back each time you calculate to
           avoid inconsistencies. We may optimize the server later to send and receive only
           the required ModVars. */

      if (piu.serverCallSuccessful(response)) {
        gbu.safeCallFn(successFn(response[pisc.modVars]));
      } else {
        piu.notifyUserOfError(response, onDialogChange);
        gbu.safeCallFn(errorFn);
      }
    },
    () => {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = "An error occurred while calculating cost categories.";
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);
      //alert("An error occurred while calculating cost categories.");
    }
  );
}

export const onCalculateCostDetailedTableAsync = promisify(onCalculateCostDetailedTable);

/* The server will accept all input ModVars but return only a small subset of
   the original input ModVars (only those needed to store calculated values).
   They will be modified versions of the original ModVars with additional calculated
   fields added to them. */
export function onCalculateCostsLiteTable(modVarObjList, modVarStrTag, onDialogChange, successFn, errorFn) {
  calculateCostLiteTable(
    modVarObjList,
    (response) => {
      /* For now, calculate all ModVars and set them all back each time you calculate to
           avoid inconsistencies. We may optimize the server later to send and receive only
           the required ModVars. */

      if (piu.serverCallSuccessful(response)) {
        gbu.safeCallFn(successFn(response[pisc.modVars]));
      } else {
        piu.notifyUserOfError(response, onDialogChange);
        gbu.safeCallFn(errorFn);
      }
    },
    () => {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = "An error occurred while calculating default costs per visit.";
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert("An error occurred while calculating default costs per visit.");
    }
  );
}

export const onCalculateCostsLiteTableAsync = promisify(onCalculateCostsLiteTable);

/* The server will accept all input ModVars but return only a small subset of
   the original input ModVars (only those needed to store calculated values).
   They will be modified versions of the original ModVars with additional calculated
   fields added to them. */
export function onCalculateAGYWThresholdsTable(modVarObjList, modVarStrTag, onDialogChange, successFn, errorFn) {
  calculateAGYWThresholdsTable(
    modVarObjList,
    (response) => {
      /* For now, calculate all ModVars and set them all back each time you calculate to
           avoid inconsistencies. We may optimize the server later to send and receive only
           the required ModVars. */

      if (piu.serverCallSuccessful(response)) {
        gbu.safeCallFn(successFn(response[pisc.modVars]));
      } else {
        piu.notifyUserOfError(response, onDialogChange);
        gbu.safeCallFn(errorFn);
      }
    },
    () => {
      let dialogObj = pias.getDefaultDialogObj();
      dialogObj[pias.contentStr] = "An error occurred while calculating AGYW results.";
      dialogObj[pias.headerStr] = RS(SC.GB_stError);
      dialogObj[pias.maxWidthStr] = "sm";
      dialogObj[pias.showBool] = true;
      dialogObj[pias.styleObj] = { width: 500 };

      onDialogChange(dialogObj);

      //alert("An error occurred while calculating AGYW results.")
    }
  );
}

export const onCalculateAGYWThresholdsTableAsync = promisify(onCalculateAGYWThresholdsTable);

export function calcImpactFactors(modVarObjArr, successFn) {
  let impactEffectiveness1DIntArr = piasu.getModVarValue(modVarObjArr, pisc.impactEffectivenessMVTag);
  let priorPopObjArr = piasu.getModVarValue(modVarObjArr, pisc.priorPopsMVTag);
  const selectedMethodMstIDStr = piasu.getModVarValue(modVarObjArr, pisc.selectedMethodMVTag);
  const methodObjArr = piasu.getModVarValue(modVarObjArr, pisc.methodsMVTag);
  const defImpPriorPopObjArr = piasu.getModVarValue(modVarObjArr, pisc.impactDefPriorPopMVTag);

  const methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstIDStr);

  const effectiveness = piasu.getImpactEffectiveness(impactEffectiveness1DIntArr, methodCurrID);

  const numPriorPops = piasu.getTotalNumPriorPops(priorPopObjArr);
  const ratio = effectiveness / 90;

  /* It would be nice to always multiply the user's entered values from the impact
       factors table by the ratio of effectiveness / 90, but if we do this and the user
       repeatedly enters a new effectiveness, the number will keep on shrinking.

       Matt H. solution: Always change the impact factors to the original value
       you'd get if you changed one of the dropdowns in the table * ratio.
       Values the user entered in the table will get overridden, but it's the only way. */
  for (let pp = 1; pp <= numPriorPops; pp++) {
    const impPriorPopMstID = piasu.getPriorPopImpPriorPopMstID(priorPopObjArr, pp);
    const impPriorPopCurrID = piasu.getDefImpPriorPopCurrID(defImpPriorPopObjArr, impPriorPopMstID);

    piasu.setConstantPP(
      priorPopObjArr,
      methodCurrID,
      pp,
      piasu.getConstantDIPP(defImpPriorPopObjArr, impPriorPopCurrID) * ratio
    );

    piasu.setNinetyX3PP(
      priorPopObjArr,
      methodCurrID,
      pp,
      piasu.getNinetyX3DIPP(defImpPriorPopObjArr, impPriorPopCurrID) * ratio
    );
  }

  gbu.safeCallFn(successFn);
}

export const calcImpactFactorsAsync = promisify(calcImpactFactors);
