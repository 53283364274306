import * as React from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import TSlideDrawer from "../../../common/TSlideDrawer";

import * as gbu from "../../../GB/GBUtil";

import PIDefCostsPerVisitRefLiteForm from "../../Forms/Reference/PIDefCostsPerVisitRefLiteForm";
import * as pias from "../../NonComponents/PIAppState";
import * as pip from "../../NonComponents/PIProps";
import * as php from "../../NonComponents/PIHelp";
//import THelpButton from "../../../common/THelpButton";

const applyBtnEnabledC = "applyBtnEnabled";

class PIDefCostsPerVisitRefLiteSlideDrawer extends React.Component {
  static propTypes = {
    [pias.helpAreaStr]: PropTypes.string,
    [pias.onHelp]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.onSlideDrawerClose]: PropTypes.func,
    //[pip.onToggleRefSlideDrawer] : PropTypes.func,
  };

  static defaultProps = {
    [pias.helpAreaStr]: php.costsLite_DefCostPatRD_HP,
    [pias.onHelp]: () => console.log(pias.onHelp),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pip.onSlideDrawerClose]: PropTypes.func,
    // [pip.onToggleRefSlideDrawer] : () => console.log(pip.onToggleRefSlideDrawer),
  };

  state = {
    [pias.modVarObjList]: gbu.cloneObj(this.props[pias.modVarObjList]),
  };

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onHelpBtnClick = () => {
    this.props.onHelp();
  };

  onSlideDrawerSaveBtnClick = () => {
    /* state */

    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];

    /* props */

    const props = this.props;
    const onModVarsChange = props[pias.onModVarsChange];
    const onSlideDrawerClose = props[pip.onSlideDrawerClose];

    // const editorValid = imdu.getGBV_EditorValid(
    //     state.impactIPV_IntervEditValue_D,
    //     state.IPV_PercentEditValue_D,
    //     state.countryCodeIntGBV_D,
    //     false);
    const editorValid = true;

    if (!editorValid) {
      return false;
    } else {
      onModVarsChange(modVarObjList, false, () => {
        this.setState(
          {
            [applyBtnEnabledC]: false,
          },
          () => {
            onSlideDrawerClose();
            return true;
          }
        );
      });
    }
  };

  onModVarsChange = (modVarObjList, calculateBool, successFn) => {
    this.setState(
      {
        [pias.modVarObjList]: modVarObjList,
        [applyBtnEnabledC]: true,
      },
      () => gbu.safeCallFn(successFn)
    );
  };

  //==================================================================================================================
  //
  //                                              Render
  //
  //==================================================================================================================

  render() {
    /* props */

    const props = this.props;
    const onSlideDrawerClose = props[pip.onSlideDrawerClose];
    // const onToggleRefSlideDrawer = props[pip.onToggleRefSlideDrawer];

    /* state */

    const state = this.state;
    const modVarObjList = state[pias.modVarObjList];

    const formComp = (
      <PIDefCostsPerVisitRefLiteForm
        {...{
          [pias.modVarObjList]: modVarObjList,
          [pias.onModVarsChange]: this.onModVarsChange,
        }}
      />
    );

    const slideDrawer = (
      <TSlideDrawer
        anchor={"right"}
        onClose={onSlideDrawerClose}
        content={formComp}
        headerTitleStyle={{
          color: Theme.PI_PrimaryColor,
        }}
        onSave={this.onSlideDrawerSaveBtnClick}
        saveButton={false}
        saveBtnCaption={RS(SC.GB_stApply)}
        saveBtnEnabled={applyBtnEnabledC}
        title={RS(SC.GB_stDefaultCostPatterns)}
        width={Theme.slideDrawerLv2Width}
        //helpBtn={<THelpButton Theme={Theme} onClick={this.onHelpBtnClick} />}
      />
    );

    return slideDrawer;
  }
}

export default PIDefCostsPerVisitRefLiteSlideDrawer;
