import React, { Component } from "react";
//import * as PropTypes from 'prop-types';

//import Link from "@material-ui/core/Link";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import { generateTypes } from "../../../../utilities";
import SuperTableShim from "../../../common/SuperTableShim";

const firstRow = 0;
const numRows = 3;

const firstCol = 0;
const oneMonthCol = 1;
const threeMonthCol = 2;
const sixMonthCol = 3;
const twelveMonthCol = 4;
const numCols = twelveMonthCol + 1;

class PIContRefTable4 extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Column headings */

      gbtu.setValue(packTable, firstRow, oneMonthCol, RS(SC.GB_stAfterOneMonth) + " (%)");
      gbtu.setValue(packTable, firstRow, threeMonthCol, RS(SC.GB_stAfterThreeMonths) + " (%)");
      gbtu.setValue(packTable, firstRow, sixMonthCol, RS(SC.GB_stAfterSixMonths) + " (%)");
      gbtu.setValue(packTable, firstRow, twelveMonthCol, RS(SC.GB_stAfter12Months) + " (%)");

      /* Row headings */

      gbtu.setValue(packTable, 1, firstCol, "Enhanced adherence support");
      gbtu.setValue(packTable, 2, firstCol, "Standard adherence support");

      /* Values */

      gbtu.setValue(packTable, 1, oneMonthCol, 94.0);
      gbtu.setValue(packTable, 2, oneMonthCol, 89.0);

      gbtu.setValue(packTable, 1, threeMonthCol, 75.3);
      gbtu.setValue(packTable, 2, threeMonthCol, 76.4);

      gbtu.setValue(packTable, 1, sixMonthCol, 66.0);
      gbtu.setValue(packTable, 2, sixMonthCol, 69.3);

      gbtu.setValue(packTable, 1, twelveMonthCol, 14.0);
      gbtu.setValue(packTable, 2, twelveMonthCol, 9.4);

      gbtu.setColWidth(packTable, firstCol, Theme.itemNameColWidth);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 1);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setRowHeight(packTable, firstRow, 75);
      gbtu.setWordWrappedCol(packTable, firstCol, true);
      gbtu.lockPackTable(packTable, true, true);

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"contRefTable4"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={generateTypes(packTable)}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: 30,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "renderTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIContRefTable4;
