import * as React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

class TComboBox extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    caption: PropTypes.string,
    captionStyle: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.string),
    disable: PropTypes.bool,
    itemIndex: PropTypes.number,
    selectedText: PropTypes.string,
    width: PropTypes.string,
    display: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    outerStyle: PropTypes.object,
    info: PropTypes.arrayOf(PropTypes.any),
    enabledItems: PropTypes.arrayOf(PropTypes.bool),
    contentRight: PropTypes.arrayOf(PropTypes.any),
    menuItemStyle: PropTypes.object,
    comboBoxKey: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    caption: "", //'This is a label',
    captionStyle: {},
    items: [""], //['Item 1', 'Item 2', 'Item 3'],
    itemIndex: 0, //1,
    selectedText: "",
    width: "250px",
    display: "inline-block",
    onChange: (value) => console.log("TComboBox onChange [" + value + "]"),
    style: {},
    outerStyle: {},
    info: [],
    enabledItems: undefined,
    contentRight: [],
    menuItemStyle: {},
    comboBoxKey: "TComboBox",
    disabled: false,
  };

  state = {
    itemIndex: 0,
  };

  //==================================================================================================================
  //
  //                                                 Events
  //
  //==================================================================================================================

  /* Material UI:

       Callback function fired when a menu item is selected.

       Signature:

           function(event: object, child?: object) => void

               event: The event source of the callback. You can pull out the new value by accessing
                   event.target.value.
               child: The react element that was selected when native is false (default).*/

  onChange = (event) => {
    const value = event.target.value;

    /* onChange should have three parameters:

               value :
                   Combobox index of selected item.

               text :
                   Text at selected combobox index.

               info :
                   Array used to get info about the selected item.
         */
    this.props.onChange(value, this.props.items[value], this.props.info[value]);
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  renderItems = () => {
    const items = [];

    for (let i = 0; i <= this.props.items.length - 1; i++) {
      const contentRightDefined = typeof this.props.contentRight[i] !== "undefined";

      if (typeof this.props.items[i] === "object") {
        Object.entries(this.props.items[i]).forEach(([key, value]) => {
          items.push(
            <MenuItem className="MyMenuItem" value={i} key={i} style={{ backgroundColor: value }}>
              {this.props.items[i][key]}
            </MenuItem>
          );
        });
      } else {
        if (typeof this.props.enabledItems !== "undefined") {
          items.push(
            <MenuItem
              className="MyMenuItem"
              value={i}
              key={i}
              disabled={!this.props.enabledItems[i]}
              style={{ ...this.props.menuItemStyle }}
            >
              {this.props.items[i]}
              {contentRightDefined ? this.props.contentRight[i] : null}
            </MenuItem>
          );
        } else {
          items.push(
            <MenuItem className="MyMenuItem" value={i} key={i} style={{ ...this.props.menuItemStyle }}>
              {this.props.items[i]}
              {contentRightDefined ? this.props.contentRight[i] : null}
            </MenuItem>
          );
        }
      }
    }

    return items;
  };

  getItemIndex = (selectedText) => {
    for (let i = 0; i <= this.props.items.length - 1; i++) {
      if (this.props.selectedText === this.props.items[i]) {
        this.setState({
          itemIndex: i,
        });
        return;
      }
    }
  };

  render() {
    const selectFieldStyle = {
      //marginTop: 10,
      marginRight: 25,
      paddingLeft: 0,
      width: this.props.width,
      //marginBottom: 30
    };

    let items = this.renderItems();

    // Error Checking
    //if (this.props.itemIndex + 1 > items.length) {

    if (items.length === 0) {
      return (
        <FormControl style={{ display: this.props.display, ...this.props.outerStyle }} disabled={true}>
          {this.props.caption !== "" ? (
            <InputLabel htmlFor="name-native-disabled" style={{ ...this.props.captionStyle }}>
              {this.props.caption}
            </InputLabel>
          ) : null}

          <Select value={0} onChange={this.onChange} style={{ ...selectFieldStyle, ...this.props.style }}>
            <MenuItem className="MyMenuItem" value={0} key={0}>
              {"<None Available>"}
            </MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      // Do nothing and continue
    }

    //}

    return (
      <FormControl
        style={{
          display: this.props.display,
          ...this.props.outerStyle,
        }}
        key={this.props.comboBoxKey}
      >
        {this.props.caption !== "" ? (
          <InputLabel
            htmlFor="name-native-disabled"
            style={{ ...this.props.captionStyle }}
            key={this.props.comboBoxKey + "InputLabel"}
          >
            {this.props.caption}
          </InputLabel>
        ) : null}

        <Select
          className="MySelect"
          value={this.props.itemIndex}
          onChange={this.onChange}
          style={{
            ...selectFieldStyle,
            ...this.props.style,
          }}
          key={this.props.comboBoxKey + "Select"}
          disabled={this.props.disabled}
        >
          {items}
        </Select>
      </FormControl>
    );
  }
}

export default TComboBox;
