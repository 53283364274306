import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtu from "../../GB/GBTableUtil";
import * as pip from "../NonComponents/PIProps";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pisc from "../NonComponents/PIServerConst";
import * as gbtc from "../../GB/GBTableConst";
import * as gbu from "../../GB/GBUtil";
import { onCalculate, calcImpactFactors } from "../NonComponents/PICalc";
import * as pitu from "../NonComponents/PITableUtil";

import SuperTableShim from "../../common/SuperTableShim";

const hiddenCol = 0;
const effectCol = 1;
const numCols = effectCol + 1;

const firstRow = 0;
const effectRow = 1;
const numRows = effectRow + 1;

class PIImpactEffectivenessTable extends Component {
  static propTypes = {
    [pias.onCalculatingChange]: PropTypes.func,

    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,
  };

  static defaultProps = {
    [pias.onCalculatingChange]: () => console.log(pias.onCalculatingChange),

    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),
  };

  state = {
    [pip.rDec]: [],
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChange = (newPackTable) => {
    try {
      const props = this.props;
      const onDialogChange = props[pias.onDialogChange];
      const onCalculatingChange = props[pias.onCalculatingChange];
      const onModVarsChange = props[pias.onModVarsChange];

      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);

      const newPackTableClone = gbu.cloneObj(newPackTable);

      let impactEffectiveness1DIntArr = piasu.getModVarValue(modVarObjListClone, pisc.impactEffectivenessMVTag);
      const selectedMethodMstIDStr = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjListClone, pisc.methodsMVTag);

      const methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstIDStr);

      const effectiveness = gbtu.getValue(newPackTableClone, effectRow, effectCol);
      piasu.setImpactEffectiveness(impactEffectiveness1DIntArr, methodCurrID, effectiveness);

      onCalculatingChange(true, () => {
        calcImpactFactors(modVarObjListClone, () => {
          this.setState(
            {
              [pip.rDec]: newPackTable[gbtc.rDec],
            },
            () => {
              onModVarsChange(modVarObjListClone, false, () => {
                onCalculate(
                  modVarObjListClone,
                  "",
                  onDialogChange,
                  (response) => {
                    onModVarsChange(modVarObjListClone, response, () => {
                      onCalculatingChange(false);
                    });
                  },
                  () => onCalculatingChange(false)
                );
              });
            }
          );
        });
      });
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      const state = this.state;
      const rDec = state[pip.rDec];

      let impactEffectiveness1DIntArr = piasu.getModVarValue(modVarObjList, pisc.impactEffectivenessMVTag);
      const selectedMethodMstIDStr = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

      const methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstIDStr);

      let packTable = gbtu.getNewPackTable();

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Set column headings */
      gbtu.setValue(packTable, firstRow, effectCol, RS(SC.GB_stEffectiveness) + " (%)");

      const effectiveness = piasu.getImpactEffectiveness(impactEffectiveness1DIntArr, methodCurrID) || 0;
      gbtu.setValue(packTable, effectRow, effectCol, effectiveness);

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, firstRow, gbtc.hAlign.center);
      gbtu.setRowHeight(packTable, firstRow, 50);
      gbtu.setColWidth(packTable, hiddenCol, 0);
      gbtu.setColWidth(packTable, effectCol, Theme.dataColWidthLarge);

      if (rDec.length === 0) {
        gbtu.setRDec(packTable, effectRow, effectCol, 0);
      } else {
        for (let r = 0; r < rDec.length; r++) {
          for (let c = 0; c < rDec[r].length; c++) {
            gbtu.setRDec(packTable, r, c, rDec[r][c]);
          }
        }
      }

      if (window.DebugMode) {
        console.log("Component: PIImpactEffectivenessTable");
        console.log("ModVar(s):");
        console.log(pisc.impactEffectivenessMVTag);
        console.log(impactEffectiveness1DIntArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"table"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={[
            ["s", "s"],
            ["s", "n"],
          ]}
          onPackTableChanged={this.onPackTableChange}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            padding: 0,
          }}
          activeContextMenuItems={["copy", "copy-all", "copy-to-json"]}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIImpactEffectivenessTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIImpactEffectivenessTable;
