import * as PropTypes from "prop-types";
import React, { Component } from "react";

import { RS } from "../../../data/strings/global";
import * as SC from "../../../data/strings/PIStringConst";

import * as Theme from "../../../app/Theme";

import * as gbtc from "../../GB/GBTableConst";
import * as gbtu from "../../GB/GBTableUtil";
import * as gbu from "../../GB/GBUtil";
import * as pias from "../NonComponents/PIAppState";
import * as piasu from "../NonComponents/PIAppStateUtil";
import * as pip from "../NonComponents/PIProps";
import * as pisc from "../NonComponents/PIServerConst";
import * as pitu from "../NonComponents/PITableUtil";

import { repeat } from "../../../utilities";
import SuperTableShim from "../../common/SuperTableShim";

//markHardcoded -- need to change these continuation visit schedules to be the Costs Lite versions.

//const monthsAfterInitC      = "monthsAfterInit";
//const contVisitSchedCurrIDC = "contVisitSchedCurrID";

class PIAssignContVisitsToSchedLiteTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
    [pias.onModVarsChange]: PropTypes.func,

    [pip.tableKey]: PropTypes.string,
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
    [pias.onModVarsChange]: () => console.log(pias.onModVarsChange),

    [pip.tableKey]: "",
  };

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  onPackTableChanged = (newPackTable) => {
    try {
      const props = this.props;
      let modVarObjListClone = gbu.cloneObj(props[pias.modVarObjList]);
      const onModVarsChange = props[pias.onModVarsChange];

      let contVisitSchedLiteObjList = piasu.getModVarValue(modVarObjListClone, pisc.contVisitSchedLiteMVTag);
      //let contVisitObjList = piasu.getModVarValue(modVarObjListClone, pisc.contVisitsMVTag);
      //const selectedMethodMstID = piasu.getModVarValue(modVarObjListClone, pisc.selectedMethodMVTag);

      const newPackTableClone = gbu.cloneObj(newPackTable);

      const numContVisitSchedules = piasu.getTotalNumContVisitSchedules(contVisitSchedLiteObjList);
      //const numContVisitSchedules = piasu.getTotalNumContVisitSchedules(selectedMethodMstID, contVisitSchedLiteObjList);
      //const numContVisits = piasu.getTotalNumContVisits(contVisitObjList);
      //const numContVisits = piasu.getTotalNumContVisits(selectedMethodMstID, contVisitObjList);

      for (let m = 2; m <= 13; m++) {
        for (let cvs = 1; cvs <= numContVisitSchedules; cvs++) {
          const checkBoxState = gbtu.getCheckBoxState(newPackTableClone, m, cvs);
          if (checkBoxState) {
            //piasu.setContVisitSchedContVisitMstID(selectedMethodMstID, contVisitSchedLiteObjList, cvs, m, pisc.yesCVOMstID);
            piasu.setContVisitSchedContVisitMstID(contVisitSchedLiteObjList, cvs, m, pisc.yesCVOMstID);
          } else {
            //piasu.setContVisitSchedContVisitMstID(selectedMethodMstID, contVisitSchedLiteObjList, cvs, m, pisc.noCVOMstID);
            piasu.setContVisitSchedContVisitMstID(contVisitSchedLiteObjList, cvs, m, pisc.noCVOMstID);
          }
        }
      }

      piasu.setModVarValue(modVarObjListClone, pisc.contVisitSchedLiteMVTag, contVisitSchedLiteObjList);

      onModVarsChange(modVarObjListClone, false);
    } catch (exception) {
      alert(exception.name + ": " + exception.message);
    }
  };

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];
      const tableKey = props[pip.tableKey];

      const contVisitSchedLiteObjList = piasu.getModVarValue(modVarObjList, pisc.contVisitSchedLiteMVTag);
      const contVisitObjList = piasu.getModVarValue(modVarObjList, pisc.contVisitsMVTag);
      //const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);

      let packTable = gbtu.getNewPackTable();

      //const numContVisitSchedules = piasu.getTotalNumContVisitSchedules(selectedMethodMstID, contVisitSchedLiteObjList);
      const numContVisitSchedules = piasu.getTotalNumContVisitSchedules(contVisitSchedLiteObjList);
      //const numContVisits = piasu.getTotalNumContVisits(selectedMethodMstID, contVisitObjList);
      //const numContVisits = piasu.getTotalNumContVisits(contVisitObjList);

      const numRows = 14;
      const numCols = numContVisitSchedules + 1;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      gbtu.setValue(packTable, 0, 0, RS(SC.GB_stMonthsAfterInit));

      packTable = gbtu.setValue(packTable, 1, 0, 0);
      for (let cvs = 1; cvs <= numContVisitSchedules; cvs++) {
        //const contVisitSchedName = piasu.getContVisitSchedName(selectedMethodMstID, contVisitSchedLiteObjList, cv
        const contVisitSchedName = piasu.getContVisitSchedName(contVisitSchedLiteObjList, cvs);
        gbtu.setValue(packTable, 0, cvs, contVisitSchedName);

        gbtu.setValue(packTable, 1, cvs, RS(SC.GB_stInitiationVisit));

        gbtu.setColWidth(packTable, cvs, Theme.dataColWidthLarge);
      }

      for (let m = 2; m <= 13; m++) {
        /* Set row headings. */
        packTable = gbtu.setValue(packTable, m, 0, m - 1);

        for (let cvs = 1; cvs <= numContVisitSchedules; cvs++) {
          gbtu.setHasCheckBox(packTable, m, cvs, true);
          gbtu.setAlignment(packTable, m, cvs, gbtc.hAlign.center);
          gbtu.lockCell(packTable, m, cvs, true, false);

          //const contVisitSchedOptMstID = piasu.getContVisitSchedContVisitMstID(selectedMethodMstID, contVisitSchedLiteObjList, cvs, m);
          const contVisitSchedOptMstID = piasu.getContVisitSchedContVisitMstID(contVisitSchedLiteObjList, cvs, m);

          if (contVisitSchedOptMstID === pisc.yesCVOMstID) {
            gbtu.setCheckBoxState(packTable, m, cvs, true);
          }
        }
      }

      gbtu.lockCells(packTable, 1, true, true);
      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
      gbtu.setColWidth(packTable, 0, Theme.dataColWidthSmall);
      gbtu.setRowHeight(packTable, 0, 50);
      gbtu.setWordWrappedCol(packTable, 0, true);

      gbtu.setID(packTable, "packTable");
      gbtu.setKey(packTable, "packTable");

      if (window.DebugMode) {
        console.log("Component: PIAssignContVisitsToSchedLiteTable");
        console.log("ModVar(s):");
        console.log(pisc.contVisitSchedLiteMVTag);
        console.log(contVisitSchedLiteObjList);
        console.log(pisc.contVisitsMVTag);
        console.log(contVisitObjList);
        console.log("");
      }

      const typeCodes = [
        repeat("s", packTable.GBColCount),
        ["n", ...repeat("s", packTable.GBColCount - 1)],
        ...repeat(["n", ...repeat("cb", packTable.GBColCount - 1)], packTable.GBRowCount - 2),
      ];

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={tableKey}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          types={typeCodes}
          onPackTableChanged={this.onPackTableChanged}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            tableFont: Theme.tableFont,
            marginLeft: Theme.leftIndent,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
          undoDisabled={false}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "render PIAssignContVisitsToSchedLiteTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAssignContVisitsToSchedLiteTable;
