import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

import * as Theme from "../../../../app/Theme";

import * as gbtu from "../../../GB/GBTableUtil";
import * as pias from "../../NonComponents/PIAppState";
import * as piasu from "../../NonComponents/PIAppStateUtil";
import * as pisc from "../../NonComponents/PIServerConst";
import * as gbtc from "../../../GB/GBTableConst";
import * as gbu from "../../../GB/GBUtil";
import * as pitu from "../../NonComponents/PITableUtil";

import SuperTableShim from "../../../common/SuperTableShim";

const numRows = 14;

class PIAvgUnitCostPerMonthServDelivStratDetRefTable extends Component {
  static propTypes = {
    [pias.modVarObjList]: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    [pias.modVarObjList]: [],
  };

  state = {};

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  renderTable = () => {
    const fn = () => {
      const props = this.props;
      const modVarObjList = props[pias.modVarObjList];

      let servDelivStratObjList = piasu.getModVarValue(modVarObjList, pisc.servDelivStratsMVTag);
      const avgCostPrEPByMonth2DFltArr = piasu.getModVarValue(modVarObjList, pisc.avgCostPrEPByMonthSDSMVTag);
      const selectedMethodMstID = piasu.getModVarValue(modVarObjList, pisc.selectedMethodMVTag);
      const methodObjArr = piasu.getModVarValue(modVarObjList, pisc.methodsMVTag);

      const numServDelivStrats = piasu.getTotalNumServDelivStrats(selectedMethodMstID, servDelivStratObjList);
      const methodCurrID = piasu.getMethodCurrID(methodObjArr, selectedMethodMstID);

      let packTable = gbtu.getNewPackTable();

      /* Number of service delivery strategies + edu code column + personnel type column. */
      const numCols = numServDelivStrats + 1;

      packTable = gbtu.resizePackTable(packTable, numRows, numCols);

      /* Column headings */
      gbtu.setValue(packTable, 0, 0, RS(SC.GB_stMonthsOnPrEp));
      gbtu.setColWidth(packTable, 0, Theme.dataColWidthSmall);

      for (let m = 0; m <= 12; m++) {
        gbtu.setValue(packTable, m + 1, 0, m);
      }

      for (let sds = 1; sds <= numServDelivStrats; sds++) {
        const servDelivStratName = piasu.getServDelivStratName(selectedMethodMstID, servDelivStratObjList, sds);
        gbtu.setValue(packTable, 0, sds, servDelivStratName);

        for (let m = 0; m <= 12; m++) {
          const value = piasu.getAvgCostPrEPByMonthSDS(avgCostPrEPByMonth2DFltArr, methodCurrID, sds, m);
          gbtu.setValue(packTable, m + 1, sds, value);
        }

        gbtu.setColWidth(packTable, sds, Theme.dataColWidthMed);
      }

      gbtu.alignNumericCellsRight(packTable);
      gbtu.setRowAlignment(packTable, 0, gbtc.hAlign.center);
      gbtu.setRDecs(packTable, 2);
      gbtu.setMinAllowedValForTable(packTable, 0);
      gbtu.setMaxAllowedValForTable(packTable, gbtc.maxInt);
      gbtu.setRowHeight(packTable, 0, 50);
      gbtu.setWordWrappedCol(packTable, 0, true);
      gbtu.lockPackTable(packTable, true, true);

      gbtu.setID(packTable, "packTable");
      gbtu.setKey(packTable, "packTable");

      if (window.DebugMode) {
        console.log("Component: PIAvgUnitCostPerMonthServDelivStratDetRefTable");
        console.log("ModVar(s):");
        console.log(pisc.avgCostPrEPByMonthSDSMVTag);
        console.log(avgCostPrEPByMonth2DFltArr);
        console.log("");
      }

      const stdTable = (
        <SuperTableShim
          font={Theme.tableFont}
          headerBackgroundColor={Theme.PI_PrimaryColor}
          key={"table"}
          oddRowBackgroundColor={Theme.PI_BandColor}
          packTable={packTable}
          onPackTableChanged={() => {}}
          removedMenuNames={pitu.tableHideMenuItems}
          style={{
            fontFamily: Theme.tableFont,
            marginTop: Theme.ctrlSpacing,
            padding: 0,
          }}
        />
      );

      return stdTable;
    };

    return gbu.tryRenderFn(fn, "renderTable");
  };

  render() {
    return <React.Fragment>{this.renderTable()}</React.Fragment>;
  }
}

export default PIAvgUnitCostPerMonthServDelivStratDetRefTable;
