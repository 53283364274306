import React, { Component } from "react";

import Link from "@material-ui/core/Link";

import * as Theme from "../../../../app/Theme";

import PIContRefTable1 from "../../Tables/Reference/PIContRefTable1";
import PIContRefTable2 from "../../Tables/Reference/PIContRefTable2";
import PIContRefTable3 from "../../Tables/Reference/PIContRefTable3";
import PIContRefTable4 from "../../Tables/Reference/PIContRefTable4";

import * as gbu from "../../../GB/GBUtil";
import { RS } from "../../../../data/strings/global";
import * as SC from "../../../../data/strings/PIStringConst";

//const table    = 1;
//const barChart = 2;

class PIContinuationRefForm extends Component {
  static propTypes = {};

  static defaultProps = {};

  //==================================================================================================================
  //
  //                                        Life Cycle Events
  //
  //==================================================================================================================

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return gbu.shouldComponentUpdateGB(this.props, nextProps, this.state, nextState);
  }

  //==================================================================================================================
  //
  //                                              Event Handlers
  //
  //==================================================================================================================

  //==================================================================================================================
  //
  //                                                 Render
  //
  //==================================================================================================================

  render() {
    // const sampleCurvesTableLab =
    //     <p
    //         key   = {"sampleCurvesTableLab"}
    //         style = {{
    //             marginLeft : Theme.leftIndent,
    //             ...Theme.labelStyle,
    //         }}
    //     >
    //         {RS(SC.GB_stSeveralCurvesPriorStudies)}
    //     </p>;
    //
    // const sampleCurvesTable = this.renderSampleCurvesTable();

    const empCurvesPriorStudiesLab = (
      <p
        key={"empCurvesPriorStudiesLab"}
        style={{
          marginLeft: Theme.leftIndent,
          ...Theme.labelStyle,
        }}
      >
        {RS(SC.GB_stEmpCurvesPriorStudies)}
      </p>
    );

    const contRefTable1 = <PIContRefTable1 />;

    const contRefTable2 = <PIContRefTable2 />;

    const table2SourceLab = (
      <p
        style={{
          marginBottom: 0,
          marginLeft: Theme.leftIndent,
          marginTop: 5,
        }}
      >
        Source: &nbsp;
        <Link
          onClick={() => gbu.URL_Click("https://academic.oup.com/jid/article/221/9/1387/5513001")}
          style={{
            color: Theme.PI_LinkBlue,
            cursor: "pointer",
          }}
          underline={"hover"}
        >
          Mugwanya et al., 2020: Women in routine FP clinics in Kenya
        </Link>
      </p>
    );

    const contRefTable3 = <PIContRefTable3 />;

    const table3SourceLab = (
      <p
        style={{
          marginBottom: 0,
          marginLeft: Theme.leftIndent,
          marginTop: 5,
        }}
      >
        Source: &nbsp;
        <Link
          onClick={() =>
            gbu.URL_Click("https://www.thelancet.com/journals/lanhiv/article/PIIS2352-3018(19)30335-2/fulltext")
          }
          style={{
            color: Theme.PI_LinkBlue,
            cursor: "pointer",
          }}
          underline={"hover"}
        >
          Kinuthia et al., 2020: Pregnant and postpartum women at maternal and child health clinics in Kenya
        </Link>
      </p>
    );

    const contRefTable4 = <PIContRefTable4 />;

    const table4SourceLab = (
      <p
        style={{
          marginBottom: 0,
          marginLeft: Theme.leftIndent,
          marginTop: 5,
        }}
      >
        Source: &nbsp;
        <Link
          onClick={() =>
            gbu.URL_Click("https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1003670")
          }
          style={{
            color: Theme.PI_LinkBlue,
            cursor: "pointer",
          }}
          underline={"hover"}
        >
          Celum et al., 2021: AGYW 16-25 in Johannesburg, Cape Town and Harare
        </Link>
      </p>
    );

    return (
      <React.Fragment>
        {empCurvesPriorStudiesLab}

        {contRefTable1}

        {contRefTable2}
        {table2SourceLab}

        {contRefTable3}
        {table3SourceLab}

        {contRefTable4}
        {table4SourceLab}
      </React.Fragment>
    );
  }
}

export default PIContinuationRefForm;
