import React from "react";
import PropTypes from "prop-types";

class TAppPage extends React.Component {
  //==================================================================================================================
  //
  //                                             Props and State
  //
  //==================================================================================================================

  static propTypes = {
    style: PropTypes.object,
  };

  static defaultProps = {
    style: {},
  };

  //==================================================================================================================
  //
  //                                                Render
  //
  //==================================================================================================================

  render() {
    let styles = {
      page: {
        transition: "margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)",
        height: "calc(100% - " + this.props.appBarHeight + "px)",
        overflowY: "auto", // mr3
        position: "absolute", // mr3
        right: 0,
        left: 0,
        bottom: 0,
        top: 0,
        //width: "100%",
        ...this.props.style,
      },
    };

    return (
      <div className="AppPage" style={styles.page}>
        <div className="AppPageInner" style={styles.innerPage}>
          {this.props.children ? this.props.children : <div>No children given</div>}
        </div>
      </div>
    );
  }
}

export default TAppPage;
